import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Permission, Role } from 'core/enums';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class ManageReportsGuard {
  constructor(
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (
      !this.authService.isAuthenticated() ||
      this.jwtService.userHasRole(Role.User) ||
      !this.jwtService.userHasPermission(Permission.ManageReports)
    ) {
      return this.handleInvalidAccess();
    }

    return true;
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/not-found']);
    return false;
  }
}
