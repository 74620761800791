import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PartnersService } from 'core/services/partners.service';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CanViewOurPartnerGuard {
  constructor(
    private partnerService: PartnersService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const organizationId = route.paramMap.get('id');

    if (organizationId === null || isNaN(+organizationId)) {
      this.handleInvalidAccess();
    }

    return this.partnerService.canViewOurPartner(Number(organizationId)).pipe(
      tap((result: boolean) => {
        if (!result) return this.handleInvalidAccess();
        return true;
      })
    );
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/our-partners']);
    return false;
  }
}
