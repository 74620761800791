import { Pipe, PipeTransform } from '@angular/core';
import { Job } from 'core/services/jobs.service';
import { UtilsService } from 'core/utilities/utils.service';

@Pipe({
  name: 'isJobPublished',
})
export class IsJobPublishedPipe implements PipeTransform {
  constructor(private utilitiesService: UtilsService) {}

  transform(job: Job): boolean {
    const startOfToday = this.utilitiesService.getDateAtMidnight(new Date());

    if (!job.publishStartDate || !job.publishEndDate || !startOfToday) {
      return false;
    }

    if (
      startOfToday >= job.publishStartDate &&
      startOfToday <= job.publishEndDate
    ) {
      return true;
    }
    return false;
  }
}
