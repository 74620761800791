import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from 'core/utilities/utils.service';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { SearchFilter } from 'shared/models/search-filter';
import { ConfigService } from '../infrastructure/config.service';

@Injectable({
  providedIn: 'root',
})
export class SystemAlertService {
  publishedSystemAlertCount$ = new BehaviorSubject<boolean>(true);
  private msepPartnerApiUrl = this.configService.config.msepPartnerApiBaseUrl;
  private baseUrl = `${this.msepPartnerApiUrl}`;
  private savedFilters: SystemAlertSearchFilter | null = null;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private utilService: UtilsService
  ) {}

  clearSavedFilters(): void {
    this.savedFilters = null;
  }

  createSystemAlert(createSystemAlert: SystemAlert): Observable<void> {
    const url = `${this.baseUrl}/cms/system-alerts/create`;

    return this.http
      .post<void>(url, createSystemAlert)
      .pipe(catchError(this.handleError));
  }

  deleteSystemAlert(id?: number): Observable<void> {
    const url = `${this.baseUrl}/cms/system-alerts/${id}/delete`;
    return this.http
      .post<void>(url, {})
      .pipe(catchError((error) => this.handleError(error)));
  }

  editSystemAlert(alert: SystemAlert): Observable<void> {
    return this.http
      .post<void>(`${this.baseUrl}/cms/system-alerts/${alert.id}/edit`, alert)
      .pipe(catchError((error) => this.handleError(error)));
  }

  getSavedFilters(): SystemAlertSearchFilter | null {
    return this.savedFilters;
  }

  getSystemAlertById(id?: number): Observable<SystemAlert> {
    const url = `${this.baseUrl}/cms/system-alerts/${id}`;
    return this.http.get<ApiResponse<SystemAlert>>(url).pipe(
      map((result) => result.data),
      catchError((error) => this.handleError(error))
    );
  }

  publishedSystemAlerts(
    params: HttpParams
  ): Observable<ApiResponse<SystemAlert[]>> {
    const url = `${this.baseUrl}/public/system-alerts`;
    return this.http
      .get<ApiResponse<SystemAlert[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  saveFilters(filters: SystemAlertSearchFilter): void {
    this.savedFilters = filters;
  }

  searchSystemAlerts(
    filters: SystemAlertSearchFilter
  ): Observable<ApiResponse<SystemAlert[]>> {
    const url = `${this.baseUrl}/cms/system-alerts`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SystemAlert[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface CreateSystemAlert {
  title: string;
  categoryId: number;
  body: string;
  publishedStartDate?: Date;
  publishedEndDate?: Date;
}

export interface SystemAlert {
  body?: string;
  categoryDescription?: string;
  categoryId?: number;
  id?: number;
  publishStartDate?: Date;
  publishEndDate?: Date;
  title?: string;
}

export interface SystemAlertSearchFilter extends SearchFilter {
  categoryId?: number;
  endDate?: string;
  startDate?: string;
  title?: string;
}

export interface SystemAlertsSearchParams {
  categoryId?: number;
  skip?: number;
  sortDirection?: string;
  sortField?: string;
  take?: number;
}
