import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Docks } from 'core/enums';
import { DashboardService } from 'core/services/dashboard.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';

@Component({
  selector: 'msep-dock',
  templateUrl: './dock.component.html',
  styleUrls: ['./dock.component.scss'],
})
export class DockComponent {
  @Input() dockId!: Docks;
  @Input() icon!: string;
  @Input() isCollapsed!: boolean | undefined;
  /* @Input() title sets the HTML title attribute, which
  the browser turns into a tooltip. We don't want that functionality
  for this component, so we define the getter/setter explicitly
  and clear out the native element's title attribute.
   */

  @Output() toggleCollapseDock = new EventEmitter();

  private titleValue!: string;

  @Input() get title(): string {
    return this.titleValue;
  }

  set title(value: string) {
    this.titleValue = value;
    this.elementRef.nativeElement.title = '';
  }

  isLoading = false;

  constructor(
    private dashboardService: DashboardService,
    private elementRef: ElementRef,
    private notificationService: NotificationService
  ) {}

  onClick(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.dashboardService.toggleCollapseDock(this.dockId).subscribe({
        next: () => {
          this.isLoading = false;
          this.isCollapsed = !this.isCollapsed;
          this.toggleCollapseDock.emit();
        },
        error: () => {
          this.notificationService.emitFailure(
            'Error updating docks. Please contact an administrator.'
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }
}
