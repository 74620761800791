<msep-dock
  title="Partner Users"
  [dockId]="userMetrics"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container *ngIf="!isLoadingUserMetrics; else loading">
    <div class="multiple-dock-items flex flex-row justify-between align-center">
      <strong>Total Users</strong>
      <div class="count default">{{ totalUsers | number }}</div>
    </div>
    <mat-divider></mat-divider>
    <div class="multiple-dock-items flex flex-row justify-between align-center">
      <strong>Active Users</strong>
      <div class="count default">{{ activeUsers | number }}</div>
    </div>
    <mat-divider></mat-divider>
    <div class="multiple-dock-items flex flex-row justify-between align-center">
      <strong>Locked Users</strong>
      <div class="count default">{{ lockedUsers | number }}</div>
    </div>
    <mat-divider></mat-divider>
    <div class="multiple-dock-items flex flex-row justify-between align-center">
      <strong>Suspended Users</strong>
      <div class="count default">{{ suspendedUsers | number }}</div>
    </div>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
