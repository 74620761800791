import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationStatus } from 'core/enums';
import { catchError, Observable, throwError, map } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { DashboardStatusCount } from 'shared/models/dashboard';
import { ConfigService } from './infrastructure/config.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getExactTotalSpousesHired(): Observable<number> {
    return this.http
      .get<ApiResponse<{ count: number }>>(
        `${this.msepPartnerApiUrl}/resources/total-spouses-hired`
      )
      .pipe(
        map(response => response.data.count),
        catchError(this.handleError)
      );
  }

  getDefaultRoleDocks(roleId: number): Observable<Dock[][]> {
    const url = `${this.msepPartnerApiUrl}/admin/default-role-docks/${roleId}`;
    return this.http.get<ApiResponse<Dock[][]>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getDocks(): Observable<Dock[][]> {
    const url = `${this.msepPartnerApiUrl}/dashboard/dock/get-docks`;
    return this.http.get<ApiResponse<Dock[][]>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getPartnerEngagement(): Observable<PartnerEngagement> {
    const url = `${this.msepPartnerApiUrl}/dashboard/partner-engagement`;
    return this.http
      .get<PartnerEngagement>(url)
      .pipe(catchError(this.handleError));
  }

  getPartnerTracker(): Observable<PartnerTracker> {
    const url = `${this.msepPartnerApiUrl}/dashboard/partner-tracker`;
    return this.http
      .get<PartnerTracker>(url)
      .pipe(catchError(this.handleError));
  }

  getPartnerMetrics(): Observable<PartnerMetrics> {
    const url = `${this.msepPartnerApiUrl}/dashboard/partner-metrics`;
    return this.http.get<PartnerMetrics>(url).pipe(
      map((result: PartnerMetrics) => {
        const visibleStatus = result.statusCounts.filter(
          x =>
            x.statusId === ApplicationStatus.InReview ||
            x.statusId === ApplicationStatus.Approved ||
            x.statusId === ApplicationStatus.Disapproved
        );

        return {
          statusCounts: visibleStatus,
        };
      }),
      catchError(this.handleError)
    );
  }

  getRecentlyAccessedPartners(): Observable<RecentlyAccessedPartner[]> {
    const url = `${this.msepPartnerApiUrl}/dashboard/recently-accessed-partners`;
    return this.http.get<ApiResponse<RecentlyAccessedPartner[]>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getUserMetrics(): Observable<UserMetrics> {
    const url = `${this.msepPartnerApiUrl}/dashboard/user-metrics`;
    return this.http.get<ApiResponse<UserMetrics>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  toggleCollapseDock(dockId: number): Observable<boolean> {
    const url = `${this.msepPartnerApiUrl}/dashboard/dock/toggle-collapse-dock`;
    return this.http.post<boolean>(url, dockId).pipe(response => {
      return response;
    }, catchError(this.handleError));
  }

  updateDocks(docks: Dock[]): Observable<boolean> {
    const url = `${this.msepPartnerApiUrl}/dashboard/dock/update-docks`;
    return this.http.post<boolean>(url, { docks: docks }).pipe(response => {
      return response;
    }, catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface Dock {
  userId?: number;
  dockId: number;
  column?: number;
  sortOrder?: number;
  isCollapsed?: boolean;
}

export interface PartnerEngagement {
  candidateSearchCount: number;
  completedMonthlyReportCount: number;
}

export interface PartnerTracker {
  highlyEngaged: number;
  increaseEngagement: number;
  limitedEngagement: number;
  noEngagement: number;
}

export interface PartnerMetrics {
  statusCounts: DashboardStatusCount[];
}

export interface UserMetrics {
  totalUsers: number;
  activeUsers: number;
  lockedUsers: number;
  suspendedUsers: number;
}

export interface RecentlyAccessedPartner {
  partnerId: number;
  partnerName: string;
  accessedDate: Date;
}
