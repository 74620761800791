import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: '[remove-role]',
})
export class RemoveRoleDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.removeAttribute('role');
  }
}
