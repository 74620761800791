import { Component } from '@angular/core';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { DialogService } from 'core/services/infrastructure/dialog.service';

@Component({
  selector: 'msep-create-account-overview',
  templateUrl: './create-account-overview.component.html',
  styleUrls: ['./create-account-overview.component.scss'],
})
export class CreateAccountOverviewComponent {
  secoBaseUrl = this.configService.config.secoBaseUrl;

  constructor(
    private configService: ConfigService,
    private dialogService: DialogService
  ) {}

  onClickSeco(): void {
    const dialogRef = this.dialogService.governmentExitModal(this.secoBaseUrl);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(this.secoBaseUrl, '_blank');
      }
    });
  }

  onClickSecoGettingStarted(): void {
    const dialogRef = this.dialogService.governmentExitModal(
      this.secoBaseUrl + 'getting-started'
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(this.secoBaseUrl + 'getting-started', '_blank');
      }
    });
  }
}
