import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUtilsService } from 'core/utilities/date-utils.service';
import { UtilsService } from 'core/utilities/utils.service';
import {
  Subject,
  BehaviorSubject,
  catchError,
  Observable,
  throwError,
} from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { SearchFilter } from 'shared/models/search-filter';
import { ServerBlobResult } from 'shared/models/server-blob-result';
import { ConfigService } from '../infrastructure/config.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerReportService {
  reportSubject: Subject<ReportFilters>;
  private defaultFilter: ReportFilters;
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private dateUtils: DateUtilsService,
    private http: HttpClient,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {
    const currentYtd = this.dateUtils.getYtdDetails();
    this.defaultFilter = {
      startDate: currentYtd.startDate.toLocaleDateString(),
      endDate: currentYtd.endDate.toLocaleDateString(),
      skip: 0,
      take: 10,
    };
    this.reportSubject = new BehaviorSubject<ReportFilters>(this.defaultFilter);
  }

  createMonthlyReport(
    data: HiringReporting,
    organizationId: number
  ): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/create-hiring`;
    const params = this.utilService.removeFalseyProperties(data);
    return this.http.post<void>(url, params).pipe(catchError(this.handleError));
  }

  editMonthlyReport(
    data: HiringReporting,
    organizationId: number
  ): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/edit-hiring`;
    const params = this.utilService.removeFalseyProperties(data);
    return this.http.post<void>(url, params).pipe(catchError(this.handleError));
  }

  exportSpousesEmploymentDetails(
    filters: ReportFilters,
    organizationId: number
  ): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/export-spouse-employment-details`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(this.handleError));
  }

  exportSpousesHiredData(
    filters: ReportFilters,
    organizationId: number
  ): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/export-spouse-hire`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSpousesEmploymentDetailsData(
    filters: ReportFilters,
    organizationId: number
  ): Observable<ApiResponse<SpouseEmploymentData[]>> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/spouse-employment-details`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SpouseEmploymentData[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getSpousesHiredData(
    filters: ReportFilters,
    organizationId: number
  ): Observable<ApiResponse<SpouseHireData[]>> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/spouse-hire`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<SpouseHireData[]>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  getReportForPeriod(
    organizationId: string,
    reportPeriod: string
  ): Observable<ApiResponse<HiringReporting>> {
    const params = new HttpParams({
      fromObject: {
        reportPeriod,
      },
    });
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/period`;
    return this.http
      .get<ApiResponse<HiringReporting>>(url, { params })
      .pipe(catchError(this.handleError));
  }

  hasPartnerReported(
    organizationId: string,
    reportPeriod: string
  ): Observable<HasPartnerReported> {
    const url = `${this.msepPartnerApiUrl}/partners/reports/${organizationId}/current-period`;
    const params = new HttpParams({
      fromObject: {
        reportPeriod,
      },
    });
    return this.http
      .get<HasPartnerReported>(url, { params })
      .pipe(catchError(this.handleError));
  }

  setReportFilters(reportFilters: ReportFilters): void {
    this.reportSubject.next(reportFilters);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface HiringReporting {
  id?: number;
  organizationId: number;
  reportPeriod: Date;
  currentlyEmployed: HiredReportMetricGroup;
  newlyHired: HiredReportMetricGroup;
  promoted: number;
  maintainedThroughMove: number;
  virtually: number;
  fullTime: number;
  partTime: number;
  referrals: number;
  interns: number;
  fellowships: number;
}

export interface HiredReportMetricGroup {
  airForce: number;
  army: number;
  coastGuard: number;
  marines: number;
  navy: number;
  spaceForce: number;
  unknown: number;
}

export interface SpouseEmploymentData {
  employmentDetail: string;
  spouseCount: number;
  reportingPeriod: Date;
  createdDate: Date;
}

export interface SpouseHireData {
  militaryService: string;
  spouseCount: number;
  reportingPeriod: Date;
  createdDate: Date;
  createdBy: string;
  updatedBy: string;
}

export interface ReportFilters extends SearchFilter {
  employmentDetailId?: number;
  getTotals?: boolean;
  reportMetricId?: number;
  startDate?: string;
  endDate?: string;
}

export interface HasPartnerReported {
  isReportingWindowOpen: boolean;
  hasReportedInPeriod: boolean;
}
