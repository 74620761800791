// use this when you need to compare a sub-group of a form group
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

// while using angular material inputs
export class GroupComparisonErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    if (!control || !control.parent) {
      return false;
    }
    return control.parent.invalid && control.touched;
  }
}
