import { Component, Input } from '@angular/core';
import {
  AbstractControlDirective,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'msep-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
  @Input() control: AbstractControlDirective | AbstractControl | null =
    {} as AbstractControl;
  @Input() customMessage?: string;

  private static readonly errorMessages: Record<
    string,
    (() => string) | ((params: ValidationErrors) => string)
  > = {
    required: () => 'Required',
    email: () => 'Email is not formatted correctly',
    phoneNumber: () => 'Please enter a valid phone number (e.g., 123-456-7890)',
    minlength: (params: ValidationErrors) =>
      'The min number of characters is ' + params.requiredLength,
    maxlength: (params: ValidationErrors) =>
      'The max allowed number of characters is ' + params.requiredLength,
    pattern: (params: ValidationErrors) =>
      'The required pattern is: ' + params.requiredPattern,
    years: (params: ValidationErrors) => params.message,
    countryCity: (params: ValidationErrors) => params.message,
    uniqueName: (params: ValidationErrors) => params.message,
    telephoneNumbers: (params: ValidationErrors) => params.message,
    telephoneNumber: (params: ValidationErrors) => params.message,
    startGreaterThanEnd: () => 'Start Date cannot be greater than End Date',
    dateIsInThePast: () => 'Date cannot be in the past',
    maxLengthArray: (params: ValidationErrors) =>
      'You can select a max of ' + params.maximumLength + ' items',
    minLengthArray: (params: ValidationErrors) =>
      'You must select a minimum of ' + params.minimumLength + ' items',
    patternWithCustomMessage: (params: ValidationErrors) => params.message,
    customRequired: (params: ValidationErrors) => params.message,
    max: (params: ValidationErrors) => 'The max value allowed is ' + params.max,
    min: (params: ValidationErrors) => 'The min value allowed is ' + params.min,
    atLeastOneValueRequiredArray: (params: ValidationErrors) =>
      'At least one' + params.objectToValidate + ' is required',
    atLeastTwoValueRequiredArray: (params: ValidationErrors) =>
      'At least two' + params.objectToValidate + ' is required',
    stringCompareMismatch: (params: ValidationErrors) => params.message,
    urlQueryParams: () =>
      'Url cannot contain query parameters (e.g., ?param=value)',
    zipCode: () => 'Please enter a valid ZIP Code (e.g., 10001)',
    matDatepickerMax: (params: ValidationErrors) => {
      let maxDateString = '';
      if (params.max) {
        const date = new Date(params.max);
        maxDateString = [
          date.getMonth() + 1,
          date.getDate(),
          date.getFullYear(),
        ].join('/');
      }

      return 'The latest date you can select is ' + maxDateString;
    },
    matDatepickerMin: (params: ValidationErrors) => {
      let minDateString = '';
      if (params.min) {
        const date = new Date(params.min);
        minDateString = [
          date.getMonth() + 1,
          date.getDate(),
          date.getFullYear(),
        ].join('/');
      }

      return 'The earliest date you can select is ' + minDateString;
    },
    matDatepickerParse: () => 'Date is not formatted correctly.',
    unique: () => 'Value must be unique.',
  };

  listOfErrors(): string {
    if (this.control && this.control.errors) {
      return Object.keys(this.control.errors)
        .map((field) => this.getMessage(field, this.control?.errors?.[field]))
        .join('; ');
    }

    return '';
  }

  shouldShowErrors(): boolean | null {
    return (
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched)
    );
  }

  private getMessage(type: string, params: ValidationErrors): string {
    if (this.customMessage) {
      return this.customMessage;
    }
    return FormErrorComponent.errorMessages[type](params);
  }
}
