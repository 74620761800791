import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthenticationService } from 'core/services/authentication.service';

@Component({
  selector: 'msep-app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit {
  private dapId = '_fed_an_ua_tag';
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    if (isDevMode()) {
      return;
    }

    const dapScript = document.getElementById(this.dapId);

    if (this.authenticationService.isAuthenticated()) {
      dapScript?.remove();
    } else if (!dapScript) {
      const newDapScript = document.createElement('script');
      newDapScript.id = this.dapId;
      newDapScript.type = 'text/javascript';
      newDapScript.src =
        'https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=DoD';
      newDapScript.async = true;
      document.head.appendChild(newDapScript);
    }
  }
}
