<section>
  <nav aria-label="partner menu" class="menu">
    <a
      aria-label="Dashboard"
      [routerLink]="['/partner/dashboard', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>dashboard</mat-icon>Dashboard
    </a>
    <a
      aria-label="Charts"
      [routerLink]="['/partner/charts', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>area_chart</mat-icon>Charts
    </a>
    <a
      aria-label="Profile"
      [routerLink]="['/partner/profile', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>account_circle</mat-icon>Profile
    </a>
    <a
      aria-label="Candidates"
      [routerLink]="['/partner/candidates', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>group</mat-icon>Candidates
    </a>
    <a
      aria-label="Jobs"
      [routerLink]="['/partner/jobs', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>view_headline</mat-icon>Jobs
    </a>
    <a
      aria-label="Reports"
      [routerLink]="['/partner/reports', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>equalizer</mat-icon>Reports
    </a>
    <a
      aria-label="Spouse Engagement"
      [routerLink]="['/partner/spouse-engagement', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>sync_alt</mat-icon>Spouse Engagement
    </a>
    <a
      aria-label="Events"
      *ngIf="canViewEvents()"
      [routerLink]="['/virtual-events/events', organizationId]"
      mat-raised-button=""
      routerLinkActive="active-menu-link"
    >
      <mat-icon>events</mat-icon>Events
    </a>
  </nav>
  <nav aria-label="mobile-friendly menu" class="mobile-menu">
    <button type="button" mat-raised-button [matMenuTriggerFor]="mobileMenu">
      Partner Menu
      <div class="mat-select-arrow-wrapper">
        <div class="mat-select-arrow"></div>
      </div>
    </button>
    <mat-menu #mobileMenu="matMenu">
      <a
        role="menuitem"
        [routerLink]="['/partner/dashboard', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>dashboard</mat-icon>Dashboard
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/charts', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>area_chart</mat-icon>Charts
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/profile', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>account_circle</mat-icon>Profile
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/candidates', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>group</mat-icon>Candidates
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/jobs', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>view_headline</mat-icon>Jobs
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/reports', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>equalizer</mat-icon>Reports
      </a>
      <a
        role="menuitem"
        [routerLink]="['/partner/spouse-engagement', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>sync_alt</mat-icon>Spouse Engagement
      </a>
      <a
        role="menuitem"
        *ngIf="canViewEvents()"
        [routerLink]="['/virtual-events/events', organizationId]"
        mat-menu-item
        routerLinkActive="active-menu-link"
      >
        <mat-icon>events</mat-icon>Events
      </a>
    </mat-menu>
  </nav>
</section>
