import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Permission, Role } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'msep-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  @Output() menuNavigated = new EventEmitter<boolean>();
  panelOpenState = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const authenticationSubscription =
      this.authenticationService.isAuthenticatedSubject
        .pipe(map((result) => (this.isAuthenticated = result)))
        .subscribe();

    this.subscriptions.push(authenticationSubscription);
  }

  getDisplayName(): string {
    return this.authenticationService.getDisplayName();
  }

  getHomepageLink(): string {
    return this.jwtService.getHomePage();
  }

  getLastLoginDate(): string {
    return this.jwtService.getLastLoginDate();
  }

  onLogin(): void {
    this.toggle();
    this.router.navigate(['/notice-and-consent', 'login']);
  }

  onLogout(): void {
    this.toggle();
    const logoutSubscription = this.authenticationService
      .logOut()
      .subscribe(() => {
        this.router.navigate(['/logout']);
      });

    this.subscriptions.push(logoutSubscription);
  }

  shouldShowPartnerDirectoryLink(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  shouldShowReportMenu(): boolean {
    if (this.userHasUserRole()) {
      return false;
    }

    return this.userHasManageReportsPermission();
  }

  toggle(): void {
    this.menuNavigated.emit(true);
  }

  userHasHelpDeskPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.Helpdesk);
  }

  userHasManageJobsPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageJobs);
  }

  userHasManageReportsPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageReports);
  }

  userHasManageUsersPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageUsers);
  }

  userHasSpecialistRole(): boolean {
    return this.jwtService.userHasRole(Role.Specialist);
  }

  userHasUserRole(): boolean {
    return this.jwtService.userHasRole(Role.User);
  }

  userIsSysOp(): boolean {
    return this.jwtService.userHasRole(Role.SysOp);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((x) => x.unsubscribe());
  }
}
