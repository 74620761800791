import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'candidateLocation',
})
export class CandidateLocationPipe implements PipeTransform {
  transform(city: string | undefined, state: string | undefined): string {
    const hasCity = (city?.length ?? 0) > 0;
    const hasState = (state?.length ?? 0) > 0;

    if (hasCity && hasState) {
      return city + ', ' + state;
    } else if (hasCity) {
      return city ?? '';
    } else if (hasState) {
      return state ?? '';
    } else {
      return '';
    }
  }
}
