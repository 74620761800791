import { Component, Input } from '@angular/core';
import { GhostLoaderType } from 'core/enums';

@Component({
  selector: 'msep-ghost-loader-mat-card',
  templateUrl: './ghost-loader-mat-card.component.html',
  styleUrls: ['./ghost-loader-mat-card.component.scss'],
})
export class GhostLoaderMatCardComponent {
  @Input() type? = GhostLoaderType.Card;
  ghostLoaderLogo = GhostLoaderType.PartnerLogo;
}
