<button
  class="flex"
  mat-raised-button
  (click)="onExportClick()"
  type="button"
  [disabled]="isLoading"
  [class.spinner]="isLoading"
>
  <mat-icon aria-hidden="false" aria-label="arrow facing down above line">
    file_download
  </mat-icon>
  Export
</button>
