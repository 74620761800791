<msep-page-title>
  <h1>Notice and Consent Statement</h1>
</msep-page-title>

<mat-card>
  <mat-card-subtitle class="uppercase"
    >OMB control number: 0704-0563<br />
    OMB expiration date: 6/30/2026
  </mat-card-subtitle>

  <mat-card-content>
    <section>
      <p>
        You are accessing a U.S. Government (USG) Information System (IS) that
        is provided for USG-authorized use only. By using this IS (which
        includes any device attached to this IS), you consent to the following
        conditions:
      </p>

      <ul>
        <li>
          The USG routinely intercepts and monitors communications on this IS
          for purposes including, but not limited to, penetration testing,
          COMSEC monitoring, network operations and defense, personnel
          misconduct (PM), law enforcement (LE), and counterintelligence (CI)
          investigations.
        </li>
        <li>
          At any time, the USG may inspect and seize data stored on this IS.
        </li>
        <li>
          Communications using, or data stored on, this IS are not private, are
          subject to routine monitoring, interception, and search, and may be
          disclosed or used for any USG authorized purpose. This IS includes
          security measures (e.g., authentication and access controls) to
          protect USG interests--not for your personal benefit or privacy.
        </li>
        <li>
          Notwithstanding the above, using this IS does not constitute consent
          to PM, LE or CI investigative searching or monitoring of the content
          of privileged communications, or work product, related to personal
          representation or services by attorneys, psychotherapists, or clergy,
          and their assistants. Such communications and work product are private
          and confidential. See
          <a role="link" routerLink="/user-agreement">User Agreement</a> for
          details.
        </li>
      </ul>
    </section>
  </mat-card-content>
</mat-card>

<mat-card class="wrap">
  <mat-card-title>Privacy Act Statement</mat-card-title>

  <mat-card-content>
    <section>
      <p>
        <strong>Authority:</strong> 10 U.S.C. 136, Under Secretary of Defense
        for Personnel and Readiness; 10 U.S.C. 1784, Employment Opportunities
        for Military Spouses, and DoDI 1342.22, Military Family Readiness.
      </p>

      <p>
        <strong>Principal Purpose(s):</strong> The Military Spouse Employment
        Partnership (MSEP) Partner Portal is the sole web platform utilized to
        connect military spouses with companies seeking to hire military spouse
        employees. MSEP is a component of the DoD Spouse Education and Career
        Opportunities (SECO) Program which is the primary source of education,
        career and employment counseling for all military spouses.
      </p>

      <p>
        <strong>Routine Use(s):</strong> In addition to those disclosures
        generally permitted under 5 U.S.C. 552a(b) of the Privacy Act of 1974,
        as amended, the records contained herein may specifically be disclosed
        outside the DoD as a routine use pursuant to 5 U.S.C. 552a(b)(3) as
        follows: To authorized DoD SECO contractors and grantees for the purpose
        of responding to military spouse need as they relate to employment
        readiness. To authorized DoD contractors and grantees for the purpose of
        supporting research studies concerned with the education, career and
        employment counseling of military spouses participating in DoD funded
        spousal education, career and employment programs. See the applicable
        system of records notice, DPR 47 DoD, Military Spouse Employment
        Partnership (MSEP) Partner Portal for additional routine uses, located
        at:
        <a
          href="https://dpcld.defense.gov/Portals/49/Documents/Privacy/SORNs/OSDJS/DPR-47-DoD.pdf"
          >https://dpcld.defense.gov/Portals/49/Documents/Privacy/SORNs/OSDJS/DPR-47-DoD.pdf</a
        >
      </p>

      <p>
        <strong>Disclosure:</strong> Voluntary; however, failure to provide
        information may impact your ability to utilize certain program
        opportunities.
      </p>
    </section>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>Agency Disclosure Notice</mat-card-title>

  <mat-card-content>
    <section>
      <p>
        The public reporting burden for this collection of information is
        estimated to average 15 minutes per response, including the time for
        reviewing instructions, searching existing data sources, gathering and
        maintaining the data needed, and completing and reviewing the collection
        of information. Send comments regarding this burden estimate or any
        other aspect of this collection of information, including suggestions
        for reducing the burden, to the Department of Defense, Washington
        Headquarters Services, Executive Services Directorate, Directives
        Division, 4800 Mark Center Drive, East Tower, Suite 02G09, Alexandria,
        VA 22350-3100, OMB CONTROL NUMBER: 0704-0563. Respondents should be
        aware that notwithstanding any other provision of law, no person shall
        be subject to any penalty for failing to comply with a collection of
        information if it does not display a currently valid OMB control number.
      </p>

      <p>
        <strong class="uppercase"
          >Please do not return your response to the above address.
        </strong>
      </p>

      <p>Responses should be sent to:</p>
      <p>
        Military Community Support Programs<br />
        ATTN: Program Manager, Spouse Education &amp; Career Opportunities
        Program<br />
        4801 Mark Center Drive<br />
        Alexandria, VA 22350-2300<br />
      </p>
    </section>
  </mat-card-content>
</mat-card>

<p>
  By clicking <span class="uppercase">I agree</span>, you agree to the terms of
  the User Agreement and confirm that you have read the Privacy Act Statement
  and Agency Disclosure Notice.
</p>

<div class="action-buttons flex flex-row justify-center align-center">
  <a role="link" mat-raised-button routerLink="/">Cancel</a>
  <a role="link" mat-raised-button color="primary" (click)="onAgreeClick()">
    I Agree
  </a>
</div>
