import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';
import {
  PartnerProfile,
  PartnersService,
  PointOfContact,
} from 'core/services/partners.service';
import { Resource } from 'core/services/resources.service';
import { UtilsService } from 'core/utilities/utils.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-our-partner-profile',
  templateUrl: './view-our-partner.component.html',
  styleUrls: ['./view-our-partner.component.scss'],
})
export class ViewOurPartnerComponent implements OnInit {
  partnerProfile!: PartnerProfile;
  pointOfContact$!: Observable<PointOfContact>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer,
    private partnerService: PartnersService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.partnerProfile = this.activatedRoute.snapshot.data['resolvedData'];

    this.partnerProfile.description =
      this.domSanitizer.sanitize(
        SecurityContext.HTML,
        this.partnerProfile.description
      ) ?? '';

    this.setPointOfContact();
  }

  calculateWidth(): string {
    const cardCount = [
      this.hasValue(this.partnerProfile.partnerVideoUrl),
      this.shouldShowStatistics(),
      this.shouldShowIndustry(),
    ].filter(Boolean).length;

    switch (cardCount) {
      case 1:
        return 'flex-gt-md-100';
      case 2:
        return 'flex-gt-md-50';
      case 3:
        return 'flex-gt-md-33-33';
      default:
        return '';
    }
  }

  getResourceString(resource: Resource[]): string {
    const resourceDescriptions = resource.map((r) => r.description);
    return this.utilsService.getStringListFromArray(resourceDescriptions);
  }

  getSecondaryIndustryHeading(): string {
    return this.partnerProfile.secondaryIndustries.length > 1
      ? 'Secondary Industries'
      : 'Secondary Industry';
  }

  hasLink(): boolean {
    return Boolean(
      this.partnerProfile.website ||
        this.partnerProfile.facebookUrl ||
        this.partnerProfile.linkedInUrl ||
        this.partnerProfile.twitterUrl ||
        this.partnerProfile.instagramUrl
    );
  }

  hasValue(value: PartnerProfile[keyof PartnerProfile]): boolean {
    return Array.isArray(value) ? value.length > 0 : Boolean(value);
  }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  setPointOfContact(): void {
    if (this.isAuthenticated()) {
      this.pointOfContact$ = this.partnerService.getPrimaryPointOfContact(
        this.partnerProfile.id
      );
    }
  }

  shouldShowIndustry(): boolean {
    return (
      this.hasValue(this.partnerProfile.primaryIndustry) ||
      this.hasValue(this.partnerProfile.secondaryIndustries) ||
      this.hasValue(this.partnerProfile.geographicReach)
    );
  }

  shouldShowPartnerVideo(): boolean {
    return this.hasValue(this.partnerProfile.partnerVideoUrl);
  }

  shouldShowSocialMediaLinks(): boolean {
    return (
      this.hasValue(this.partnerProfile.facebookUrl) ||
      this.hasValue(this.partnerProfile.linkedInUrl) ||
      this.hasValue(this.partnerProfile.twitterUrl) ||
      this.hasValue(this.partnerProfile.instagramUrl)
    );
  }

  shouldShowStatistics(): boolean {
    return (
      this.hasValue(this.partnerProfile.numberOfEmployees) ||
      this.hasValue(this.partnerProfile.countriesWithFacilities) ||
      this.hasValue(this.partnerProfile.numberOfActiveJobs)
    );
  }
}
