import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SysOpGuard } from 'core/guards/sysOp.guard';
import { AuthenticatedGuard } from 'core/guards/authenticated.guard';
import { PageNotFoundComponent } from 'home/page-not-found/page-not-found.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { ManageReportsGuard } from 'core/guards/manage-reports.guard';
import { SpecialistGuard } from 'core/guards/specialist.guard';
import { GovtGuard } from 'core/guards/government.guard';
import { VhfTempGuard } from 'core/guards/vhfTemp.guard';
import { CanViewChartsGuard } from 'core/guards/can-view-charts.guard';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [SysOpGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((x) => x.AdminModule),
  },
  {
    path: 'charts',
    canActivate: [CanViewChartsGuard],
    loadChildren: () =>
      import('./charts/charts.module').then((x) => x.ChartsModule),
  },
  {
    path: 'content-management',
    canActivate: [SysOpGuard],
    loadChildren: () => import('./cms/cms.module').then((x) => x.CmsModule),
  },
  {
    path: 'government',
    canActivate: [GovtGuard],
    loadChildren: () =>
      import('./government/government.module').then((x) => x.GovernmentModule),
  },
  {
    path: 'help',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./help/help.module').then((x) => x.HelpModule),
  },
  {
    path: 'partner',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./partners/partners.module').then((x) => x.PartnersModule),
  },
  {
    path: 'registration',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./registration/registration.module').then(
        (x) => x.RegistrationModule
      ),
  },
  {
    path: 'report',
    canActivate: [ManageReportsGuard],
    loadChildren: () =>
      import('./report/report.module').then((x) => x.ReportModule),
  },
  {
    path: 'specialist',
    canActivate: [SpecialistGuard],
    loadChildren: () =>
      import('./specialists/specialists.module').then(
        (x) => x.SpecialistsModule
      ),
  },
  {
    path: 'virtual-events',
    canActivate: [VhfTempGuard],
    loadChildren: () =>
      import('./virtual-events/virtual-events.module').then(
        (x) => x.VirtualEventsModule
      ),
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/', pathMatch: 'full' },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
