import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class EqualityValidator {
  static stringCompare(
    controlName1: string,
    controlName2: string,
    friendlyErrorMessage = 'Values do not match'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControl = control.get(controlName1);
      const secondControl = control.get(controlName2);

      if (firstControl?.pristine && secondControl?.pristine) {
        return null;
      }

      if (
        firstControl?.value &&
        secondControl?.value &&
        firstControl.value !== secondControl.value
      ) {
        return { stringCompareMismatch: { message: friendlyErrorMessage } };
      }

      return null;
    };
  }
}
