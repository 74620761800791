import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminPartner } from 'partners/partner-list/models/admin-partner';
import { AdminPartnerFilters } from 'partners/partner-list/models/admin-partner-filters';
import { UtilsService } from 'core/utilities/utils.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { SearchFilter } from 'shared/models/search-filter';
import { ConfigService } from '../infrastructure/config.service';
import { Resource } from '../resources.service';

@Injectable({ providedIn: 'root' })
export class AdminService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {}

  getEmailServiceStatus(): Observable<boolean> {
    const url = `${this.msepPartnerApiUrl}/admin/email-service-status`;
    return this.http.get<ApiResponse<boolean>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getHistoricalHiresLog(
    filters: HistoricalHiresLogFilters
  ): Observable<ApiResponse<HistoricalHiresLog[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/historical-hire-logs`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<HistoricalHiresLog[]>>(url, { params })
      .pipe(
        map(result => result),
        catchError(error => this.handleError(error))
      );
  }

  getPartnersAutoComplete(searchTerm: string): Observable<Resource[]> {
    const url = `${this.msepPartnerApiUrl}/partner/autocomplete`;

    return this.http
      .get<ApiResponse<Resource[]>>(url, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((result: ApiResponse<Resource[]>) => result.data),
        catchError(error => this.handleError(error))
      );
  }

  getReportConfiguration(): Observable<ReportConfiguration> {
    const url = `${this.msepPartnerApiUrl}/admin/report-configuration`;
    return this.http.get<ApiResponse<ReportConfiguration>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  searchAuthLogs(
    filters: AuthenticationLogFilters
  ): Observable<ApiResponse<AuthenticationLog[]>> {
    const url = `${this.msepPartnerApiUrl}/admin/authentication-logs`;
    const params = new HttpParams({
      fromObject: this.utilService.removeFalseyProperties(filters),
    });
    return this.http
      .get<ApiResponse<AuthenticationLog[]>>(url, { params })
      .pipe(
        map(result => result),
        catchError(error => this.handleError(error))
      );
  }

  searchPartners(
    filters?: AdminPartnerFilters
  ): Observable<ApiResponse<AdminPartner[]>> {
    const url = `${this.msepPartnerApiUrl}/partner/search`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http.get<ApiResponse<AdminPartner[]>>(url, { params }).pipe(
      map(result => result),
      catchError(error => this.handleError(error))
    );
  }

  sendTestEmail(): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/admin/email-health-check`;
    return this.http.post<void>(url, {}).pipe(catchError(this.handleError));
  }

  updateReportConfiguration(
    reportConfiguration: ReportConfiguration
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.msepPartnerApiUrl}/admin/report-configuration`,
        reportConfiguration
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface AuthenticationLog {
  email: string;
  reason: string;
  ipAddress: string;
  createdDate: Date;
}

export interface AuthenticationLogFilters extends SearchFilter {
  email?: string;
  reasonId?: number;
  ipAddress?: string;
  startDate?: string;
  endDate?: string;
}

export interface HistoricalHiresLog {
  changeReason: string;
  createdByUser: string;
  createdDate: Date;
  historicalHiresCount: number;
}

export interface HistoricalHiresLogFilters {
  skip: number;
  take: number;
  sortByProperty?: string;
  sortDirection?: string;
}

export interface ReportConfiguration {
  id: number;
  openDay: number;
  closeDay: number;
  override: boolean;
  historicalHiresCount: number;
  showVhfToAllUsers: boolean;
  showVhfToBetaUsers: boolean;
}
