<div
  class="flex flex-col wrap"
  *ngIf="score !== null; else noScore"
  [ngClass]="{
    green: score >= 80,
    yellow: score < 80 && score >= 60,
    orange: score < 60 && score >= 40,
    red: score < 40
  }"
>
  <strong class="description">{{ description }}</strong>
  <div class="help">
    For further explanation on your tracker, contact your MSEP Specialist
  </div>
</div>
<ng-template #noScore>
  In progress, contact your MSEP Specialist for more information
</ng-template>
