import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { AlertType } from 'shared/components/alert/alert.component';

@Component({
  selector: 'msep-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  exceptionId = 0;
  alertType = AlertType.Warn;
  homePage = '';

  constructor(private route: ActivatedRoute, private jwtService: JwtService) {}

  ngOnInit(): void {
    this.exceptionId = Number(this.route.snapshot.paramMap.get('exceptionId'));
    this.homePage = this.jwtService.getHomePage();
  }
}
