import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDateTime',
})
export class LocalDateTimePipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line
  transform(value: any): any {
    if (value == null) return '';
    /* 
        Our server dates are zero UTC offset. When we add the zone designator
        for the zero UTC offset ('Z'), Angular's DatePipe can use the
        server date's UTC offset to calculate the local time.
    */
    // Some times are formatted with AM/PM and need a space before the Z
    let dateTimeWithUtcZoneDesignator = value;
    if (dateTimeWithUtcZoneDesignator.match('[0-9]$')) {
      dateTimeWithUtcZoneDesignator += 'Z';
    } else {
      dateTimeWithUtcZoneDesignator += ' Z';
    }
    const customFormat = 'MM/dd/yyyy h:mm:ss a';
    return super.transform(dateTimeWithUtcZoneDesignator, customFormat);
  }
}
