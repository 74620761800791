<msep-page-title>
  <h1>Error Processing Request</h1>
</msep-page-title>

<msep-alert [type]="alertType">
  Sorry, an error has occurred while trying to process your request. Exception
  ID: {{ exceptionId }}
</msep-alert>

<p>
  Please return to the
  <a role="link" [routerLink]="homePage">home page</a> to continue.
</p>
