import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PartnerDashboardService,
  PartnerInfo,
} from 'core/services/partner/partner-dashboard.service';
import { Subscription } from 'rxjs';
import { PartnerTrackerComponent } from '../partner-tracker/partner-tracker.component';
import { PartnershipTypeId } from 'core/enums';

@Component({
  selector: 'msep-partner-page-title',
  templateUrl: './partner-page-title.component.html',
  styleUrls: ['./partner-page-title.component.scss'],
})
export class PartnerPageTitleComponent implements OnInit {
  isLoadingTracker = false;
  partnerInfo!: PartnerInfo;
  score: number | null = null;
  showTracker = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private overlay: Overlay,
    private partnerDashboardService: PartnerDashboardService
  ) {}

  get partnerIsApproved(): boolean {
    return this.partnerInfo.status == 'Approved';
  }

  get partnerIsSmallBusiness(): boolean {
    return (
      this.partnerInfo.partnershipType?.indexOf(
        PartnershipTypeId.MsepSmallBusiness
      ) != -1
    );
  }

  ngOnInit(): void {
    /* 
    If the activated route's resolver is solely dedicated for loading
    this component's data, the key is partnerInfo. If the activated route
    has other resolver needs, a custom object with partnerInfo as a key-value
    pair can be used.
    */
    this.partnerInfo =
      this.activatedRoute.snapshot.data['partnerInfo'] ??
      this.activatedRoute.snapshot.data['resolvedData'].partnerInfo;

    this.getScoreById();
  }

  displayOverlay(): void {
    const target = document.querySelector('#scoreIcon') as HTMLElement;
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'mat-elevation-z8',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
          },
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
          },
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
        ]),
    });
    const component = new ComponentPortal(PartnerTrackerComponent);
    const componentRef = overlayRef.attach(component);
    componentRef.instance.score = this.score;
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  private getScoreById(): void {
    this.isLoadingTracker = true;

    this.subscriptions.push(
      this.partnerDashboardService
        .getPartnerScoreFromSap(this.partnerInfo.partnerId)
        .subscribe({
          next: (result) => {
            this.score = result.score;
          },
          error: () => {
            this.isLoadingTracker = false;
          },
          complete: () => {
            this.isLoadingTracker = false;
          },
        })
    );
  }
}
