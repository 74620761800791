import { CandidateDegreeInfoPipe } from './candidate-degree-info.pipe';
import { CandidateJobInfoPipe } from './candidate-job-info.pipe';
import { CandidateLocationPipe } from './candidate-location.pipe';
import { FormatAddressPipe } from './format-address.pipe';
import { FriendlyBytesPipe } from './friendly-bytes.pipe';
import { IsJobPublishedPipe } from './is-job-published.pipe';
import { IsValueProvidedPipe } from './is-value-provided.pipe';
import { JobLogTimePipe } from './job-log-date.pipe';
import { LocalDateTimePipe } from './local-date-time.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { UtcDatePipe } from './utc-date.pipe';
import { UtcDateTimePipe } from './utc-date-time.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { PrefixHttpsPipe } from './prefix-https.pipe';
import { EventsLocalDateTimePipe } from './events-local-date-time.pipe';

export const SHARED_PIPES = [
  CandidateDegreeInfoPipe,
  CandidateJobInfoPipe,
  CandidateLocationPipe,
  EventsLocalDateTimePipe,
  FormatAddressPipe,
  FriendlyBytesPipe,
  IsJobPublishedPipe,
  IsValueProvidedPipe,
  JobLogTimePipe,
  LocalDateTimePipe,
  LocalDatePipe,
  PrefixHttpsPipe,
  UtcDateTimePipe,
  UtcDatePipe,
  YesNoPipe,
];
