import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'core/services/infrastructure/config.service';

@Component({
  selector: 'msep-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  version = this.configService.config.version;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    /* timeout needed to allow HTTP request to finish. there is not currently
    a lifecycle hook in mat-icon to handle the loaded state, so relying on
    timeout for now.
    */
    setTimeout(() => {
      this.fixDuplicateSvgIds();
    }, 1000);
  }

  // update MOS and SECO facebook/twitter svgs. without this update, we'll
  // have duplicate IDs on the page
  private fixDuplicateSvgIds(): void {
    this.updateSvgElement('#mosFacebook svg', 'mosFacebookSvg');
    this.updateSvgElement('#mosTwitter svg', 'mosTwitterSvg');
    this.updateSvgElement('#secoFacebook svg', 'secoFacebookSvg');
    this.updateSvgElement('#secoTwitter svg', 'secoTwitterSvg');
  }

  // update relevant attributes to have a 508-compliant SVG
  private updateSvgElement(selector: string, uniqueId: string): void {
    const svgElement = document.querySelector(selector);
    svgElement?.setAttribute('aria-labelledby', uniqueId);
    svgElement?.querySelector('title')?.setAttribute('id', uniqueId);
  }
}
