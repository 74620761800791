import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaLinksService {
  getMediaLinks(): MediaSpec[] {
    return [
      {
        id: 5,
        publishedDate: '09-26-2013',
        title: 'Bank of America -- Supporting Military Spouses',
        description:
          'Bank of America actively recruits military reservists, veterans and spouses. We celebrate\n        and honor our more than 5,000 employees who have served our country. Hear what several of them have to\n        say about their experience working at our company.',
        url: 'https://youtu.be/erEINDx0epM',
        thumbnail: 'https://img.youtube.com/vi/erEINDx0epM/0.jpg',
        altText:
          'YouTube Thumbnail Bank of America -- Supporting Military Spouses',
        type: 'video',
      },
      {
        id: 14,
        publishedDate: '01-24-2017',
        title: 'Guidance for Companies Looking to Hire',
        description:
          'Learn how Werner Enterprises, a Department of Defense Military Spouse Employment Partnership (MSEP) company,\n        has benefited from the MSEP program, as well as helped employees retain jobs during family deployments.\n        The Military Spouse Employment Partnership is a career partnership that connects military spouses with hundreds of partner\n        employers who have committed to recruit, hire, promote and retain military spouses.',
        url: 'https://youtu.be/tPb_qLMHhlM',
        thumbnail: 'https://img.youtube.com/vi/tPb_qLMHhlM/0.jpg',
        altText: 'YouTube Thumbnail Guidance for Companies Looking to Hire',
        type: 'video',
      },
      {
        id: 15,
        publishedDate: '12-12-2017',
        title: 'Jobs for MilSpouses on the Move',
        description:
          "Military spouses move frequently due to reassignment or deployment, so it's important that they're\n        able to take their job with them when they move. The Department of Defense's Military Spouse Employment Partnership (MSEP)\n        connects military spouses with hundreds of partner employers who have committed to recruit, hire, promote and retain\n        military spouses. Watch the video for more on how MSEP partners are thinking creatively when it comes to employment.\n\n Interviews were conducted during the MSEP Partner Induction Ceremony and Partner Meeting October 2-3, 2017.",
        url: 'https://youtu.be/fzPs0uTJ1Os',
        thumbnail: 'https://img.youtube.com/vi/fzPs0uTJ1Os/0.jpg',
        altText: 'YouTube Thumbnail Jobs for MilSpouses on the Move',
        type: 'video',
      },
      {
        id: 16,
        publishedDate: '01-16-2019',
        title: 'Johnson Controls: Military Spouse and Veteran Spotlight',
        description:
          'Johnson Controls hires veterans and military spouses and believes that “hiring from the military isn’t a gesture — it’s a business strategy.” Hear from a couple of their veteran and military spouse employees.',
        url: 'https://youtu.be/Z78nv2fqgfE',
        thumbnail: 'https://img.youtube.com/vi/Z78nv2fqgfE/0.jpg',
        altText:
          'YouTube Thumbnail Johnson Controls: Military Spouse and Veteran Spotlight',
        type: 'video',
      },
      {
        id: 17,
        publishedDate: '05-10-2019',
        title: 'How Booz Allen Hamilton Supports MilSpouse Careers',
        description:
          'Booz Allen Hamilton, a Department of Defense Military Spouse Employment Partnership (MSEP) company, understands the unique challenges of military life and advocate for military spouses when they need to relocate.',
        url: 'https://youtu.be/sfOPNKuB1IM',
        thumbnail: 'https://img.youtube.com/vi/sfOPNKuB1IM/0.jpg',
        altText:
          'YouTube Thumbnail How Booz Allen Hamilton Supports MilSpouse Careers',
        type: 'video',
      },
      {
        id: 18,
        publishedDate: '05-10-2019',
        title: 'Why Booz Allen Hamilton Hires Military Spouses',
        description:
          'Booz Allen Hamilton, a Department of Defense Military Spouse Employment Partnership (MSEP) company, knows it’s important to not only hire military spouses but retain them as well. Problem solving, flexibility and resiliency are a few of the reasons Booz Allen looks at military spouses as top talent.',
        url: 'https://youtu.be/e-IFPO6T8SQ',
        thumbnail: 'https://img.youtube.com/vi/e-IFPO6T8SQ/0.jpg',
        altText:
          'YouTube Thumbnail Why Booz Allen Hamilton Hires Military Spouses',
        type: 'video',
      },
      {
        id: 19,
        publishedDate: '08-11-2020',
        title: 'What Makes an MSEP Partner',
        description:
          "More than your average employer, Military Spouse Employment Partnership partners see the value in all MilSpouses bring to the table and they are committed to supporting their needs. Learn more: <a href='https://myseco.militaryonesource.mil/portal/msep' aria-describedby='opens in a new window'>https://myseco.militaryonesource.mil/portal/msep/jobsearch</a>",
        url: 'https://youtu.be/dYDP0oGCbjs',
        thumbnail: 'https://img.youtube.com/vi/dYDP0oGCbjs/0.jpg',
        altText: 'YouTube Thumbnail What Makes an MSEP Partner',
        type: 'video',
      },
    ];
  }
}

export interface MediaSpec {
  id: number;
  publishedDate: string;
  title: string;
  altText: string;
  description: string;
  url: string;
  thumbnail: string;
  type: string;
}
