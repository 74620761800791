import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'core/enums';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class CanViewChartsGuard {
  constructor(
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (
      this.authService.isAuthenticated() &&
      !this.jwtService.userHasRole(Role.User)
    ) {
      return true;
    }

    return this.handleInvalidAccess();
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/not-found']);
    return false;
  }
}
