<msep-alert
  [type]="expiredPasswordAlertType"
  title="Reset Password"
  *ngIf="isPasswordExpiring"
>
  Your password is about to expire in
  {{ daysUntilPasswordExpires }}
  {{ daysUntilPasswordExpires === 1 ? 'day' : 'days' }}. Click below to reset
  your password, and you will receive an email with instructions on how to reset
  your password. Please complete your reset password before logging in again.

  <div>
    <button
      mat-raised-button
      (click)="onResetPasswordClick()"
      [disabled]="isLoading"
      [class.spinner]="isLoading"
    >
      Reset Password
    </button>
  </div>
</msep-alert>
