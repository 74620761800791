import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks } from 'core/enums';
import {
  DashboardService,
  RecentlyAccessedPartner,
} from 'core/services/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-recently-accessed-partners-dock',
  templateUrl: './recently-accessed-partners-dock.component.html',
})
export class RecentlyAccessedPartnersDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  recentlyAccessed = Docks.RecentlyAccessedPartner;
  recentlyAccessedPartners$!: Observable<RecentlyAccessedPartner[]>;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.recentlyAccessedPartners$ =
      this.dashboardService.getRecentlyAccessedPartners();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }
}
