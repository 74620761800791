<ng-container [ngSwitch]="type">
  <ng-container
    *ngSwitchCase="ghostLoaderLogo"
    [ngTemplateOutlet]="logoGhost"
  ></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultGhost">
  </ng-container>
</ng-container>

<ng-template #logoGhost>
  <mat-card class="ghost-logo-card">
    <mat-card-content>
      <div class="ghost-logo-container"></div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #defaultGhost>
  <mat-card>
    <mat-card-title>
      <div class="ghost-header"></div>
    </mat-card-title>
    <mat-card-content>
      <div class="ghost-content"></div>
    </mat-card-content>
  </mat-card>
</ng-template>
