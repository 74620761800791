<a
  href="https://bluestarfam.org/4plus1/"
  aria-label="Blue Star Families 4 Plus 1 - link opens in a new window"
>
  <img
    src="assets/images/BSF_4_Plus_1_Badge_Icon.jpg"
    Title='This partner participates in the Hiring Our Heroes/Blue Star Families "4+1" initiative'
    alt="4+1 Blue Star Families Badge"
  />
</a>
