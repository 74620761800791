import { Component, Input } from '@angular/core';
import { AlertType } from '../alert/alert.component';

@Component({
  selector: 'msep-password-checklist',
  templateUrl: './password-checklist.component.html',
  styleUrls: ['./password-checklist.component.scss'],
})
export class PasswordChecklistComponent {
  @Input() password!: string;

  alertType = AlertType.Info;

  get hasCapitalLetter(): boolean {
    return /[A-Z]/.test(this.password);
  }

  get hasLowerCaseLetter(): boolean {
    return /[a-z]/.test(this.password);
  }

  get hasNumber(): boolean {
    return /\d/.test(this.password);
  }

  get hasSpecialCharacter(): boolean {
    return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.password);
  }

  get meetsLengthRequirement(): boolean {
    return this.password?.length >= 15;
  }
}
