<msep-page-title>
  <h1>Session Expired</h1>
</msep-page-title>

<div class="flex flex-col justify-center align-center">
  <p>
    Your session has expired. For security reasons, you have been logged out of
    your account.
  </p>
  <p>
    Please
    <a role="link" [routerLink]="['/notice-and-consent', 'login']">log in</a>
    again to start a new session.
  </p>
</div>
