import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { GhostLoaderType, PartnerResourceType } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import {
  PartnerResource,
  PartnerResourceFilter,
  PartnersService,
} from 'core/services/partners.service';
import { Resource, ResourcesService } from 'core/services/resources.service';
import { BehaviorSubject, Observable, delay, switchMap, tap } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-partner-resources',
  templateUrl: './partner-resources.component.html',
  styleUrls: ['./partner-resources.component.scss'],
})
export class PartnerResourcesComponent implements OnInit {
  @ViewChild('paginator', { static: false }) paginator:
    | MatPaginator
    | undefined;

  categories$ = this.resourceService.getResourceCategories();
  downloadProgress: number = 0;
  fileType = 'application/pdf';
  form!: UntypedFormGroup;
  ghostLoaderType = GhostLoaderType.PartnerLogo;
  isDownloading: boolean = false;
  isLoading = false;
  pageSize = 10;
  partnerResources: PartnerResource[] | undefined;
  partnerResourcesAction$!: Observable<PartnerResourceFilter>;
  partnerResources$!: Observable<ApiResponse<PartnerResource[]>>;
  resultTotal = 0;
  isTestEnvironment = false;
  userManual = PartnerResourceType.UserManual;

  private defaultFilter = {
    skip: 0,
    take: this.pageSize,
  } as PartnerResourceFilter;
  private partnerResourcesSubject = new BehaviorSubject<PartnerResourceFilter>(
    this.defaultFilter
  );

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private partnersService: PartnersService,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: null,
      title: null,
      categoryId: null,
    });

    this.setPartnerResourcesRequestStream();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadUserManual(): void {
    this.isDownloading = true;
    this.downloadProgress = 0;

    this.resourceService.getUserManualSize().subscribe({
      next: response => {
        const fileSize = response;
        const blockSize = 500 * 1024;
        const fileData: Blob[] = [];

        this.downloadBlocks(0, fileSize, blockSize, fileData, () => {
          if (!this.isTestEnvironment) {
            const fullFile = new Blob(fileData, { type: this.fileType });
            const downloadUrl = URL.createObjectURL(fullFile);

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'MsepUserManual.pdf';
            link.click();

            URL.revokeObjectURL(downloadUrl);
            this.isDownloading = false;
          }
        });
      },
      error: () => {
        this.isDownloading = false;
        this.notificationService.emitFailure('Failed to download user manual');
      },
    });
  }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  onCategoryChanged(event: MatSelectChange): void {
    this.form.patchValue({
      categoryId: event.value,
    });
    this.search();
  }

  onPagingChange(event: { skip: number; take: number }): void {
    const filter = this.form.value as PartnerResourceFilter;
    this.pageSize = event.take;
    filter.take = event.take;
    filter.skip = event.skip;
    this.partnerResourcesSubject.next(filter);
  }

  onReset(): void {
    this.form.reset();
    this.paginator?.firstPage();
    this.pageSize = this.defaultFilter.take;
    this.partnerResourcesSubject.next(this.defaultFilter);
  }

  onTitleSelected(title: Resource): void {
    this.form.patchValue({ id: title.id });
    this.search();
  }

  private downloadBlocks(
    currentBlockIndex: number,
    fileSize: number,
    blockSize: number,
    fileData: Blob[],
    onComplete: () => void
  ): void {
    if (currentBlockIndex * blockSize < fileSize) {
      this.resourceService.getUserManual(currentBlockIndex).subscribe({
        next: blockData => {
          blockData.arrayBuffer().then(arrayBuffer => {
            const uint8Array = new Uint8Array(arrayBuffer);
            const isBase64 = this.isBase64Encoded(uint8Array);

            let blob: Blob;

            if (isBase64) {
              const base64Blob = this.handleBase64Encoding(uint8Array);
              if (!base64Blob) {
                this.isDownloading = false;
                return;
              }
              blob = base64Blob;
            } else {
              blob = new Blob([uint8Array], { type: this.fileType });
            }

            fileData.push(blob);

            this.downloadProgress = Math.round(
              ((currentBlockIndex * blockSize) / fileSize) * 100
            );

            this.downloadBlocks(
              currentBlockIndex + 1,
              fileSize,
              blockSize,
              fileData,
              onComplete
            );
          });
        },
        error: () => {
          this.isDownloading = false;
          this.notificationService.emitFailure('Error downloading user manual');
        },
      });
    } else {
      onComplete();
    }
  }

  private isBase64Encoded(uint8Array: Uint8Array): boolean {
    const base64Pattern = /^[A-Za-z0-9+/=]+$/;
    const decodedString = new TextDecoder().decode(uint8Array);

    return base64Pattern.test(decodedString.trim());
  }

  private handleBase64Encoding(uint8Array: Uint8Array): Blob | null {
    try {
      const arrayBuffer = uint8Array.buffer;
      const base64String = new TextDecoder().decode(arrayBuffer);

      const binaryString = atob(base64String);
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      return new Blob([byteArray], { type: this.fileType });
    } catch (error) {
      this.notificationService.emitFailure('Error decoding Base64 data');
      return null;
    }
  }

  private search(): void {
    const filter = this.form.value as PartnerResourceFilter;
    filter.take = this.paginator?.pageSize ?? this.pageSize;
    filter.skip = 0;
    if (this.paginator !== undefined) {
      this.paginator.firstPage();
    }
    this.partnerResourcesSubject.next(filter);
  }

  private setPartnerResourcesRequestStream(): void {
    this.partnerResourcesAction$ = this.partnerResourcesSubject.asObservable();
    this.partnerResources$ = this.partnerResourcesAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap((filters: PartnerResourceFilter) =>
        this.partnersService.getPartnerResources(filters)
      ),
      tap(results => {
        this.resultTotal = results.total;
        this.partnerResources = results.data;
        this.isLoading = false;
      })
    );
  }
}
