<msep-dock
  title="Help Desk"
  [dockId]="helpDesk"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container *ngIf="helpDeskCounts$ | async as helpDeskCounts; else loading">
    <div *ngFor="let ticketCount of helpDeskCounts.ticketCounts">
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <div *ngIf="userIsSysOpWithPermission; else readOnlyTickets">
          <a
            role="link"
            [routerLink]="['/admin/contact-us-tickets']"
            [queryParams]="{ ticketStatusId: ticketCount.statusId }"
          >
            {{ ticketCount.description }} Tickets
          </a>
        </div>
        <ng-template #readOnlyTickets>
          <strong>{{ ticketCount.description }} Tickets</strong>
        </ng-template>
        <div class="count {{ ticketCount.count > 0 ? 'red' : 'default' }}">
          {{ ticketCount.count | number }}
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div>
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <div *ngIf="userIsSysOpWithPermission; else readOnlyPending">
          <a
            role="link"
            [routerLink]="['/admin/users']"
            [queryParams]="{ accountStatusId: userAccountPendingStatusId }"
          >
            Pending Users
          </a>
        </div>
        <ng-template #readOnlyPending>
          <strong>Pending Users</strong>
        </ng-template>
        <div
          class="count {{
            helpDeskCounts.pendingUserCount > 0 ? 'red' : 'default'
          }}"
        >
          {{ helpDeskCounts.pendingUserCount | number }}
        </div>
      </div>
    </div>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
