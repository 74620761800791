import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { ConfigService } from 'core/services/infrastructure/config.service';
import {
  getGoogleLocation,
  GoogleGeocoderRequest,
  GoogleGeocoderResult,
  GooglePlaceAutocompleteResult,
} from 'shared/models/google-location';
import { from, map, Observable, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'msep-google-place-autocomplete',
  templateUrl: './google-place-autocomplete.component.html',
  styleUrls: ['./google-place-autocomplete.component.scss'],
})
export class GooglePlaceAutocompleteComponent
  implements AfterViewInit, OnDestroy
{
  isLoading = false;
  @Input() disabled = false;
  @Input() geocodeRequest?: GoogleGeocoderRequest;
  @Input() required = false;
  @Input() value = '';
  @Output() googleAutocompleteChanged =
    new EventEmitter<GooglePlaceAutocompleteResult>();
  @Output() reverseGeocodeLoaded =
    new EventEmitter<GoogleGeocoderResult | null>();
  @ViewChild('autocompleteInput')
  autocompleteInput!: ElementRef<HTMLInputElement>;

  private autocompleteLoader$: Observable<typeof google>;
  private geocodeLoader$!: Observable<GoogleGeocoderResult>;
  private loaderOptions: LoaderOptions;
  private place!: google.maps.places.PlaceResult;
  private subscriptions: Subscription[] = [];

  constructor(private configService: ConfigService) {
    this.loaderOptions = {
      apiKey: this.configService.config.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    };
    const loader = new Loader(this.loaderOptions);
    this.autocompleteLoader$ = from(loader.load());
  }

  ngAfterViewInit(): void {
    this.initAutocomplete();
    if (this.geocodeRequest) {
      this.geocodeLoader$ = this.buildGeocodeObservable();
      this.getReverseGeocodingData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((x) => x.unsubscribe());
  }

  private buildGeocodeObservable(): Observable<GoogleGeocoderResult> {
    // create a separate loader from the autocomplete
    const loader = new Loader(this.loaderOptions);
    // convert the promise to an observable
    const loader$ = from(loader.load());

    return loader$.pipe(
      switchMap(() => {
        this.isLoading = true;
        const geocoder = new google.maps.Geocoder();
        const query = {} as google.maps.GeocoderRequest;

        if (this.geocodeRequest?.placeId) {
          // placeId gives the most accurate results, so always use it if provided
          query.placeId = this.geocodeRequest.placeId;
        } else if (this.geocodeRequest?.address) {
          // Check address second, will be more specific than LatLong
          query.address = this.geocodeRequest.address;
        } else if (!this.geocodeRequest?.coordinates?.isNullCoordinates) {
          const latlng = new google.maps.LatLng(
            Number(this.geocodeRequest?.coordinates?.latitude),
            Number(this.geocodeRequest?.coordinates?.longitude)
          );
          query.location = latlng;
        }

        return geocoder.geocode(query);
      }),
      map((result: google.maps.GeocoderResponse) => {
        if (!result || !result.results) {
          return {};
        }

        const addressComponentMostInfoIndex = result.results.reduce(
          (
            pendingIndex,
            current,
            currentIndexOfLongestArray,
            geocoderResults
          ) =>
            geocoderResults[pendingIndex].address_components.length >
            current.address_components.length
              ? pendingIndex
              : currentIndexOfLongestArray,
          0
        );

        const location = result.results[addressComponentMostInfoIndex];

        return {
          formattedAddress: location?.formatted_address,
          googleLocation: getGoogleLocation(
            location?.address_components,
            location?.geometry.location
          ),
        } as GoogleGeocoderResult;
      })
    );
  }

  private getReverseGeocodingData(): void {
    const subscription = this.geocodeLoader$
      .pipe(tap((result) => this.reverseGeocodeLoaded.emit(result)))
      .subscribe({
        next: () => (this.isLoading = false),
        error: () => (this.isLoading = false),
        complete: () => (this.isLoading = false),
      });
    this.subscriptions.push(subscription);
  }

  private initAutocomplete(): void {
    const subscription = this.autocompleteLoader$
      .pipe(
        tap(() => {
          const autocomplete = new google.maps.places.Autocomplete(
            this.autocompleteInput.nativeElement,
            { fields: ['place_id', 'geometry', 'name', 'address_components'] }
          );
          autocomplete.addListener('place_changed', () => {
            this.place = autocomplete.getPlace();
            const autocompleteResult = new GooglePlaceAutocompleteResult(
              this.place
            );
            this.googleAutocompleteChanged.emit(autocompleteResult);
          });
        })
      )
      .subscribe();

    this.subscriptions.push(subscription);
  }
}
