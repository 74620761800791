import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY, catchError, tap } from 'rxjs';
import { AccountService } from '../account.service';
import { Resource } from '../resources.service';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordResolverService {
  constructor(private accountService: AccountService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Resource[]> {
    const token = route.paramMap.get('token');
    if (token == null) {
      this.router.navigate(['/forgot-password']);
      return EMPTY;
    }

    return this.accountService.getUserChallengeQuestions(token).pipe(
      tap((result) => {
        if (result.length > 0) {
          return result;
        }
        this.router.navigateByUrl('/forgot-password?expiredToken=true');
        return EMPTY;
      }),
      catchError(() => {
        this.router.navigate(['/forgot-password']);
        return EMPTY;
      })
    );
  }
}
