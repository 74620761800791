<msep-dock
  title="Recently Accessed Partners"
  [dockId]="recentlyAccessed"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container
    *ngIf="recentlyAccessedPartners$! | async as accessedPartners; else loading"
  >
    <ng-container
      *ngIf="accessedPartners && accessedPartners.length > 0; else noResults"
    >
      <div *ngFor="let accessedPartner of accessedPartners; let last = last">
        <div
          class="flex flex-row justify-between align-center multiple-dock-items"
        >
          <div class="flex">
            <a
              role="link"
              [routerLink]="['/partner/dashboard', accessedPartner.partnerId]"
            >
              {{ accessedPartner.partnerName }}
            </a>
          </div>
          <div>
            {{ accessedPartner.accessedDate | localDateTime }}
          </div>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </ng-container>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
<ng-template #noResults>
  <msep-no-results></msep-no-results>
</ng-template>
