import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Faq } from 'core/services/resources.service';
import { UtilsService } from 'core/utilities/utils.service';

@Component({
  selector: 'msep-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
  faqs!: Faq[];
  urlPath = '/faqs';

  constructor(
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.faqs = this.activatedRoute.snapshot.data['resolvedData'];
    this.faqs?.forEach((faq) => {
      faq.answer =
        this.domSanitizer.sanitize(SecurityContext.HTML, faq.answer) ?? '';
    });
  }

  convertToKebabCase(string: string): string {
    return this.utilsService.convertToKebabCase(string);
  }
}
