<msep-dock
  title="Spouse Activity"
  [dockId]="spouseActivity"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container *ngIf="spousesHired$ | async as spousesHired; else loading">
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <div *ngIf="userHasPermission; else readOnly">
        <a role="link" [routerLink]="['/report/partners-and-spouses-hired']">
          Total Hired
        </a>
      </div>
      <ng-template #readOnly>
        <strong>Total Hired</strong>
      </ng-template>
      <div class="count default">{{ spousesHired | number }}</div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <ng-container *ngIf="clickApplyCount$ | async as totalClicks; else loading">
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <strong>Total Clicked Apply</strong>
      <div class="count default">
        {{ totalClicks.total | number }}
      </div>
    </div>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
