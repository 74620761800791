import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { utc } from 'moment';

@Pipe({
  name: 'jobLogDate',
})
export class JobLogTimePipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    // the log date is formatted a little weirdly, so let moment fix it for us
    const momentFormat = 'YYYY-MM-DD-HH-mm-ss';
    const formattedDate = utc(value, momentFormat).toDate();
    // now that we have a moment date, format it
    const msepFormat = 'MM/dd/yyyy h:mm:ss a zzzz';
    return super.transform(formattedDate, msepFormat);
  }
}
