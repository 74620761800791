<msep-page-title [forceBackgroundImageUse]="true">
  <div class="flex flex-row partner-name-wrap">
    <div
      *ngIf="partnerIsApproved && this.score !== null"
      class="score-icon-padding"
    ></div>
    <h1>{{ partnerInfo.partnerName }}</h1>
    <div
      *ngIf="
        partnerIsApproved && !partnerIsSmallBusiness && this.score !== null
      "
    >
      <mat-icon
        id="scoreIcon"
        (click)="displayOverlay()"
        (keydown)="displayOverlay()"
        [ngClass]="{
          green: score >= 80,
          yellow: score < 80 && score >= 60,
          orange: score < 60 && score >= 40,
          red: score < 40
        }"
        tabindex="0"
      >
        circle
      </mat-icon>
    </div>
  </div>
  <div
    *ngIf="partnerIsApproved && partnerInfo.inductedDate"
    class="induction-details"
  >
    MSEP Partner since {{ partnerInfo.inductedDate | date : 'yyyy' }}
  </div>
  <msep-partner-menu
    [organizationId]="partnerInfo.partnerId"
  ></msep-partner-menu>
</msep-page-title>
