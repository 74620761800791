import { Component, Input, OnDestroy } from '@angular/core';
import { PieChart } from 'core/enums';
import { BlobService } from 'core/services/blob.service';
import {
  ChartsService,
  PieChartMetricsFilter,
} from 'core/services/charts.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { Observable, Subscription } from 'rxjs';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Component({
  selector: 'msep-export-pie-chart-button',
  templateUrl: './export-pie-chart-button.component.html',
})
export class ExportPieChartButtonComponent implements OnDestroy {
  @Input() chartId!: PieChart;
  @Input() organizationId?: number;
  @Input() filters!: PieChartMetricsFilter;
  isLoading = false;
  chartsObservable$!: Observable<ServerBlobResult>;
  private subscriptions: Subscription[] = [];

  constructor(
    private blobService: BlobService,
    private chartsService: ChartsService,
    private notificationService: NotificationService
  ) {}

  onExportClick(): void {
    this.isLoading = true;
    this.setObservable();
    const subscription = this.chartsObservable$.subscribe({
      next: (result) => {
        this.blobService.download(result);
      },
      error: () => {
        this.notificationService.emitFailure(
          'Error exporting chart data. Please contact an administrator'
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((x) => x.unsubscribe());
  }

  private setObservable(): void {
    if (this.chartId === PieChart.RemoteTeleworkJob) {
      this.chartsObservable$ =
        this.chartsService.exportRemoteTeleworkJobMetrics(
          this.filters,
          this.organizationId
        );
    } else if (this.chartId === PieChart.RemoteTeleworkPartner) {
      this.chartsObservable$ =
        this.chartsService.exportRemoteTeleworkPartnerMetrics();
    } else if (this.chartId === PieChart.PartnerIndustry) {
      this.chartsObservable$ =
        this.chartsService.exportPartnerIndustryMetrics();
    } else if (this.chartId === PieChart.PartnershipType) {
      this.chartsObservable$ =
        this.chartsService.exportPartnershipTypeMetrics();
    } else {
      this.chartsObservable$ = this.chartsService.exportJobMetrics(
        this.filters,
        this.organizationId
      );
    }
  }
}
