import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AccountService,
  ChallengeQuestionAnswer,
  ResetPassword,
} from 'core/services/account.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { Resource } from 'core/services/resources.service';
import { Subscription } from 'rxjs';
import { AlertType } from 'shared/components/alert/alert.component';
import { EqualityValidator } from 'shared/validation/equality.validator';
import { GroupComparisonErrorStateMatcher } from 'shared/validation/group-errorstate-matcher';

@Component({
  selector: 'msep-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  alertType = AlertType.Info;
  challengeQuestions!: Resource[];
  form!: UntypedFormGroup;
  isLoading = false;
  matcher = new GroupComparisonErrorStateMatcher();
  showHidden: Map<string, boolean> = new Map([
    ['answer1', false],
    ['answer2', false],
    ['answer3', false],
    ['password', false],
    ['confirmPassword', false],
  ]);
  token!: string | null;

  private subscriptions: Subscription[] = [];
  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    const dto = {
      challengeQuestionAnswers: this.formatChallengeQuestionAnswers(),
      newPassword: this.form.get('passwordGroup.newPassword')?.value,
      token: this.token,
    } as ResetPassword;
    this.isLoading = true;
    const resetSubscription = this.accountService.resetPassword(dto).subscribe({
      next: () => {
        this.notificationService.emitSuccess(
          'Password has been reset successfully.'
        );
        this.router.navigate(['/']);
      },
      error: () => {
        this.notificationService.emitFailure(
          'Error resetting password. Please contact an administrator.'
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.subscriptions.push(resetSubscription);
  }

  toggleShowHidden(key: string): void {
    this.showHidden.set(key, !this.showHidden.get(key));
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((x) => x.unsubscribe());
  }

  private buildForm(): void {
    this.challengeQuestions = this.activatedRoute.snapshot.data['resolvedData'];
    const routeSubscription = this.activatedRoute.paramMap.subscribe(
      (params: ParamMap) => (this.token = params.get('token'))
    );
    this.subscriptions.push(routeSubscription);

    this.form = this.formBuilder.group({
      challengeAnswer1: [null, Validators.required],
      challengeAnswer2: [null, Validators.required],
      challengeAnswer3: [null, Validators.required],
      passwordGroup: this.formBuilder.group(
        {
          newPassword: [null, Validators.required],
          newPasswordConfirm: [null, Validators.required],
        },
        {
          validator: EqualityValidator.stringCompare(
            'newPassword',
            'newPasswordConfirm',
            'Passwords do not match'
          ),
        } as AbstractControlOptions
      ),
    });
  }

  private formatChallengeQuestionAnswers(): ChallengeQuestionAnswer[] {
    const result = [];
    for (let index = 0; index < this.challengeQuestions.length; index++) {
      const question = this.challengeQuestions[index];
      const answer = this.form.get(`challengeAnswer${index + 1}`)?.value;
      const challengeQuestionAnswer = {
        challengeQuestionId: question.id,
        answer: answer,
      } as ChallengeQuestionAnswer;

      result.push(challengeQuestionAnswer);
    }

    return result;
  }
}
