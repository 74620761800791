import { Component, Input } from '@angular/core';
import { Role } from 'core/enums';
import { JwtService } from 'core/services/infrastructure/jwt.service';

@Component({
  selector: 'msep-partner-menu',
  templateUrl: './partner-menu.component.html',
  styleUrls: ['./partner-menu.component.scss'],
})
export class PartnerMenuComponent {
  @Input() organizationId!: number;

  constructor(private jwtService: JwtService) {}

  canViewEvents(): boolean {
    return (
      this.jwtService.userHasRole(Role.SysOp) ||
      this.jwtService.userHasRole(Role.Govt) ||
      this.jwtService.userHasRole(Role.Specialist) ||
      this.jwtService.showVhfToAllUsers() ||
      (this.jwtService.showVhfToBetaUsers() && this.jwtService.isBetaUser())
    );
  }
}
