import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Permission, Role } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'msep-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent implements OnInit, OnDestroy {
  @Output() menuToggled = new EventEmitter<boolean>();
  isAuthenticated = false;
  isLoading = false;
  private subscriptions: Subscription[] = [];

  get getHideMenuClass(): string {
    return this.userIsSysOp() || this.userHasSpecialistRole()
      ? 'hide-menu'
      : '';
  }

  constructor(
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const navigationStartSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map(() => (this.isLoading = true))
      )
      .subscribe();

    const navigationOverSub = this.router.events
      .pipe(
        filter((event) => {
          return (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
          );
        }),
        map(() => (this.isLoading = false))
      )
      .subscribe();

    const authenticationSubscription =
      this.authenticationService.isAuthenticatedSubject
        .pipe(map((result) => (this.isAuthenticated = result)))
        .subscribe();

    this.subscriptions.push(
      navigationStartSub,
      navigationOverSub,
      authenticationSubscription
    );
  }

  getHomepageLink(): string {
    if (!this.isAuthenticated) return '/';

    return this.jwtService.getHomePage();
  }

  shouldShowPartnerDirectoryLink(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  shouldShowReportMenu(): boolean {
    if (this.userHasUserRole()) {
      return false;
    }

    return this.userHasManageReportsPermission();
  }

  toggle(): void {
    this.menuToggled.emit(true);
  }

  userHasHelpDeskPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.Helpdesk);
  }

  userHasManageJobsPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageJobs);
  }

  userHasManageReportsPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageReports);
  }

  userHasManageUsersPermission(): boolean {
    return this.jwtService.userHasPermission(Permission.ManageUsers);
  }

  userHasSpecialistRole(): boolean {
    return this.jwtService.userHasRole(Role.Specialist);
  }

  userHasUserRole(): boolean {
    return this.jwtService.userHasRole(Role.User);
  }

  userIsSysOp(): boolean {
    return this.jwtService.userHasRole(Role.SysOp);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}
