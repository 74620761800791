<div class="flex flex-100 flex-row datepicker-wrap">
  <mat-form-field class="flex-100" appearance="outline">
    <mat-label>{{ inputLabel }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      placeholder="Month and Year"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
      [required]="isRequired"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, picker)"
      panelClass="example-month-picker"
    >
    </mat-datepicker>
    <mat-hint><ng-content></ng-content></mat-hint>
  </mat-form-field>
</div>
