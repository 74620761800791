import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'core/services/infrastructure/google-analytics.service';

@Component({
  selector: 'msep-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  buttonNo = 'Cancel';
  buttonYes = 'Ok';
  linkUrl = '';
  message = 'Are you sure?';
  title = 'Confirm';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // eslint-disable-line
    private googleAnalyticService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
    this.buttonYes = this.data.buttonYes;
    this.buttonNo = this.data.buttonNo;
    this.data.confirm = true;
    this.linkUrl = this.data.linkUrl;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    if (this.linkUrl) {
      this.googleAnalyticService.trackExternalLinkClick(this.linkUrl);
      window.open(this.linkUrl, '_system');
    }
  }
}
