<a
  role="link"
  [routerLink]="[]"
  fragment="main-content"
  class="cdk-visually-hidden"
>
  Skip to content
</a>
<msep-cui-banner></msep-cui-banner>
<msep-mos-universal-nav></msep-mos-universal-nav>
<msep-idle-timeout-warning></msep-idle-timeout-warning>
<router-outlet></router-outlet>
