<div class="flex flex-row flex-wrap">
  <div class="flex-100" mat-dialog-title *ngIf="title">
    {{ title }}
  </div>
  <mat-dialog-content class="flex-100">
    <ng-template [cdkPortalOutlet]="templatePortal"></ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-raised-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
