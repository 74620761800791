import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AccountService } from 'core/services/account.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GroupComparisonErrorStateMatcher } from 'shared/validation/group-errorstate-matcher';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AlertType } from 'shared/components/alert/alert.component';
import { EqualityValidator } from 'shared/validation/equality.validator';
import { ConfigService } from 'core/services/infrastructure/config.service';

@Component({
  selector: 'msep-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  expiredTokenAlertType = AlertType.Warn;
  form!: UntypedFormGroup;
  isLoading = false;
  hasExpiredToken = false;
  matcher = new GroupComparisonErrorStateMatcher();
  recaptchaSiteKey = this.configService.config.recaptchaSiteKey;
  private submissionSuccessMessage =
    'If a valid email address was entered, instructions to reset your password have been sent to your email.';
  private subscription: Subscription | undefined;

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private jwtService: JwtService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      emailGroup: this.formBuilder.group(
        {
          email: [
            '',
            [Validators.required, Validators.email, Validators.maxLength(256)],
          ],
          confirmEmail: [
            '',
            [Validators.required, Validators.email, Validators.maxLength(256)],
          ],
        },
        {
          validator: EqualityValidator.stringCompare(
            'email',
            'confirmEmail',
            'Emails do not match'
          ),
        } as AbstractControlOptions
      ),
      reCaptcha: [null, Validators.required],
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.hasExpiredToken = params['expiredToken'] === 'true';
    });
  }

  onSubmit(): void {
    this.isLoading = true;

    this.subscription = this.accountService
      .sendPasswordResetTokenWithRecaptcha(
        this.form.value.emailGroup,
        this.form.value.reCaptcha
      )
      .subscribe({
        next: () => {
          this.notificationService.emitSuccess(this.submissionSuccessMessage);
          this.jwtService.routeToHomePage();
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
