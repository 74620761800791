import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'msep-notice-and-consent',
  templateUrl: './notice-and-consent.component.html',
})
export class NoticeAndConsentComponent {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  onAgreeClick(): void {
    this.router.navigate([
      this.activatedRoute.snapshot.paramMap.get('path') ?? 'home',
    ]);
  }
}
