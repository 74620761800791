import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as phoneLib from 'google-libphonenumber';

export function phoneValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { value } = control;

  if (value && !isValidPhoneNumber(value)) {
    return { phoneNumber: true };
  }

  return null;
}

function isValidPhoneNumber(input: string): boolean {
  const phoneUtil = phoneLib.PhoneNumberUtil.getInstance();

  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(input, 'US');
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
}
