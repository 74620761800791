<ng-container [ngSwitch]="type">
  <ng-container
    *ngSwitchCase="ghostTypePage"
    [ngTemplateOutlet]="pageGhost"
  ></ng-container>
  <ng-container
    *ngSwitchCase="ghostTypeTable"
    [ngTemplateOutlet]="tableGhost"
  ></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultGhost">
  </ng-container>
</ng-container>

<ng-template #pageGhost>
  <div class="ghost-page">
    <div class="ghost-paragraph">
      <div class="ghost-content"></div>
      <div class="ghost-content"></div>
      <div class="ghost-content"></div>
    </div>
    <div class="ghost-paragraph">
      <div class="ghost-content"></div>
      <div class="ghost-content"></div>
      <div class="ghost-content"></div>
    </div>
  </div>
</ng-template>

<ng-template #tableGhost>
  <table
    class="mat-table cdk-table ghost-table mat-elevation-z2"
    role="table"
    aria-label="This table indicates a loading state and disappears when the data is loaded"
  >
    <thead class="cdk-visually-hidden">
      <th></th>
    </thead>
    <tbody role="rowgroup">
      <tr *ngFor="let row of tableRowGhosts">
        <td *ngFor="let column of tableColumnGhosts">
          <div class="ghost-content"></div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #defaultGhost>
  <msep-ghost-loader-mat-card
    [ngClass]="flexClasses"
    *ngFor="let ghost of ghosts"
    [type]="type"
  ></msep-ghost-loader-mat-card>
</ng-template>
