import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SeoService {
  private readonly defaultTitle = 'Military Spouse Employment Partnership';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly htmlTitle: Title,
    private readonly meta: Meta
  ) {}

  getMetaDetailsFromRoute(): SeoMetaDetails {
    let child = this.activatedRoute.firstChild;
    while (child?.firstChild) {
      child = child.firstChild;
    }

    const metaDetails: SeoMetaDetails = { title: this.defaultTitle };
    if (child?.snapshot.data['title']) {
      metaDetails.title = child.snapshot.data['title'];
    }
    if (child?.snapshot.data['description']) {
      metaDetails.description = child.snapshot.data['description'];
    }

    return metaDetails;
  }

  setMetaTags(metaDetails: SeoMetaDetails): void {
    const { title, description } = metaDetails;
    this.setMetaTitle(title);
    if (description) {
      this.setMetaDescription(description);
    } else {
      this.deleteMetaDescription();
    }
  }

  setTitle(title: string): void {
    this.htmlTitle.setTitle(title);
  }

  private deleteMetaDescription(): void {
    this.meta.removeTag('name=description');
  }

  private setMetaDescription(description: string): void {
    if (this.meta.getTag('name=description')) {
      this.meta.updateTag({ name: 'description', content: description });
    } else {
      this.meta.addTag({ name: 'description', content: description });
    }
  }

  private setMetaTitle(title: string): void {
    if (this.meta.getTag('name=title')) {
      this.meta.updateTag({ name: 'title', content: title });
    } else {
      this.meta.addTag({ name: 'title', content: title });
    }
  }
}

interface SeoMetaDetails {
  title: string;
  description?: string;
}
