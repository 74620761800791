import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'msep-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @Input() altText = 'Partner Video';
  @Input() videoPath!: string;

  source!: string;
  wistiaClass!: string;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.addScript(this.videoPath);
    this.getVideoPath(this.videoPath);
    this.addVideoEndScript();
  }

  private addScript(videoPath: string): void {
    const wistia = document.createElement('script');
    wistia.async = true;
    wistia.id = 'wistia-media';
    wistia.src = 'https://fast.wistia.com/embed/medias/' + videoPath + '.jsonp';
    document.head.appendChild(wistia);

    const base = document.createElement('script');
    base.async = true;
    base.id = 'wistia-external';
    base.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    document.head.appendChild(base);
  }

  private addVideoEndScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'wistia-video-end';
    script.innerHTML = `
    window._wq = window._wq || [];
    _wq.push({ id: "my_video", onReady: function(video) {
      video.bind('end', function() {
        document.getElementById('wistia-video-frame').setAttribute('hidden', true);
        document.getElementById('video-recommendations').removeAttribute('hidden');
      });
    }});`;

    document.head.appendChild(script);
  }

  private getVideoPath(videoPath: string): void {
    if (this.videoPath) {
      this.source =
        this.domSanitizer.sanitize(
          SecurityContext.URL,
          'https://fast.wistia.com/embed/medias/' + videoPath + '/swatch'
        ) ?? '';

      this.wistiaClass =
        'wistia_embed wistia_async_' +
        videoPath +
        ' videoFoam=true autoplay=false';
    }
  }

  ngOnDestroy(): void {
    const scripts = document.head.querySelectorAll('script[id^="wistia-"]');
    scripts.forEach((s) => {
      s.remove();
    });
  }
}
