import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateUtilsService } from 'core/utilities/date-utils.service';
import momentImport, { default as rollupMoment, Moment } from 'moment';

const moment = rollupMoment || momentImport;

export const monthYearFormat = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'msep-monthly-datepicker',
  templateUrl: './monthly-datepicker.component.html',
  styleUrls: ['./monthly-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: monthYearFormat,
    },
  ],
})
export class MonthlyDatepickerComponent implements OnChanges {
  @Input() passedDate: Date | undefined;
  @Input() inputLabel = 'Monthly Date Picker';
  @Input() isDisabled!: boolean;
  @Input() isRequired = false;
  @Output() selectedDateMonth = new EventEmitter<Date>();

  constructor(private dateUtils: DateUtilsService) {}
  date = new UntypedFormControl({ value: null, disabled: false });
  minDate!: Date;
  maxDate!: Date;

  ngOnChanges(): void {
    if (this.passedDate) {
      this.date.reset({ value: this.passedDate, disabled: this.isDisabled });
    }
    const ytdDetails = this.dateUtils.getYtdDetails();
    this.minDate = ytdDetails.minDate;
    this.maxDate = ytdDetails.maxDate;
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);

    this.selectedDateMonth.emit(this.date.value);
    datepicker.close();
  }

  chosenYearHandler(normalizedYear: Moment): void {
    this.date.setValue(moment());
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
}
