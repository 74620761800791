import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CORE_INTERCEPTORS } from 'core';
import { APP_MODULES } from '.';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ConfigService,
  CONFIG_SERVICE_PROVIDER,
} from './core/services/infrastructure/config.service';
import { LAYOUT_COMPONENTS } from './layout';
import { MaterialModule } from './shared/material.module';

@NgModule({
  declarations: [AppComponent, LAYOUT_COMPONENTS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    APP_MODULES,
    MaterialModule,
    AppRoutingModule,
  ],
  providers: [ConfigService, CONFIG_SERVICE_PROVIDER, CORE_INTERCEPTORS],
  bootstrap: [AppComponent],
})
export class AppModule {}
