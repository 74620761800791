import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY, map, catchError } from 'rxjs';
import {
  PartnerProfile,
  PartnersService,
} from 'core/services/partners.service';
import { UtilsService } from 'core/utilities/utils.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerProfileResolverService {
  constructor(
    private partnersService: PartnersService,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PartnerProfile> {
    const id = route.paramMap.get('id');
    if (id === null || isNaN(+id) || !this.utilsService.isInt32(+id)) {
      this.router.navigate(['/our-partners']);
      return EMPTY;
    }

    return this.partnersService.getPartnerProfile(+id).pipe(
      map((result) => result),
      catchError(() => {
        this.router.navigate(['/our-partners']);
        return EMPTY;
      })
    );
  }
}
