import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Faq, ResourcesService } from '../resources.service';

@Injectable({
  providedIn: 'root',
})
export class FaqsResolverService {
  constructor(private resourcesService: ResourcesService) {}

  resolve(): Observable<Faq[]> {
    return this.resourcesService.getFaqs();
  }
}
