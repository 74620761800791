import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'msep-partner-tracker',
  templateUrl: './partner-tracker.component.html',
  styleUrls: ['./partner-tracker.component.scss'],
})
export class PartnerTrackerComponent implements OnInit {
  @Input() score!: number | null;

  description = '';

  ngOnInit(): void {
    if (this.score) {
      if (this.score >= 80) {
        this.description = 'Highly Engaged';
      } else if (this.score >= 60) {
        this.description = 'Opportunity to Increase Engagement';
      } else if (this.score >= 40) {
        this.description = 'Limited Demonstration of Engagement';
      } else {
        this.description = 'No Engagement';
      }
    }
  }
}
