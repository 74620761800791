import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line
  transform(value: any): any {
    const msepFormat = 'MM/dd/yyyy';
    return super.transform(value, msepFormat, 'UTC');
  }
}
