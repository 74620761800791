<nav class="msep-side-nav" aria-label="msep-side-nav">
  <div *ngIf="isAuthenticated" class="flex flex-col justify-end sidenav-header">
    <div>
      <div class="label-hello margin-top-20">Hello</div>
      <strong class="label-name"> {{ getDisplayName() }} </strong>
      <div class="label-login margin-top-20">Last Login</div>
      <div class="label-login-details">
        {{ getLastLoginDate() | localDateTime }}
      </div>
    </div>
  </div>

  <mat-divider *ngIf="isAuthenticated"></mat-divider>
  <mat-list class="secondaryLinks">
    <ng-container *ngIf="isAuthenticated; else publicMenu">
      <mat-list-item>
        <a
          aria-label="home"
          [routerLink]="getHomepageLink()"
          (click)="toggle()"
        >
          Home
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!userHasUserRole()">
        <a
          aria-label="partners"
          [routerLink]="['/partner/search']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Partners
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="shouldShowPartnerDirectoryLink()">
        <a
          aria-label="partner-directory"
          [routerLink]="['/our-partners']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Partner Directory
        </a>
      </mat-list-item>
      <mat-accordion class="left sub-menu" *ngIf="userIsSysOp()">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Admin </mat-panel-title>
          </mat-expansion-panel-header>

          <a
            class="flex justify-end"
            aria-label="authentication-logs"
            [routerLink]="['/admin/authentication-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Authentication Logs
          </a>
          <a
            class="flex justify-end"
            aria-label="email-health-check"
            [routerLink]="['/admin/email-health-check']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Email Health Check
          </a>
          <a
            class="flex justify-end"
            aria-label="email-templates"
            [routerLink]="['/content-management/email-templates']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Email Templates
          </a>
          <a
            class="flex justify-end"
            aria-label="event-logs"
            [routerLink]="['/admin/event-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Event Logs
          </a>
          <a
            class="flex justify-end"
            aria-label="exception-logs"
            [routerLink]="['/admin/exception-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Exception Logs
          </a>
          <a
            class="flex justify-end"
            aria-label="faqs"
            [routerLink]="['/content-management/faqs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            FAQs
          </a>
          <a
            class="flex justify-end"
            aria-label="government-dashboard"
            [routerLink]="['/admin/government-view']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Government Dashboard
          </a>
          <a
            class="flex justify-end"
            aria-label="help-desk-tickets"
            *ngIf="userHasHelpDeskPermission()"
            [routerLink]="['/admin/contact-us-tickets']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Help Desk Tickets
          </a>
          <a
            class="flex justify-end"
            aria-label="job-feed-logs"
            [routerLink]="['/admin/job-feed-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Feed Logs
          </a>
          <a
            class="flex justify-end"
            aria-label="job-feeds"
            *ngIf="userHasManageJobsPermission()"
            [routerLink]="['/admin/job-feeds']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Feeds
          </a>
          <a
            class="flex justify-end"
            aria-label="job-posts"
            [routerLink]="['/admin/jobs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Job Posts
          </a>
          <a
            class="flex justify-end"
            aria-label="360-newsletters"
            [routerLink]="['/content-management/manage-360-newsletters']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            360 Newsletters
          </a>
          <a
            class="flex justify-end"
            aria-label="partner-training-videos"
            [routerLink]="['/content-management/partner-training-videos']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Partner Training Videos
          </a>
          <a
            class="flex justify-end"
            aria-label="report-configuration"
            *ngIf="userHasManageReportsPermission()"
            [routerLink]="['/admin/report-configuration']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Report Configuration
          </a>
          <a
            class="flex justify-end"
            aria-label="security-alert-logs"
            [routerLink]="['/admin/security-alert-logs']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Security Alert Logs
          </a>
          <a
            class="flex justify-end"
            aria-label="specialist-dashboard"
            [routerLink]="['/admin/specialist-view']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Specialist Dashboard
          </a>
          <a
            class="flex justify-end"
            aria-label="system-alerts"
            [routerLink]="['/content-management/system-alerts']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            System Alerts
          </a>
          <a
            class="flex justify-end"
            aria-label="test-scheduled-tasks"
            [routerLink]="['/admin/test-scheduled-tasks']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Test Scheduled Tasks
          </a>
          <a
            class="flex justify-end"
            aria-label="users"
            *ngIf="userHasManageUsersPermission()"
            [routerLink]="['/admin/users']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Users
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-list-item *ngIf="shouldShowReportMenu()">
        <a
          aria-label="reports"
          [routerLink]="['/report/dashboard']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Reports
        </a>
      </mat-list-item>
      <mat-list-item *ngIf="!userHasUserRole()">
        <a
          aria-label="charts"
          [routerLink]="['/charts/dashboard']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Charts
        </a>
      </mat-list-item>
      <mat-accordion class="left sub-menu">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title> Help </mat-panel-title>
          </mat-expansion-panel-header>

          <a
            aria-label="contact-us"
            [routerLink]="['/help/contact-us']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Contact Us
          </a>
          <a
            aria-label="partner-resources"
            [routerLink]="['/partner-resources']"
            (click)="toggle()"
            routerLinkActive="active-menu-link"
          >
            Partner Resources
          </a>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-list-item *ngIf="userHasSpecialistRole() || userIsSysOp()">
        <a
          aria-label="manage-organizations"
          [routerLink]="['/specialist/manage-partners']"
          (click)="toggle()"
          routerLinkActive="active-menu-link"
        >
          Manage Organizations
        </a>
      </mat-list-item>
    </ng-container>
  </mat-list>

  <mat-divider></mat-divider>

  <div class="flex justify-center action-buttons">
    <button
      color="accent"
      mat-raised-button
      *ngIf="!isAuthenticated"
      (click)="onLogin()"
    >
      Log In
    </button>
    <button mat-raised-button (click)="onLogout()" *ngIf="isAuthenticated">
      Log Out
    </button>
  </div>

  <ng-template #publicMenu>
    <div class="flex-col" class="secondaryLinks">
      <mat-list-item>
        <a
          class="flex justify-end align-center"
          aria-label="home"
          [routerLink]="getHomepageLink()"
          (click)="toggle()"
        >
          Home
        </a>
      </mat-list-item>
      <mat-list-item>
        <a
          class="flex justify-end align-center"
          aria-label="about-us"
          [routerLink]="['about-us']"
          (click)="toggle()"
        >
          About
        </a>
      </mat-list-item>

      <mat-accordion class="sub-menu">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="justify-end">
              The Partnership
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a
            class="flex justify-end align-center"
            aria-label="become-a-partner"
            [routerLink]="['/become-a-partner']"
            (click)="toggle()"
          >
            Become a Partner
          </a>
          <a
            class="flex justify-end align-center"
            aria-label="our-partners"
            [routerLink]="['/our-partners']"
            (click)="toggle()"
          >
            Our Partners
          </a>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-list-item>
        <a
          class="flex justify-end align-center"
          aria-label="media"
          [routerLink]="['/media']"
          (click)="toggle()"
        >
          Media
        </a>
      </mat-list-item>
      <mat-list-item>
        <a
          class="flex justify-end align-center"
          aria-label="faqs"
          [routerLink]="['/faqs']"
          (click)="toggle()"
        >
          FAQs
        </a>
      </mat-list-item>

      <mat-accordion class="sub-menu">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="justify-end"> Help </mat-panel-title>
          </mat-expansion-panel-header>

          <a
            class="flex justify-end align-center"
            aria-label="contact-us"
            [routerLink]="['/notice-and-consent', 'contact-us']"
            (click)="toggle()"
          >
            Contact Us
          </a>
          <a
            class="flex justify-end align-center"
            aria-label="partner-resources"
            [routerLink]="['/partner-resources']"
            (click)="toggle()"
          >
            Partner Resources
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-template>
</nav>
