import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixHttps',
})
export class PrefixHttpsPipe implements PipeTransform {
  transform(url: string): string {
    if (!url) {
      return '';
    }

    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }

    return url;
  }
}
