import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks } from 'core/enums';
import {
  DashboardService,
  PartnerMetrics,
} from 'core/services/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-partner-metrics-dock',
  templateUrl: './partner-metrics-dock.component.html',
})
export class PartnerMetricsDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  partnerMetrics = Docks.PartnerMetrics;
  partnerMetrics$!: Observable<PartnerMetrics>;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.partnerMetrics$ = this.dashboardService.getPartnerMetrics();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }
}
