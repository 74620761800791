import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  addNDaysToDate(n: number, date: Date | undefined): Date | undefined {
    if (!date) return date;
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + n);
    return newDate;
  }

  buildEncodedS3Url(s3BucketUrl: string, fileName: string): string {
    return (
      s3BucketUrl + '/' + encodeURIComponent(fileName).replace(/%20/g, '+')
    );
  }

  convertToKebabCase(term: string): string {
    term = term
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
    return term;
  }

  getDateAtMidnight(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    date.setUTCHours(0, 0, 0, 0);

    return date.toISOString();
  }

  getDateAtLatestTimestamp(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    date.setUTCHours(23, 59, 59, 999);

    return date.toISOString();
  }

  getStringListFromArray(input: string[]): string {
    return input.join(', ');
  }

  isInt32(input: number): boolean {
    return input <= Math.pow(2, 31) - 1;
  }

  removeFalseyProperties<O extends object>(object: O): Partial<O> {
    const falseyValues = new Set<unknown>([undefined, null, '']);
    const output = <Partial<O>>{};

    Object.keys(object)
      .filter((key) => !falseyValues.has(object[<keyof O>key]))
      .forEach((key) => (output[<keyof O>key] = object[<keyof O>key]));

    return output;
  }
}
