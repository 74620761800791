import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStatus, Permission, Role } from 'core/enums';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { JwtToken } from 'shared/models/jwt-token';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class JwtService {
  private refreshTokenKey: string;
  private tokenKey: string;
  tokenStream: Observable<string>;

  constructor(private configService: ConfigService, private router: Router) {
    this.refreshTokenKey = this.configService.config.refreshTokenStorageKey;
    this.tokenKey = this.configService.config.tokenStorageKey;
    this.tokenStream = new Observable<string>((obs) => {
      obs.next(this.getToken());
    });
  }

  decodeToken(): JwtToken | null {
    const token = this.getToken();
    if (!token) {
      return null;
    }

    return jwtDecode<JwtToken>(token);
  }

  deleteAllTokens(): void {
    sessionStorage.clear();
  }

  doesTokenExist(): boolean {
    return Boolean(this.getToken());
  }

  getDaysUntilPasswordExpires(): number {
    const decodedToken = this.decodeToken();
    return Number(decodedToken?.daysUntilPasswordExpires) ?? 0;
  }

  getEmail(): string {
    const decodedToken = this.decodeToken();

    return decodedToken?.email ?? '';
  }

  getHomePage(): string {
    const organizationId = this.getOrganizationId();
    const organizationStatus = this.getOrganizationApplicationStatusId();
    switch (true) {
      case this.decodeToken() === null:
        return '/';
      case this.userHasRole(Role.SysOp):
        return '/admin/dashboard';
      case this.userHasRole(Role.Specialist):
        return '/specialist/dashboard';
      case this.userHasRole(Role.Govt):
        return '/government/dashboard';
      case organizationStatus === 0:
        return '/registration/partner-application';
      case organizationStatus === ApplicationStatus.Approved:
        return `/partner/dashboard/${organizationId}`;
      default:
        return `/registration/application-dashboard/${organizationId}`;
    }
  }

  getLastLoginDate(): string {
    const decodedToken = this.decodeToken();

    return decodedToken?.lastLoginDate ?? '';
  }

  getOrganizationId(): number {
    const decodedToken = this.decodeToken();

    return Number(decodedToken?.organizationId) ?? 0;
  }

  getOrganizationApplicationStatusId(): number {
    const decodedToken = this.decodeToken();

    return Number(decodedToken?.organizationApplicationStatusId) ?? 0;
  }

  getToken(): string {
    return sessionStorage.getItem(this.tokenKey) ?? '';
  }

  getRefreshToken(): string {
    return sessionStorage.getItem(this.refreshTokenKey) ?? '';
  }

  getTokenExpiration(): Date {
    const decodedToken = this.decodeToken();
    const date = new Date(0);
    date.setUTCSeconds(decodedToken?.exp ?? 0);

    return date;
  }

  getUserId(): number {
    const token = this.getToken();

    if (!token) {
      return 0;
    }

    const decodedToken = this.decodeToken();

    return Number(decodedToken?.id ?? 0);
  }

  getValue(key: keyof JwtToken): JwtToken[keyof JwtToken] | null {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      return null;
    }
    return decodedToken[key];
  }

  isBetaUser(): boolean {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      return false;
    }
    return decodedToken.isBetaUser === 'True';
  }

  isTokenExpired(offsetSeconds = 0): boolean {
    if (!this.doesTokenExist()) {
      return true;
    }

    const date = this.getTokenExpiration();

    return date.valueOf() <= new Date().valueOf() + offsetSeconds * 1000;
  }

  isPasswordExpiring(): boolean {
    return Number(this.decodeToken()?.daysUntilPasswordExpires) <= 10;
  }

  routeToHomePage(): void {
    this.router.navigate([this.getHomePage()]);
  }

  setToken(token: string): void {
    sessionStorage.setItem(this.tokenKey, token);
  }

  setRefreshToken(token: string): void {
    sessionStorage.setItem(this.refreshTokenKey, token);
  }

  showVhfToAllUsers(): boolean {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      return false;
    }
    return decodedToken.showVhfToAllUsers === 'True';
  }

  showVhfToBetaUsers(): boolean {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      return false;
    }
    return decodedToken.showVhfToBetaUsers === 'True';
  }

  userHasPermission(permissionToCheck: Permission): boolean {
    return this.getPermissions().some(
      (permission) => permission === permissionToCheck
    );
  }

  userHasRole(roleToCheck: Role): boolean {
    return this.getRole() === roleToCheck;
  }

  private getPermissions(): Permission[] {
    const decodedToken = this.decodeToken();
    let permissions = decodedToken?.permissions ?? [];

    if (typeof permissions === 'string') {
      permissions = [permissions];
    }

    return permissions;
  }

  private getRole(): Role | undefined {
    return this.decodeToken()?.role;
  }
}
