import { Job } from 'core/services/jobs.service';

export class Coordinates {
  readonly latitude: number;
  readonly longitude: number;
  readonly isNullCoordinates: boolean;

  constructor(coordinates: { latitude: number; longitude: number }) {
    this.latitude = coordinates.latitude;
    this.longitude = coordinates.longitude;
    this.isNullCoordinates =
      isNaN(coordinates.latitude) && isNaN(coordinates.longitude);
  }
}

export class GoogleGeocoderRequest {
  readonly address?: string;
  readonly coordinates?: Coordinates;
  readonly placeId?: string;

  constructor(job: Job) {
    this.coordinates = new Coordinates({
      latitude: Number(job.latitude),
      longitude: Number(job.longitude),
    });
    this.placeId = job.googlePlaceId;
    this.address = this.buildAddress(job);
  }

  buildAddress(job: Job): string {
    const { address1, city, state, postal } = job;
    return [address1, city, state, postal].filter(Boolean).join(' ');
  }
}

export interface GoogleGeocoderResult {
  formattedAddress?: string;
  googleLocation?: GoogleLocation;
}

export class GooglePlaceAutocompleteResult {
  googleLocation?: GoogleLocation;
  isStateOnly: boolean;
  placeId?: string;

  constructor(placeResult: google.maps.places.PlaceResult) {
    this.placeId = placeResult.place_id;
    this.isStateOnly = this.locationHasStateInformationOnly(
      placeResult.address_components
    );

    if (placeResult.address_components === undefined) {
      return;
    }

    if (this.isStateOnly) {
      this.googleLocation = {
        state: this.getStateCode(placeResult.address_components),
      } as GoogleLocation;

      return;
    }

    this.googleLocation = getGoogleLocation(
      placeResult.address_components,
      placeResult.geometry?.location
    );
  }

  private getStateCode(
    addressComponents: google.maps.GeocoderAddressComponent[] | undefined
  ): string | null {
    const stateComponent = addressComponents?.find((addressComponent) =>
      addressComponent.types.includes(AddressComponentType.State)
    );
    if (stateComponent === undefined) return null;
    return stateComponent.short_name;
  }

  /*
   * If the location provided is a state or has state as its lowest-level
   * detail (i.e., Virginia, USA; Maryland, USA; or California, USA),
   * filter by the two-letter state code (i.e., VA, MD, or CA) in Elasticsearch.
   */
  private locationHasStateInformationOnly(
    addressComponents: google.maps.GeocoderAddressComponent[] | undefined
  ): boolean {
    if (addressComponents === undefined) return false;
    const addressComponentTypes = new Set<AddressComponentType>();

    addressComponents.forEach((addressComponent) => {
      addressComponent.types.forEach((addressComponentType) => {
        addressComponentTypes.add(<AddressComponentType>addressComponentType);
      });
    });

    const hasStateInformation =
      addressComponentTypes?.has(AddressComponentType.State) ?? false;

    return (
      hasStateInformation &&
      !this.hasNonStateLocationInformation(addressComponentTypes)
    );
  }

  private hasNonStateLocationInformation(
    addressComponentTypes: Set<AddressComponentType>
  ): boolean {
    const typesToCheck = [
      AddressComponentType.StreetNumber,
      AddressComponentType.Address1,
      AddressComponentType.City,
      AddressComponentType.ZipCode,
      AddressComponentType.County,
    ];
    return typesToCheck.some((type) => addressComponentTypes?.has(type));
  }
}

export function getCoordinates(
  location: google.maps.LatLng | undefined
): Coordinates | undefined {
  if (!location) {
    return undefined;
  }
  const latitude = location.lat();
  const longitude = location.lng();
  return new Coordinates({ latitude, longitude });
}

export function getGoogleLocation(
  addressComponents: google.maps.GeocoderAddressComponent[],
  latitudeAndLongtitude?: google.maps.LatLng
): GoogleLocation {
  const location = {} as GoogleLocation;
  // loop through the provided location components and build
  // the full location object
  for (const addressComponent of addressComponents) {
    const componentType = addressComponent.types[0];
    switch (componentType) {
      case AddressComponentType.StreetNumber:
        location.address1 = addressComponent.long_name;
        break;
      case AddressComponentType.Address1:
        // add a space if the number is already set
        location.address1 =
          location.address1 && location.address1?.length > 0
            ? ` ${addressComponent.long_name}`
            : addressComponent.long_name;
        break;
      case AddressComponentType.City:
        location.city = addressComponent.long_name;
        break;
      case AddressComponentType.State:
        location.state = addressComponent.short_name;
        break;
      case AddressComponentType.Country:
        location.country = addressComponent.long_name;
        break;
      case AddressComponentType.ZipCode:
        location.zip = addressComponent.long_name;
        break;
      default:
        break;
    }
  }

  if (latitudeAndLongtitude) {
    location.coordinates = getCoordinates(latitudeAndLongtitude);
  }

  return location;
}

export interface GoogleLocation {
  address1?: string;
  city?: string;
  coordinates?: Coordinates;
  country?: string;
  state?: string;
  zip?: string;
}

export enum AddressComponentType {
  StreetNumber = 'street_number',
  Address1 = 'route',
  City = 'locality',
  County = 'administrative_area_level_2',
  State = 'administrative_area_level_1',
  ZipCode = 'postal_code',
  Country = 'country',
}
