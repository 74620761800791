import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  SystemAlert,
  SystemAlertService,
} from 'core/services/cms/system-alert.service';
import { Observable, switchMap } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-published-alerts-icon',
  templateUrl: './published-alerts-icon.component.html',
  styleUrls: ['./published-alerts-icon.component.scss'],
})
export class PublishedAlertsIconComponent implements OnInit {
  systemAlert$!: Observable<ApiResponse<SystemAlert[]>>;
  filters = new HttpParams({ fromObject: { skip: 0 } });

  constructor(private systemAlertService: SystemAlertService) {}

  ngOnInit(): void {
    this.getPublishedAlertCount();
  }

  private getPublishedAlertCount(): void {
    this.systemAlert$ = this.systemAlertService.publishedSystemAlertCount$.pipe(
      switchMap(() =>
        this.systemAlertService.publishedSystemAlerts(this.filters)
      )
    );
  }
}
