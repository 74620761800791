import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Chart, ChartType } from 'chart.js/auto';
import { PieChart } from 'core/enums';
import {
  PieChartMetrics,
  PieChartMetricsFilter,
  ChartsService,
} from 'core/services/charts.service';
import { Resource } from 'core/services/resources.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  delay,
  switchMap,
  tap,
} from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() partnerId?: number;
  @Input() set pieChartType(value: PieChart) {
    this.pieChart = value;
    this.setChartValues();
    this.loadChartData();
  }

  get pieChartId(): PieChart {
    return this.pieChart;
  }

  get shouldHideFilters(): boolean {
    return (
      this.pieChartId == this.partnerIndustryChartId ||
      this.pieChartId == this.remoteTeleworkPartnerChartId ||
      this.pieChartId == this.partnershipTypeChartId
    );
  }

  chart!: Chart;
  chartName!: string;
  dateFormat = 'MM-dd-yyyy';
  form!: UntypedFormGroup;
  isLoading = false;
  partnerIndustryChartId = PieChart.PartnerIndustry;
  partnershipTypeChartId = PieChart.PartnershipType;
  pieChartMetrics!: PieChartMetrics[];
  pieChartMetrics$!: Observable<ApiResponse<PieChartMetrics[]>>;
  pieChartMetricsAction$!: Observable<PieChartMetricsFilter>;
  remoteTeleworkPartnerChartId = PieChart.RemoteTeleworkPartner;
  selectedEndDate!: Date;
  selectedStartDate!: Date;

  defaultFilter = {
    field: '',
    partnerId: undefined,
  } as PieChartMetricsFilter;

  private pieChart!: PieChart;
  private pieChartMetricsSubject = new BehaviorSubject<PieChartMetricsFilter>(
    this.defaultFilter
  );
  private subscription = new Subscription();

  get currentFilter(): PieChartMetricsFilter {
    return {
      ...this.form.value,
      field: this.chartName,
    };
  }

  constructor(
    private chartsService: ChartsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.defaultFilter.organizationId = this.partnerId;
    this.setChartValues();
    this.buildForm();
    this.loadChartData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  onOrganizationSelected(organization: Resource): void {
    this.form.patchValue({
      partnerId: organization.id,
      organizationName: organization.description,
    });
    this.pieChartMetricsSubject.next(this.currentFilter);
  }

  onReset(): void {
    this.form.reset();
    this.pieChartMetricsSubject.next(this.currentFilter);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      partnerId: this.defaultFilter.organizationId,
      organizationName: null,
    });
  }

  private createChart(): void {
    this.chart = new Chart(
      `${this.chartName}${this.partnerId ? this.partnerId : ''}`,
      {
        type: 'pie' as ChartType,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                '#1D428A',
                '#97207a',
                '#c8102e',
                '#ed6e1e',
                '#ffb81c',
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          layout: {
            padding: {
              left: 50,
              right: 50,
              top: 50,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
          },
        },
        plugins: [
          {
            id: 'NoData',
            afterDraw: function (chart) {
              if (chart.data.datasets[0].data.length === 0) {
                const ctx = chart.ctx;
                chart.clear();
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = `1.5rem ${
                  window.getComputedStyle(document.body).fontFamily
                }`;
                ctx.fillText(
                  'No job data to display',
                  chart.width / 2,
                  chart.height / 2
                );
                ctx.restore();
              }
            },
          },
        ],
      }
    );
  }

  private loadChartData(): void {
    this.pieChartMetricsAction$ = this.pieChartMetricsSubject.asObservable();

    this.pieChartMetrics$ = this.pieChartMetricsAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap((filters) => {
        filters.field = this.chartName;
        if (filters.field === 'isRemoteOrTeleworkJob') {
          return this.chartsService.getRemoteTeleworkJobMetrics(
            filters,
            this.partnerId
          );
        } else if (filters.field === 'isRemoteOrTeleworkPartner') {
          return this.chartsService.getRemoteTeleworkPartnerMetrics();
        } else if (filters.field === 'partnerIndustry') {
          return this.chartsService.getPartnerIndustryMetrics();
        } else if (filters.field === 'partnershipType') {
          return this.chartsService.getPartnershipTypeMetrics();
        }

        return this.chartsService.getJobMetrics(filters, this.partnerId);
      }),
      tap((results) => {
        this.pieChartMetrics = results.data;
        this.updateChart();
        this.isLoading = false;
      })
    );
  }

  private setChartValues(): void {
    switch (this.pieChart) {
      case PieChart.Industry:
        this.chartName = 'industry';
        break;
      case PieChart.YearsOfExperience:
        this.chartName = 'yearsOfExperience.keyword';
        break;
      case PieChart.EducationLevel:
        this.chartName = 'education.keyword';
        break;
      case PieChart.RemoteTeleworkJob:
        this.chartName = 'isRemoteOrTeleworkJob';
        break;
      case PieChart.PartnerIndustry:
        this.chartName = 'partnerIndustry';
        break;
      case PieChart.RemoteTeleworkPartner:
        this.chartName = 'isRemoteOrTeleworkPartner';
        break;
      case PieChart.PartnershipType:
        this.chartName = 'partnershipType';
        break;
      default:
        break;
    }
  }

  private updateChart(): void {
    this.chart.data.labels = [];
    this.chart.data.labels = this.pieChartMetrics.map((x) => x.description);
    this.chart.data.datasets[0].data = [];
    this.chart.data.datasets[0].data = this.pieChartMetrics.map((x) => x.count);
    this.chart.update();
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
    this.subscription.unsubscribe();
  }
}
