import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SHARED_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { MaterialModule } from './material.module';
import { SHARED_PIPES } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PortalModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MaterialModule,
    OverlayModule,
  ],
  providers: [SHARED_PIPES],
  declarations: [SHARED_COMPONENTS, SHARED_PIPES, SHARED_DIRECTIVES],
  exports: [SHARED_COMPONENTS, SHARED_PIPES, SHARED_DIRECTIVES],
})
export class SharedModule {}
