<msep-dock
  title="Jobs"
  [dockId]="jobs"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container *ngIf="publishedJobCount$ | async as jobCount; else loading">
    <div class="flex flex-row justify-between align-center multiple-dock-items">
      <div *ngIf="userIsSysOpWithPermission; else readOnlyPublished">
        <a role="link" [routerLink]="['/admin/jobs']"> Total Published Jobs </a>
      </div>
      <ng-template #readOnlyPublished>
        <strong> Total Published Jobs </strong>
      </ng-template>
      <div class="count default">{{ jobCount.total | number }}</div>
    </div>
  </ng-container>
  <div *ngIf="userIsSysOpWithPermission && !isAdminView">
    <mat-divider></mat-divider>
    <ng-container *ngIf="jobFeedInfo$ | async as jobFeedInfo; else loading">
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/admin/job-feeds']"
          [queryParams]="{ isEnabled: 'true' }"
        >
          Enabled Feeds
        </a>
        <div class="count default">
          {{ jobFeedInfo.totalEnabledFeeds | number }}
        </div>
      </div>
      <mat-divider></mat-divider>
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/admin/job-feeds']"
          [queryParams]="{ isEnabled: 'false' }"
        >
          Disabled Feeds
        </a>
        <div class="count default">
          {{ jobFeedInfo.totalDisabledFeeds | number }}
        </div>
      </div>
      <mat-divider></mat-divider>
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/admin/job-feeds']"
          [queryParams]="{ lastJobFeedImportStatus: 'Succeeded' }"
        >
          Succeeded Feeds
        </a>
        <div class="count default">
          {{ jobFeedInfo.totalSucceeded | number }}
        </div>
      </div>
      <mat-divider></mat-divider>
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/admin/job-feeds']"
          [queryParams]="{ lastJobFeedImportStatus: 'Failed' }"
        >
          Failed Feeds
        </a>
        <div class="count default">{{ jobFeedInfo.totalFailed | number }}</div>
      </div>
      <mat-divider></mat-divider>
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/admin/job-feeds']"
          [queryParams]="{ lastJobFeedImportStatus: 'Running' }"
        >
          Running Feeds
        </a>
        <div class="count default">{{ jobFeedInfo.totalRunning | number }}</div>
      </div>
    </ng-container>
  </div>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
