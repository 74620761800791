import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  convertStartAndEndDateFilters(filter: {
    startDate?: unknown;
    endDate?: unknown;
  }): void {
    const { startDate, endDate } = filter;
    if (startDate && this.isDateCompatible(startDate)) {
      filter.startDate = new Date(startDate).toLocaleDateString();
    }
    if (endDate && this.isDateCompatible(endDate)) {
      filter.endDate = new Date(endDate).toLocaleDateString();
    }
  }

  getCurrentFiscalYear(): number {
    const currentDate = new Date();
    return currentDate.getMonth() >= 9
      ? currentDate.getUTCFullYear() + 1
      : currentDate.getUTCFullYear();
  }

  getCurrentYear(): number {
    return new Date().getUTCFullYear();
  }

  getDateWithNoTime(date: Date): string {
    // returns date as YYYY-MM-DDT00:00:00Z
    return moment(date).utcOffset(0, true).format();
  }

  getDateStringWithoutUtcDesignator(
    date: string | undefined
  ): string | undefined {
    if (date === undefined || date === null) {
      return undefined;
    }
    /* 
      Our server dates are zero UTC offset (marked with Z). If the offset is present
      in the date string, Angular will convert the time into the local timezone.
      This method removes the designator to force the date to be displayed as
      the server time.
    */
    const zoneDesignatorZeroUtcOffset = 'Z';
    return date.replace(zoneDesignatorZeroUtcOffset, '');
  }

  getDayOfMonth(): number {
    const currentDate = new Date();
    return currentDate.getUTCDate();
  }

  getFyDetails(
    fiscalYear: number = this.getCurrentFiscalYear()
  ): CurrentFyDetails {
    // months are 0 indexed in javascript
    const startDate = new Date();
    startDate.setMonth(9);
    startDate.setDate(1);
    startDate.setUTCFullYear(fiscalYear - 1);

    const endDate = new Date();
    endDate.setMonth(8);
    endDate.setDate(30);
    endDate.setUTCFullYear(fiscalYear);

    return {
      fiscalYear,
      startDate,
      endDate,
    };
  }

  getYtdDetails(
    currentYear: number = this.getCurrentYear()
  ): CurrentYtdDetails {
    // months are 0 indexed in javascript
    const startDate = new Date();
    startDate.setMonth(0);
    startDate.setDate(1);
    startDate.setUTCFullYear(currentYear);

    const endDate = new Date();
    endDate.setUTCFullYear(currentYear);

    // Earliest Reported date
    const minDate = new Date(2013, 0);
    // Max reported search date is end of the Current Year
    const maxDate = new Date(currentYear, 11, 31);

    return {
      currentYear,
      startDate,
      endDate,
      minDate,
      maxDate,
    };
  }

  getPreviousMonthName(): string {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getUTCMonth() - 1);
    return currentDate.toLocaleString('default', { month: 'long' });
  }

  getPreviousMonthYear(): string {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getUTCMonth() - 1);
    return currentDate.toLocaleString('default', { year: 'numeric' });
  }

  private isDateCompatible(input: unknown): input is DateCompatible {
    return (
      typeof input === 'string' ||
      typeof input === 'number' ||
      input instanceof Date
    );
  }
}

type DateCompatible = string | number | Date;

export interface CurrentFyDetails {
  fiscalYear: number;
  startDate: Date;
  endDate: Date;
}

export interface CurrentYtdDetails {
  currentYear: number;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
}
