import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isValueProvided',
})
export class IsValueProvidedPipe implements PipeTransform {
  transform(value?: undefined): unknown {
    if (value) {
      return value;
    }

    return '0';
  }
}
