import { Pipe, PipeTransform } from '@angular/core';
import { WorkExperience } from 'core/services/partner/seco.service';

@Pipe({
  name: 'candidateJobInfo',
})
export class CandidateJobInfoPipe implements PipeTransform {
  transform(experience: WorkExperience): string {
    const hasJobTitle = (experience.jobTitle?.length ?? 0) > 0;
    const hasCompanyName = (experience.companyName?.length ?? 0) > 0;

    if (hasJobTitle && hasCompanyName) {
      return experience.jobTitle + ' at ' + experience.companyName;
    } else if (hasJobTitle) {
      return experience.jobTitle ?? '';
    } else if (hasCompanyName) {
      return experience.companyName ?? '';
    } else {
      return '';
    }
  }
}
