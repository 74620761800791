import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GhostLoaderType } from 'core/enums';
import {
  SystemAlert,
  SystemAlertService,
} from 'core/services/cms/system-alert.service';
import { delay, switchMap, tap, Observable, BehaviorSubject } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-alerts-and-information',
  templateUrl: './alerts-and-information.component.html',
  styleUrls: ['./alerts-and-information.component.scss'],
})
export class AlertsAndInformationComponent implements OnInit {
  alertCount = 0;
  displayedColumns = ['title', 'body'];
  filters = new HttpParams({ fromObject: { skip: 0, take: 10 } });
  ghostLoaderType = GhostLoaderType.Table;
  isLoading = false;
  pageSize = 10;
  systemAlerts!: SystemAlert[];
  systemAlert$!: Observable<ApiResponse<SystemAlert[]>>;
  systemAlertActions$!: Observable<boolean>;

  private systemAlertSubject = new BehaviorSubject(true);

  constructor(private systemAlertService: SystemAlertService) {}

  ngOnInit(): void {
    this.getPublishedAlertCount();
    // force top-menu icon number to be updated when viewing the page
    this.systemAlertService.publishedSystemAlertCount$.next(true);
  }

  onPagingChange(event: { skip: number; take: number }): void {
    this.filters = this.filters.set('skip', event.skip).set('take', event.take);
    this.systemAlertSubject.next(true);
  }

  private getPublishedAlertCount(): void {
    this.systemAlertActions$ = this.systemAlertSubject.asObservable();
    this.systemAlert$ = this.systemAlertActions$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap(() =>
        this.systemAlertService.publishedSystemAlerts(this.filters)
      ),
      tap((results) => {
        this.alertCount = results.total;
        this.systemAlerts = results.data;
        this.isLoading = false;
      })
    );
  }
}
