import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  failureNotification$ = new Subject<string>();
  failureFullDurationNotification$ = new Subject<string>();
  successNotification$ = new Subject<string>();
  successFullDurationNotification$ = new Subject<string>();
  serverValidationNotification$ = new Subject<string[] | null>();
  private defaultDisplayTime = 3000;
  private defaultSuccessClass = 'snackbar-success';
  private defaultFailureClass = 'snackbar-failure';
  constructor(private materialSnackbar: MatSnackBar) {}

  emitServerValidationError(errors: string[]): void {
    this.serverValidationNotification$.next(errors);
  }

  emitFailure(message: string): void {
    this.failureNotification$.next(message);
  }

  emitFailureFullDuration(message: string): void {
    this.failureFullDurationNotification$.next(message);
    this.serverValidationNotification$.next(null);
  }

  // couldn't figure out how to test Subject directly, so
  // if someone figures out how to do it properly, this method
  // won't be necessary
  emitSuccess(message: string): void {
    this.successNotification$.next(message);
    this.serverValidationNotification$.next(null);
  }

  emitSuccessFullDuration(message: string): void {
    this.successFullDurationNotification$.next(message);
    this.serverValidationNotification$.next(null);
  }

  showSuccess(message: string): void {
    this.materialSnackbar.open(message, undefined, {
      duration: this.defaultDisplayTime,
      panelClass: this.defaultSuccessClass,
    });
  }

  showSuccessFullDuration(message: string): void {
    this.materialSnackbar.open(message, 'Dismiss', {
      duration: 0,
      panelClass: this.defaultSuccessClass,
    });
  }

  showFailure(message: string): void {
    this.materialSnackbar.open(message, undefined, {
      duration: this.defaultDisplayTime,
      panelClass: this.defaultFailureClass,
    });
  }

  showFailureFullDuration(message: string): void {
    this.materialSnackbar.open(message, 'Dismiss', {
      duration: 0,
      panelClass: this.defaultFailureClass,
    });
  }
}
