import { Component, Input, OnInit } from '@angular/core';
import { GhostLoaderType } from 'core/enums';

@Component({
  selector: 'msep-ghost-loader-list',
  templateUrl: './ghost-loader-list.component.html',
  styleUrls: ['./ghost-loader-list.component.scss'],
})
export class GhostLoaderListComponent implements OnInit {
  @Input() childFlex?: number;
  @Input() childFlexGtSm?: number;
  @Input() childFlexGtLg?: number;
  @Input() ghostLength? = 3;
  @Input() tableColumnLength?: number;
  @Input() tableRowLength?: number;
  @Input() type? = GhostLoaderType.Card;
  ghosts!: Array<unknown>;
  ghostTypePage = GhostLoaderType.Page;
  ghostTypeTable = GhostLoaderType.Table;
  tableColumnGhosts!: Array<unknown>;
  tableRowGhosts!: Array<unknown>;

  get flexClasses(): string {
    let classes = '';

    if (this.childFlex) {
      classes += `flex-${this.childFlex} `;
    }
    if (this.childFlexGtSm) {
      classes += `flex-gt-sm-${this.childFlexGtSm} `;
    }
    if (this.childFlexGtLg) {
      classes += `flex-gt-lg-${this.childFlexGtLg} `;
    }

    return classes;
  }

  ngOnInit(): void {
    this.ghosts = new Array<unknown>(this.ghostLength);

    if (this.tableColumnLength) {
      this.tableColumnGhosts = new Array<unknown>(this.tableColumnLength);
    }

    if (this.tableRowLength) {
      this.tableRowGhosts = new Array<unknown>(this.tableRowLength);
    }
  }
}
