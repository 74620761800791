import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'msep-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  // Setting a value to input properties sets their default values
  @Input() length = 0;
  @Input() pageSize? = 10;
  @Input() pageSizeOptions = [10, 25, 50, 100];

  @Output() pagingChange = new EventEmitter<{
    skip: number;
    take: number;
  }>();
  @ViewChild('msepPaginator', { static: false }) paginator:
    | MatPaginator
    | undefined;

  emitPaginationChange(event: PageEvent): void {
    const { pageIndex, pageSize } = event;
    this.pageSize = pageSize;
    const skip = pageSize * pageIndex;
    const take = pageSize;

    this.pagingChange.next({ skip, take });
  }

  firstPage(): void {
    if (this.paginator !== undefined) {
      this.paginator.firstPage();
    }
  }
}
