import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, throwError } from 'rxjs';
import {
  ApiResponse,
  ExternalApiResponse,
  HeartbeatResponse,
} from 'shared/models/api-response';
import { DashboardStatusCount } from 'shared/models/dashboard';
import { ConfigService } from '../infrastructure/config.service';

@Injectable({ providedIn: 'root' })
export class AdminDashboardService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;
  private readonly secoApiUrl = this.configService.config.secoApiBaseUrl;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  getErrorTrends(): Observable<DashboardTrend> {
    const url = `${this.msepPartnerApiUrl}/admin-dashboard/error-trends`;
    return this.http
      .get<DashboardTrend>(url)
      .pipe(catchError(this.handleError));
  }

  getEventLogTrends(): Observable<DashboardTrend> {
    const url = `${this.msepPartnerApiUrl}/admin-dashboard/event-log-trends`;
    return this.http
      .get<DashboardTrend>(url)
      .pipe(catchError(this.handleError));
  }

  getHelpDeskCounts(): Observable<HelpDeskCounts> {
    return forkJoin({
      pendingUserCount: this.getPendingUserCount(),
      ticketCounts: this.getTicketCounts(),
    });
  }

  getJobFeedInfo(): Observable<JobFeedInfo> {
    const url = `${this.msepPartnerApiUrl}/admin-dashboard/job-feed-info`;
    return this.http.get<ApiResponse<JobFeedInfo>>(url).pipe(
      map((response) => response.data),
      catchError(this.handleError)
    );
  }

  isSapAvailable(): Observable<HeartbeatResponse> {
    const url = `${this.msepPartnerApiUrl}/health-check/sap`;
    return this.http.get<ExternalApiResponse>(url).pipe(
      map((response) => ({
        isRunning: response.statusCode === HttpStatusCode.Ok,
      })),
      catchError(() => of({ isRunning: false } as HeartbeatResponse))
    );
  }

  isSecoAvailable(): Observable<HeartbeatResponse> {
    const url = `${this.secoApiUrl}/healthcheck`;
    return this.http.get(url).pipe(
      map((response) => ({
        isRunning: response !== null,
      })),
      catchError(() => of({ isRunning: false } as HeartbeatResponse))
    );
  }

  private getPendingUserCount(): Observable<number> {
    const url = `${this.msepPartnerApiUrl}/admin-dashboard/pending-user-count`;
    return this.http.get<ApiResponse<number>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  private getTicketCounts(): Observable<DashboardStatusCount[]> {
    const url = `${this.msepPartnerApiUrl}/admin-dashboard/ticket-counts`;
    return this.http.get<ApiResponse<DashboardStatusCount[]>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface DashboardTrend {
  changeString: string;
  countToday: number;
  countYesterday: number;
  percentageChange: number;
}

export interface HelpDeskCounts {
  pendingUserCount: number;
  ticketCounts: DashboardStatusCount[];
}

export interface JobFeedInfo {
  totalEnabledFeeds: number;
  totalDisabledFeeds: number;
  totalRunning: number;
  totalSucceeded: number;
  totalFailed: number;
}
