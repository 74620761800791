import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Chart } from 'chart.js/auto';
import { LineChart } from 'core/enums';
import { LineChartMetricsFilter } from 'core/services/charts.service';
import {
  SecoApiSingleResponse,
  SecoLineChartMetrics,
  SecoService,
} from 'core/services/partner/seco.service';
import { Resource } from 'core/services/resources.service';
import moment from 'moment';
import { BehaviorSubject, Observable, delay, switchMap, tap } from 'rxjs';

@Component({
  selector: 'msep-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss'],
})
export class MultiLineChartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() partnerId?: number;

  chart!: Chart;
  chartId!: string;
  chartTitle!: string;
  dateFormat = 'MM-dd-yyyy';
  form!: UntypedFormGroup;
  isLoading = false;
  secoLineChartMetrics!: SecoLineChartMetrics;
  secoLineChartMetrics$!: Observable<
    SecoApiSingleResponse<SecoLineChartMetrics>
  >;
  secoLineChartMetricsAction$!: Observable<LineChartMetricsFilter>;
  selectedEndDate!: Date;
  selectedStartDate!: Date;
  spouseEngagementChartId = LineChart.SpouseEngagement;

  defaultFilter = {
    organizationId: undefined,
    startDate: '',
    endDate: '',
  } as LineChartMetricsFilter;

  private secoChartMetricsSubject = new BehaviorSubject<LineChartMetricsFilter>(
    this.defaultFilter
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private secoService: SecoService
  ) {}

  ngOnInit(): void {
    this.defaultFilter.organizationId = this.partnerId;
    this.setChartValues();
    this.setDateFilterDefaults();
    this.buildForm();
    this.loadSecoChartData();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  get currentFilter(): LineChartMetricsFilter {
    return {
      ...this.form.value,
    };
  }

  onEndDateSelected(date: Date): void {
    const endDate = moment(date).endOf('month').toDate();
    const selectedDate = new DatePipe('en-US').transform(
      endDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      endDate: selectedDate,
    });
    this.secoChartMetricsSubject.next(this.currentFilter);
  }

  onOrganizationSelected(organization: Resource): void {
    this.form.patchValue({
      organizationId: organization.id,
      organizationName: organization.description,
    });

    this.secoChartMetricsSubject.next(this.currentFilter);
  }

  onStartDateSelected(date: Date): void {
    const startDate = moment(date).toDate();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(1);
    const selectedDate = new DatePipe('en-US').transform(
      startDate,
      this.dateFormat
    );

    if (!selectedDate) return;

    this.form.patchValue({
      startDate: selectedDate,
    });
    this.secoChartMetricsSubject.next(this.currentFilter);
  }

  onReset(): void {
    this.form.patchValue({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
    this.setDateFilterDefaults();
    this.secoChartMetricsSubject.next(this.currentFilter);
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      organizationId: this.defaultFilter.organizationId,
      organizationName: null,
      startDate: this.defaultFilter.startDate,
      endDate: this.defaultFilter.endDate,
    });
  }

  private createChart(): void {
    this.chart = new Chart(this.chartId, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: false,
            borderColor: '#3d9ce0',
            backgroundColor: '#3d9ce0',
          },
          {
            data: [],
            fill: false,
            borderColor: '#8b5bb0',
            backgroundColor: '#8b5bb0',
          },
          {
            data: [],
            fill: false,
            borderColor: '#e03d50',
            backgroundColor: '#e03d50',
          },
          {
            data: [],
            fill: false,
            borderColor: '#ffb81c',
            backgroundColor: '#ffb81c',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 50,
            right: 50,
            bottom: 20,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  private loadSecoChartData(): void {
    this.secoLineChartMetricsAction$ =
      this.secoChartMetricsSubject.asObservable();

    this.secoLineChartMetrics$ = this.secoLineChartMetricsAction$.pipe(
      delay(0),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((filters) => {
        return this.secoService.getSpouseEngagementChartData(filters);
      }),
      tap((results) => {
        this.secoLineChartMetrics = results.data;
        this.updateChart();
        this.isLoading = false;
      })
    );
  }

  private setChartValues(): void {
    this.chartId = 'spouseEngagementChart';
    this.chartTitle = 'Spouse Engagement';
  }

  private setDateFilterDefaults(): void {
    const today = new Date();
    this.selectedEndDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );
    today.setDate(today.getDate() - 365);
    this.selectedStartDate = new Date(today.getFullYear(), today.getMonth(), 1);

    this.defaultFilter.startDate =
      new DatePipe('en-US').transform(
        this.selectedStartDate,
        this.dateFormat
      ) ?? '';
    this.defaultFilter.endDate =
      new DatePipe('en-US').transform(this.selectedEndDate, this.dateFormat) ??
      '';
  }

  private updateChart(): void {
    this.chart.data.labels = [];
    this.chart.data.labels = this.secoLineChartMetrics.clickApply.map((x) =>
      new DatePipe('en-US').transform(x.date, 'yyyy-MM')
    );

    this.chart.data.datasets[0].data = this.secoLineChartMetrics.clickApply.map(
      (x) => x.count
    );
    this.chart.data.datasets[0].label = 'Click Apply';

    this.chart.data.datasets[1].data =
      this.secoLineChartMetrics.saveProfile.map((x) => x.count);
    this.chart.data.datasets[1].label = 'Save Profile';

    this.chart.data.datasets[2].data =
      this.secoLineChartMetrics.searchProfile.map((x) => x.count);
    this.chart.data.datasets[2].label = 'Search Profile';

    this.chart.data.datasets[3].data =
      this.secoLineChartMetrics.viewProfile.map((x) => x.count);
    this.chart.data.datasets[3].label = 'View Profile';

    this.chart.update();
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }
}
