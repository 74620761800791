import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks } from 'core/enums';
import {
  DashboardService,
  PartnerEngagement,
  PartnerTracker,
} from 'core/services/dashboard.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-partner-engagement-dock',
  templateUrl: './partner-engagement-dock.component.html',
})
export class PartnerEngagementDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  partnerEngagement = Docks.PartnerEngagement;
  partnerEngagement$!: Observable<PartnerEngagement>;
  partnerTracker$!: Observable<PartnerTracker>;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.partnerEngagement$ = this.dashboardService.getPartnerEngagement();
    this.partnerTracker$ = this.dashboardService.getPartnerTracker();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }
}
