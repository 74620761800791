import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as bodybuilder from 'bodybuilder';
import { UtilsService } from 'core/utilities/utils.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { ApiResponse, HeartbeatResponse } from 'shared/models/api-response';
import { SearchFilter } from 'shared/models/search-filter';
import { ConfigService } from './infrastructure/config.service';
import { Coordinates } from 'shared/models/google-location';

@Injectable({ providedIn: 'root' })
export class JobService {
  private builder!: bodybuilder.Bodybuilder;
  private filters!: JobSearchFilter;
  private msepPartnerApiUrl = this.configService.config.msepPartnerApiBaseUrl;
  private savedFilters: JobSearchFilter | null = null;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private utilitiesService: UtilsService
  ) {}

  clearSavedFilters(): void {
    this.savedFilters = null;
  }

  createJob(createJob: Job): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${createJob.organizationId}/jobs/create`;

    return this.http
      .post<void>(url, createJob)
      .pipe(catchError(this.handleError));
  }

  deleteJob(deleteJob: DeleteJob): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${deleteJob.organizationId}/jobs/${deleteJob.documentId}/delete`;

    return this.http
      .post<void>(url, deleteJob)
      .pipe(catchError(this.handleError));
  }

  extendPublishDate(extendPublishJob: ExtendPublishJob): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${extendPublishJob.organizationId}/jobs/${extendPublishJob.documentId}/extend-publish-date`;

    return this.http
      .post<void>(url, extendPublishJob)
      .pipe(catchError(this.handleError));
  }

  getSavedFilters(): JobSearchFilter | null {
    return this.savedFilters;
  }

  isJobsIndexAvailable(): Observable<HeartbeatResponse> {
    const url = `${this.msepPartnerApiUrl}/jobs/search`;
    this.builder = bodybuilder().size(1).rawOption('_source', 'title');
    const query = this.builder.build();
    return this.http.post<ElasticsearchSearchResponse<Job>>(url, query).pipe(
      map((response) => ({
        isRunning: response.hits.hits.length === 1,
      })),
      catchError(() => of({ isRunning: false } as HeartbeatResponse))
    );
  }

  saveFilters(filters: JobSearchFilter): void {
    this.savedFilters = filters;
  }

  searchJobs(filters: JobSearchFilter): Observable<ApiResponse<Job[]>> {
    const url = `${this.msepPartnerApiUrl}/jobs/search`;
    this.filters = filters;
    const query = this.buildOpenSearchQuery();
    return this.http.post<ElasticsearchSearchResponse<Job>>(url, query).pipe(
      map((response) => ({
        total: response.hits.total.value,
        data: response.hits.hits.map((job) => job._source),
      })),
      catchError(this.handleError)
    );
  }

  toggleHotJob(toggleJob: ToggleHotJob): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${toggleJob.organizationId}/jobs/${toggleJob.documentId}/toggle-hot-job`;

    return this.http
      .post<void>(url, toggleJob)
      .pipe(catchError(this.handleError));
  }

  toggleJobPublish(extendPublishJob: ToggleJobPublish): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${extendPublishJob.organizationId}/jobs/${extendPublishJob.documentId}/toggle-publish`;

    return this.http
      .post<void>(url, extendPublishJob)
      .pipe(catchError(this.handleError));
  }

  toggleRemoteJob(toggleJob: ToggleRemoteJob): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${toggleJob.organizationId}/jobs/${toggleJob.documentId}/toggle-remote-job`;

    return this.http
      .post<void>(url, toggleJob)
      .pipe(catchError(this.handleError));
  }

  toggleTeleworkJob(toggleJob: ToggleTeleworkJob): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${toggleJob.organizationId}/jobs/${toggleJob.documentId}/toggle-telework-job`;

    return this.http
      .post<void>(url, toggleJob)
      .pipe(catchError(this.handleError));
  }

  updateJob(updateJob: Job): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${updateJob.organizationId}/jobs/update`;

    return this.http
      .post<void>(url, updateJob)
      .pipe(catchError(this.handleError));
  }

  private buildBaseQuery(): void {
    this.builder = bodybuilder()
      .from(this.filters.skip)
      .size(this.filters.take)
      .sort(
        <string>this.filters.sortByProperty,
        <string>this.filters.sortDirection
      )
      // openSearch limits take to 10,000. This allows total size to come back for use in pager
      .rawOption('track_total_hits', true);
  }

  private buildCoordinatesQuery(coordinates: Coordinates): void {
    const { latitude: lat, longitude: lon } = coordinates;
    this.builder.filter('geo_distance', {
      distance: '80km',
      location: { lat, lon },
    });
  }

  private buildOpenSearchQuery(): object {
    this.buildBaseQuery();

    this.buildPublishedDateQuery(this.filters.isPublished);
    if (this.filters.coordinates) {
      this.buildCoordinatesQuery(this.filters.coordinates);
    }
    if (this.filters.createdEndDate && this.filters.createdStartDate) {
      const startDate = this.utilitiesService.getDateAtMidnight(
        this.filters.createdStartDate
      );
      const endDate = this.utilitiesService.getDateAtLatestTimestamp(
        this.filters.createdEndDate
      );

      this.builder
        .query('range', 'createdDate', {
          gte: startDate,
        })
        .query('range', 'createdDate', {
          lte: endDate,
        });
    }

    if (this.filters.title) {
      this.builder.query('match_phrase', 'title', this.filters.title);
    }
    if (this.filters.keyword) {
      this.builder.query('multi_match', 'fields', ['title', 'description'], {
        query: this.filters.keyword,
      });
    }
    if (this.filters.organizationId) {
      this.builder.query(
        'match',
        'organizationId',
        this.filters.organizationId
      );
    }

    if (this.filters.jid) {
      this.builder.query('match_phrase', 'jid', this.filters.jid);
    }

    this.buildHotJobQuery(this.filters.isHotJob);

    if (this.filters.jobFeedId) {
      this.builder.query('match', 'jobFeedId', this.filters.jobFeedId);
    }
    if (this.filters.jobHash) {
      this.builder.query('match', 'jobHash.keyword', this.filters.jobHash);
    }
    if (this.filters.state) {
      this.builder.query('match', 'state', this.filters.state);
    }

    return this.builder.build();
  }

  private buildHotJobQuery(isHotJob: boolean | null): void {
    if (isHotJob == null || isHotJob == undefined) {
      return;
    }
    if (!isHotJob) {
      this.builder.query('bool', (a) =>
        a
          .orQuery('match', 'isHotJob', false)
          .orQuery('bool', (b) => b.notQuery('exists', 'field', 'isHotJob'))
      );
    } else {
      this.builder.query('match', 'isHotJob', true);
    }
  }

  private buildPublishedDateQuery(isPublished: boolean | null): void {
    if (isPublished == null) {
      return;
    }
    if (!isPublished) {
      this.builder.query('bool', (b) =>
        b
          .orQuery('range', 'publishEndDate', {
            lt: 'now',
          })

          .orQuery('bool', (c) =>
            c.notQuery('exists', 'field', 'publishEndDate')
          )
      );
      return;
    }
    this.builder
      .query('range', 'publishStartDate', {
        lte: 'now',
      })
      .query('range', 'publishEndDate', {
        gte: 'now',
      });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface ExtendPublishJob {
  documentId?: string;
  isHotJob: boolean;
  organizationId: number;
}
export interface DeleteJob {
  documentId?: string;
  organizationId: number;
}

export interface ToggleHotJob {
  documentId?: string;
  organizationId: number;
  isHotJob: boolean;
}

export interface ToggleJobPublish {
  documentId?: string;
  isHotJob: boolean;
  publish: boolean;
  organizationId: number;
}

export interface ToggleRemoteJob {
  documentId?: string;
  organizationId: number;
  isRemoteJob: boolean;
}

export interface ToggleTeleworkJob {
  documentId?: string;
  organizationId: number;
  isTelework: boolean;
}

export interface Job {
  address1?: string;
  career: string;
  careerLevelId?: number;
  careerLevel?: string;
  city?: string;
  compensationType?: string;
  createdByUserId?: number;
  createdDate: Date;
  description: string;
  education?: string;
  googlePlaceId?: string;
  hourlyRate?: string;
  industry?: string;
  isHotJob: boolean;
  isImported: boolean;
  isPublished: boolean;
  isRemoteJob: boolean;
  isTelework: false;
  jobBenefits?: string;
  jobFeedId?: number;
  jobHash?: string;
  jid?: string;
  jobOpenings?: number;
  jobType?: string;
  lastUpdatedByUserId?: number;
  lastUpdatedDate: Date;
  latitude?: number;
  location?: string;
  longitude?: number;
  minQualifications?: string;
  organizationId: number;
  organizationLogo?: string;
  organizationName: string;
  partnershipType?: string[];
  postal?: string;
  prefQualifications?: string;
  publishEndDate?: string;
  publishStartDate?: string;
  minSalary?: number;
  maxSalary?: number;
  state?: string;
  tags?: string;
  title: string;
  url: string;
  usRegion?: string;
  yearsOfExperience?: string;
}

export interface JobSearchFilter extends SearchFilter {
  location: string | null;
  coordinates: Coordinates | null;
  googlePlaceId?: string;
  organizationId: number | null;
  organizationName: string | null;
  title: string | null;
  jid: string | null;
  jobFeedId: number | null;
  isHotJob: boolean | null;
  isPublished: boolean | null;
  createdDate: Date | null;
  publishEndDate: Date | null;
  jobHash: string | null;
  createdEndDate: Date | null;
  createdStartDate: Date | null;
  keyword: string | null;
  state: string | null;
}

export interface ElasticsearchSearchResponse<T> {
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number | null;
    hits: Array<{
      _index: string;
      _type: string;
      _id: string;
      _score: number | null;
      _source: T;
      sort: string[];
    }>;
  };
  timed_out: boolean;
  took: number;
  _shards: {
    total: number;
    successful: number;
    failed: number;
    skipped: number;
  };
}
