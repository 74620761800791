import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './infrastructure/config.service';
import { Observable, catchError, throwError } from 'rxjs';
import { UtilsService } from 'core/utilities/utils.service';
import { ApiResponse } from 'shared/models/api-response';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private utilService: UtilsService
  ) {}

  getCandidateSearchMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ApiResponse<LineChartMetrics[]>> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/candidate-search`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/candidate-search`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<LineChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportCandidateSearchMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ServerBlobResult> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/candidate-search/export`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/candidate-search/export`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;

    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getJobMetrics(
    filters: PieChartMetricsFilter,
    partnerId?: number
  ): Observable<ApiResponse<PieChartMetrics[]>> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/job-metrics`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/job-metrics`;
    }
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<PieChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportJobMetrics(
    filters: PieChartMetricsFilter,
    partnerId?: number
  ): Observable<ServerBlobResult> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/job-metrics/export`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/job-metrics/export`;
    }
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getPartnerIndustryMetrics(): Observable<ApiResponse<PieChartMetrics[]>> {
    const url = `${this.msepPartnerApiUrl}/charts/partner-industry-metrics`;
    return this.http
      .get<ApiResponse<PieChartMetrics[]>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  exportPartnerIndustryMetrics(): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/charts/partner-industry-metrics/export`;
    return this.http
      .get<ServerBlobResult>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getPartnershipTypeMetrics(): Observable<ApiResponse<PieChartMetrics[]>> {
    const url = `${this.msepPartnerApiUrl}/charts/partnership-types`;
    return this.http
      .get<ApiResponse<PieChartMetrics[]>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  exportPartnershipTypeMetrics(): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/charts/partnership-types/export`;
    return this.http
      .get<ServerBlobResult>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getPartnersWhoReportedMetrics(
    filters: LineChartMetricsFilter
  ): Observable<ApiResponse<LineChartMetrics[]>> {
    const url = `${this.msepPartnerApiUrl}/charts/completed-reports`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<LineChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportPartnersWhoReportedMetrics(
    filters: LineChartMetricsFilter
  ): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/charts/completed-reports/export`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;

    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getRemoteTeleworkJobMetrics(
    filters: PieChartMetricsFilter,
    partnerId?: number
  ): Observable<ApiResponse<PieChartMetrics[]>> {
    let url: string;

    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/remote-telework-job-metrics`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/remote-telework-job-metrics`;
    }
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<PieChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportRemoteTeleworkJobMetrics(
    filters: PieChartMetricsFilter,
    partnerId?: number
  ): Observable<ServerBlobResult> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/remote-telework-job-metrics/export`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/remote-telework-job-metrics/export`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getRemoteTeleworkPartnerMetrics(): Observable<
    ApiResponse<PieChartMetrics[]>
  > {
    const url = `${this.msepPartnerApiUrl}/charts/remote-telework-partners`;
    return this.http
      .get<ApiResponse<PieChartMetrics[]>>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  exportRemoteTeleworkPartnerMetrics(): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/charts/remote-telework-partners/export`;
    return this.http
      .get<ServerBlobResult>(url)
      .pipe(catchError(error => this.handleError(error)));
  }

  getSpouseCurrentlyEmployedMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ApiResponse<LineChartMetrics[]>> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/spouse-currently-employed`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/spouse-currently-employed`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;

    return this.http
      .get<ApiResponse<LineChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportSpouseCurrentlyEmployedMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ServerBlobResult> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/spouse-currently-employed/export`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/spouse-currently-employed/export`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;

    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getSpouseHiredMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ApiResponse<LineChartMetrics[]>> {
    let url: string;

    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/spouse-hired`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/spouse-hired`;
    }
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<LineChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportSpouseHiredMetrics(
    filters: LineChartMetricsFilter,
    partnerId?: number
  ): Observable<ServerBlobResult> {
    let url: string;
    if (partnerId !== undefined) {
      url = `${this.msepPartnerApiUrl}/partner/charts/${partnerId}/spouse-hired/export`;
    } else {
      url = `${this.msepPartnerApiUrl}/charts/spouse-hired/export`;
    }

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;

    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  getTotalPartnersMetrics(
    filters: LineChartMetricsFilter
  ): Observable<ApiResponse<LineChartMetrics[]>> {
    const url = `${this.msepPartnerApiUrl}/charts/total-partners`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<LineChartMetrics[]>>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  exportTotalPartnersMetrics(
    filters: LineChartMetricsFilter
  ): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/charts/total-partners/export`;

    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ServerBlobResult>(url, { params })
      .pipe(catchError(error => this.handleError(error)));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    return throwError(() => err || 'Server error');
  }
}

export interface LineChartMetrics {
  date: string;
  count: number;
}

export interface LineChartMetricsFilter {
  startDate: string;
  endDate: string;
  organizationId?: number;
  partnershipTypeId?: number;
}

export interface PieChartMetrics {
  description: string;
  count: number;
}

export interface PieChartMetricsFilter {
  field: string;
  organizationId?: number;
}
