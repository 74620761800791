import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import {
  PartnerData,
  PartnerSearch,
  PartnersService,
} from 'core/services/partners.service';
import { Resource, ResourcesService } from 'core/services/resources.service';
import { BehaviorSubject, EMPTY, Observable, Subscription, tap } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { ApiResponse } from 'shared/models/api-response';
import { Countries, GhostLoaderType } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';

@Component({
  selector: 'msep-our-partners',
  templateUrl: './our-partners-list.component.html',
  styleUrls: ['./our-partners-list.component.scss'],
})
export class OurPartnersListComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: false }) paginator:
    | MatPaginator
    | undefined;

  countries$ = this.resourceService.getCountry();
  form!: UntypedFormGroup;
  ghostLoaderType = GhostLoaderType.PartnerLogo;
  industrySectors$ = this.resourceService.getIndustrySectors();
  isLoading = false;
  pageSize? = 10;
  partners: PartnerData[] | undefined;
  partnersAction$!: Observable<PartnerSearch>;
  partners$!: Observable<ApiResponse<PartnerData[]>>;
  partnershipTypes$ = this.resourceService.getPartnershipTypes();
  resultTotal = 0;
  states$ = this.resourceService.getStates();

  private defaultFilter = {
    skip: 0,
    take: this.pageSize,
  } as PartnerSearch;
  private partnersSubject = new BehaviorSubject<PartnerSearch>(
    this.defaultFilter
  );

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private partnersService: PartnersService,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: null,
      industrySectorId: null,
      isTeleworkAvailable: null,
      offersPermanentRemotePositions: null,
      partnershipTypeId: null,
      stateId: null,
      internationalFacilities: false,
      countryId: [{ value: null, disabled: true }],
    });

    this.subscriptions.push(
      this.form
        .get('internationalFacilities')!
        .valueChanges.subscribe((checked) => {
          const countryDropdown = this.form.get('countryId');
          if (checked) {
            countryDropdown?.enable();
          } else {
            countryDropdown?.disable();
          }
        })
    );

    this.countries$ = this.countries$.pipe(
      map((countries) =>
        countries.filter((c) => c.id !== Countries.UnitedStates)
      )
    );

    this.handleTeleworkQueryParameter();
    this.handleRemoteQueryParameter();
    this.setPartnerRequestStream();
  }

  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  hasInternationalFacilities(event: MatCheckboxChange): void {
    const newValue = event.checked ? event.checked : false;
    this.form.patchValue({
      internationalFacilities: newValue,
    });
    if (!newValue) {
      this.form.patchValue({
        countryId: null,
      });
    }
    this.search();
  }

  onCountryChanged(event: MatSelectChange): void {
    this.form.patchValue({
      countryId: event.value,
    });
    this.search();
  }

  onIndustrySectorChanged(event: MatSelectChange): void {
    this.form.patchValue({
      industrySectorId: event.value,
    });
    this.search();
  }

  onPagingChange(event: { skip: number; take: number }): void {
    const filter = this.form.value as PartnerSearch;
    this.pageSize = event.take;
    filter.take = event.take;
    filter.skip = event.skip;
    this.partnersSubject.next(filter);
  }

  onPartnerSelected(partner: Resource): void {
    this.form.patchValue({ name: partner.description });
    this.search();
  }

  onPartnershipTypeChanged(event: MatSelectChange): void {
    this.form.patchValue({
      partnershipTypeId: event.value,
    });
    this.search();
  }

  onReset(): void {
    this.form.reset();
    this.paginator?.firstPage();
    this.pageSize = this.defaultFilter.take;

    this.partnersSubject.next(this.defaultFilter);
  }

  onStateChanged(event: MatSelectChange): void {
    this.form.patchValue({
      stateId: event.value,
    });
    this.search();
  }

  onTeleworkChange(event: MatCheckboxChange): void {
    // we don't want to filter by telework = false, so we just remove the filter
    // completely if the user unchecks the box
    const newValue = event.checked ? event.checked : null;
    this.form.patchValue({
      isTeleworkAvailable: newValue,
    });
    this.search();
  }

  onRemoteChange(event: MatCheckboxChange): void {
    // we don't want to filter by remote = false, so we just remove the filter
    // completely if the user unchecks the box
    const newValue = event.checked ? event.checked : null;
    this.form.patchValue({
      offersPermanentRemotePositions: newValue,
    });
    this.search();
  }

  private handleTeleworkQueryParameter(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParamMap
        .pipe(
          switchMap((params) => {
            // only filter by telework if the parameter exists and the value is true
            const shouldFilterByTelework =
              params.get('isTeleworkAvailable')?.toLowerCase() === 'true';

            if (shouldFilterByTelework) {
              this.form.patchValue({
                isTeleworkAvailable: shouldFilterByTelework,
              });
              this.search();
            }
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  private handleRemoteQueryParameter(): void {
    const subscription = this.activatedRoute.queryParamMap
      .pipe(
        switchMap((params) => {
          // only filter by remote if the parameter exists and the value is true
          const shouldFilterByRemote =
            params.get('offersPermanentRemotePositions')?.toLowerCase() ===
            'true';

          if (shouldFilterByRemote) {
            this.form.patchValue({
              offersPermanentRemotePositions: shouldFilterByRemote,
            });
            this.search();
          }
          return EMPTY;
        })
      )
      .subscribe();

    this.subscriptions.push(subscription);
  }

  private search(): void {
    const filter = this.form.value as PartnerSearch;
    filter.take = this.paginator?.pageSize;
    filter.skip = 0;
    if (this.paginator !== undefined) {
      this.paginator.firstPage();
    }
    this.partnersSubject.next(filter);
  }

  private setPartnerRequestStream(): void {
    this.partnersAction$ = this.partnersSubject.asObservable();
    this.partners$ = this.partnersAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap((filters: PartnerSearch) =>
        this.partnersService.search(filters)
      ),
      tap((results) => {
        this.resultTotal = results.total;
        this.partners = results.data;
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((x) => x.unsubscribe);
    }
  }
}
