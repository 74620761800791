import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { LineChart, MsepChartType, PieChart } from 'core/enums';

@Component({
  selector: 'msep-chart-dock',
  templateUrl: './chart-dock.component.html',
})
export class ChartDockComponent implements OnInit {
  @Input() chartType?: MsepChartType;
  @Input() partnerId?: number;

  charts!: MsepChart[];
  chartId!: number;
  form!: UntypedFormGroup;
  lineChart = MsepChartType.Line;
  multiLineChart = MsepChartType.MultiLine;
  lineCharts = [
    {
      id: LineChart.CandidateSearch,
      title: 'Candidate Searches',
    },
    {
      id: LineChart.SpousesHired,
      title: 'Spouses Hired',
    },
    {
      id: LineChart.SpousesCurrentlyEmployed,
      title: 'Spouses Currently Employed',
    },
    {
      id: LineChart.SpouseEngagement,
      title: 'Spouse Engagement',
    },
  ] as MsepChart[];
  pieChart = MsepChartType.Pie;
  pieCharts = [
    {
      id: PieChart.Industry,
      title: 'Jobs by Industry',
    },
    {
      id: PieChart.YearsOfExperience,
      title: 'Jobs by Years of Experience',
    },
    {
      id: PieChart.EducationLevel,
      title: 'Jobs by Education Level',
    },
    {
      id: PieChart.RemoteTeleworkJob,
      title: 'Remote/Telework Jobs',
    },
  ] as MsepChart[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.chartType === MsepChartType.Pie) {
      this.charts = this.pieCharts;
      if (this.partnerId === undefined) {
        this.charts.push({
          id: PieChart.PartnerIndustry,
          title: 'Partners by Industry',
        });
        this.charts.push({
          id: PieChart.RemoteTeleworkPartner,
          title: 'Partners who offer Remote/Telework',
        });
        this.charts.push({
          id: PieChart.PartnershipType,
          title: 'Partners by Partnership Type',
        });
      }
    } else {
      this.charts = this.lineCharts;
      if (this.partnerId === undefined) {
        this.charts.push({
          id: LineChart.PartnersWhoReported,
          title: 'Partners who completed a Monthly Spouse Hire Report',
        });
        this.charts.push({
          id: LineChart.TotalPartners,
          title: 'Total Approved/Active Partners',
        });
      }
    }
    this.chartId = this.charts[0].id;
    this.buildForm();
  }

  onSelectionChanged(event: MatSelectChange): void {
    this.chartId = event.value;

    if (this.chartType === this.multiLineChart) {
      this.chartType = this.lineChart;
    }

    if (
      this.chartId === LineChart.SpouseEngagement &&
      this.chartType === this.lineChart
    ) {
      this.chartType = this.multiLineChart;
    }

    this.form.patchValue({
      active: event.value,
      title: this.charts[event.value - 1].title,
    });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      active: 1,
      title: this.charts[0].title,
    });
  }
}

export interface MsepChart {
  id: number;
  title: string;
}
