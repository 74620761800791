import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'shared/components/confirmation-dialog/confirmation-dialog.component';
import { GeneralDialogComponent } from 'shared/components/general-dialog/general-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  confirmDialogRef!: MatDialogRef<ConfirmationDialogComponent>;
  constructor(private dialog: MatDialog) {}

  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Is it OK?');

    return of(confirmation);
  }

  generalConfirmation(
    message?: string
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: message || 'Is it OK?',
        buttonYes: 'Ok',
        buttonNo: 'Cancel',
      },
    });
  }

  generalDialog(
    title: string,
    templateRef: TemplateRef<unknown> | undefined,
    width = '80vw'
  ): MatDialogRef<GeneralDialogComponent> {
    return this.dialog.open(GeneralDialogComponent, {
      data: {
        templateRef: templateRef,
        title: title,
      },
      width: width,
    });
  }

  showDeleteConfirmation(
    dialogContent: MsepDialogContent | null = null
  ): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title:
          dialogContent?.title ??
          'Are you sure you want to delete this record?',
        message:
          dialogContent?.message ??
          'You will not be able to recover this information!',
        buttonYes: dialogContent?.buttonYes ?? 'Delete',
        buttonNo: dialogContent?.buttonNo ?? 'Cancel',
      },
    });
  }

  governmentExitModal(href: string): MatDialogRef<ConfirmationDialogComponent> {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Leaving the Military Spouse Employment Partnership portal',
        message:
          'You are about to leave the site. The appearance of hyperlinks does not constitute endorsement by the Defense Department of non-U.S. government sites or the information, products, or services contained therein. Other than for authorized activities such as military exchanges and MWR sites, the United States Defense Department does not exercise any editorial control over the information you may find at these locations. Such links are provided consistent with the stated purpose of this website.',
        buttonYes: 'Ok',
        buttonNo: 'Cancel',
        linkUrl: href,
      },
    });
  }
}

export interface MsepDialogContent {
  title: string | null;
  message: string | null;
  buttonYes: string | null;
  buttonNo: string | null;
}
