import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './services/infrastructure/notification.service';
import { ConfigService } from './services/infrastructure/config.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const isTokenRequest = req.url.indexOf('api/authentication/login') !== -1;

    const secoRequest = req.url.indexOf('seco-api') !== -1;
    const sapRequest = req.url.indexOf('mycaa-api') !== -1;

    if (
      !isTokenRequest &&
      !secoRequest &&
      !sapRequest &&
      sessionStorage.getItem('id_token')
    ) {
      req = req.clone({
        setHeaders: {
          authorization: `Bearer ${sessionStorage.getItem('id_token')}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'cache-control': 'no-cache',
          pragma: 'no-cache',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        },
      });
    }

    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 400) {
          this.notificationService.emitServerValidationError(
            response.error.ErrorMessages
          );
          return EMPTY;
        }
        if (
          response.status === 403 &&
          req.url.indexOf(this.configService.config.msepPartnerApiBaseUrl) !==
            -1
        ) {
          this.router.navigate(['/logout']);
          return EMPTY;
        }
        if (response.status === 403 || response.status === 500) {
          this.router.navigate(['/error', response.error.exceptionId]);
          return EMPTY;
        }

        return throwError(() => response);
      })
    );
  }
}
