<msep-page-title>
  <h1>Privacy Act Statement</h1>
</msep-page-title>

<mat-card class="wrap">
  <mat-card-content>
    <section>
      <p>
        <strong>Authority:</strong> 10 U.S.C. 136, Under Secretary of Defense
        for Personnel and Readiness; 10 U.S.C. 1784, Employment Opportunities
        for Military Spouses, and DoDI 1342.22, Military Family Readiness.
      </p>

      <p>
        <strong>Principal Purpose(s):</strong> The Military Spouse Employment
        Partnership (MSEP) Partner Portal is the sole web platform utilized to
        connect military spouses with companies seeking to hire military spouse
        employees. MSEP is a component of the DoD Spouse Education and Career
        Opportunities (SECO) Program which is the primary source of education,
        career and employment counseling for all military spouses.
      </p>

      <p>
        <strong>Routine Use(s):</strong> In addition to those disclosures
        generally permitted under 5 U.S.C. 552a(b) of the Privacy Act of 1974,
        as amended, the records contained herein may specifically be disclosed
        outside the DoD as a routine use pursuant to 5 U.S.C. 552a(b)(3) as
        follows: To authorized DoD SECO contractors and grantees for the purpose
        of responding to military spouse need as they relate to employment
        readiness. To authorized DoD contractors and grantees for the purpose of
        supporting research studies concerned with the education, career and
        employment counseling of military spouses participating in DoD funded
        spousal education, career and employment programs. See the applicable
        system of records notice, DPR 47 DoD, Military Spouse Employment
        Partnership (MSEP) Partner Portal for additional routine uses, located
        at:
        <a
          href="https://dpcld.defense.gov/Portals/49/Documents/Privacy/SORNs/OSDJS/DPR-47-DoD.pdf"
        >
          https://dpcld.defense.gov/Portals/49/Documents/Privacy/SORNs/OSDJS/DPR-47-DoD.pdf</a
        >
      </p>

      <p>
        <strong>Disclosure:</strong> Voluntary; however, failure to provide
        information may impact your ability to utilize certain program
        opportunities.
      </p>
    </section>
  </mat-card-content>
</mat-card>
