import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDateTime',
})
export class UtcDateTimePipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line
  transform(value: any): any {
    const msepFormat = 'MM/dd/yyyy h:mm:ss a zzzz';
    return super.transform(value, msepFormat);
  }
}
