import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'msep-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent implements OnInit {
  displayData: unknown;
  templatePortal!: TemplatePortal<any>;
  title!: string;
  constructor(
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private viewContainerRef: ViewContainerRef
  ) {}

  // assumption here is that callers will pass in a TemplateRef
  ngOnInit(): void {
    this.templatePortal = new TemplatePortal(
      this.data.templateRef,
      this.viewContainerRef
    );
    this.title = this.data.title;
  }
}
