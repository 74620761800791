import { Component } from '@angular/core';
import { AuthenticationService } from 'core/services/authentication.service';

@Component({
  selector: 'msep-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent {
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.clearSession();
  }
}
