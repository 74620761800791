import { Pipe, PipeTransform } from '@angular/core';
import { PartnerProfile } from 'core/services/partners.service';

@Pipe({
  name: 'formatAddress',
})
export class FormatAddressPipe implements PipeTransform {
  transform(profile: PartnerProfile): string {
    const {
      headquartersAddress1,
      headquartersAddress2,
      headquartersCity,
      headquartersState,
      headquartersZip,
    } = profile;
    const addressLine1 = [headquartersAddress1, headquartersAddress2]
      .filter(Boolean)
      .join(' ');
    const addressLine2 = [
      headquartersCity ? headquartersCity + ',' : null,
      headquartersState,
      headquartersZip,
    ]
      .filter(Boolean)
      .join(' ');
    return [addressLine1, addressLine2].join('\n');
  }
}
