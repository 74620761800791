import { Component } from '@angular/core';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { DialogService } from 'core/services/infrastructure/dialog.service';

@Component({
  selector: 'msep-help',
  templateUrl: './help.component.html',
})
export class HelpComponent {
  secoBaseUrl = this.configService.config.secoBaseUrl;

  constructor(
    private configService: ConfigService,
    private dialogService: DialogService
  ) {}

  onClickSeco(): void {
    const dialogRef = this.dialogService.governmentExitModal(this.secoBaseUrl);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(this.secoBaseUrl, '_blank');
      }
    });
  }
}
