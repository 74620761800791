import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'core/services/authentication.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'msep-cui-banner',
  templateUrl: './cui-banner.component.html',
  styleUrls: ['./cui-banner.component.scss'],
})
export class CuiBannerComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  private subscription = new Subscription();

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.subscription = this.authenticationService.isAuthenticatedSubject
      .pipe(map((result) => (this.isAuthenticated = result)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
