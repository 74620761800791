import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyBytes',
})
export class FriendlyBytesPipe implements PipeTransform {
  transform(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const kilobyte = 1024;
    const decimalPlaces = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const exponent = Math.floor(Math.log(bytes) / Math.log(kilobyte));
    return (
      parseFloat(
        (bytes / Math.pow(kilobyte, exponent)).toFixed(decimalPlaces)
      ) +
      ' ' +
      sizes[exponent]
    );
  }
}
