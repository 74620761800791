<msep-dock
  title="Partner"
  [dockId]="partnerMetrics"
  [isCollapsed]="isCollapsed"
  (toggleCollapseDock)="toggleCollapse()"
>
  <ng-container *ngIf="partnerMetrics$ | async as partnerMetrics; else loading">
    <div
      *ngFor="let statusCount of partnerMetrics.statusCounts; let last = last"
    >
      <div
        class="flex flex-row justify-between align-center multiple-dock-items"
      >
        <a
          role="link"
          [routerLink]="['/partner/search']"
          [queryParams]="{ applicationStatusId: statusCount.statusId }"
        >
          {{ statusCount.description }}
        </a>
        <div class="count default">{{ statusCount.count | number }}</div>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </ng-container>
</msep-dock>
<ng-template #loading>
  <msep-centered-loader></msep-centered-loader>
</ng-template>
