import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Role } from 'core/enums';
import { AdminService } from 'core/services/admin/admin.service';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { PartnersService } from 'core/services/partners.service';
import { Resource } from 'core/services/resources.service';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'msep-partner-autocomplete',
  templateUrl: './partner-autocomplete.component.html',
  styleUrls: ['./partner-autocomplete.component.scss'],
})
export class PartnerAutocompleteComponent
  implements OnInit, ControlValueAccessor
{
  @Input() label = 'Search for Organization';
  @Input() partner!: Resource;
  @Input() overrideShowApprovedActiveOnly = false;

  @Output() partnerSelected = new EventEmitter<Resource>();
  private showAllOrganizationsValue = false;

  @Input() showHint = false;

  // this is a dummy input that the autocomplete and matInput need to satisfy
  // the compiler.
  inputControl = new UntypedFormControl('');
  partnersAutocomplete$: Observable<Resource[]> | undefined;

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private adminService: AdminService,
    private authenticationService: AuthenticationService,
    private partnersService: PartnersService,
    private changeDetectorRef: ChangeDetectorRef,
    private jwtService: JwtService
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.showAllOrganizationsValue =
      this.authenticationService.isAuthenticated() &&
      !this.jwtService.userHasRole(Role.User) &&
      !this.overrideShowApprovedActiveOnly;

    this.partnersAutocomplete$ = this.controlDir.control?.valueChanges.pipe(
      startWith(''),
      switchMap(value =>
        this.showAllOrganizationsValue
          ? this.adminService.getPartnersAutoComplete(value)
          : this.partnersService.getPartnersAutoComplete(value)
      )
    );
    if (this.partner) {
      this.inputControl.setValue(this.partner);
      this.autocompleteDisplay(this.partner);
      this.partnerSelected.emit(this.partner);
    }
  }

  autocompleteDisplay(partner: Resource | string): string {
    if (!partner) return '';
    return typeof partner === 'string' ? partner : partner.description;
  }

  onAutocompleteSelect(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value as Resource;
    this.partnerSelected.emit(selectedValue);
  }

  /*
    registerOnChange, registerOnTouched, and writeValue are all required by 
    the ControlValueAccessor interface.
  */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.inputControl.valueChanges.subscribe({
      next: value => {
        if (typeof value === 'string') {
          this.changeDetectorRef.detectChanges();
          fn(value);
        } else {
          fn(value);
        }
      },
    });
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    // no implementation necessary
  }

  // eslint-disable-next-line
  writeValue(obj: any): void {
    this.inputControl.setValue(obj || '');
  }
}
