import { Component } from '@angular/core';
import { GhostLoaderType } from 'core/enums';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { DialogService } from 'core/services/infrastructure/dialog.service';
import { PartnerData, PartnersService } from 'core/services/partners.service';
import { map } from 'rxjs';

@Component({
  selector: 'msep-home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.scss'],
})
export class HomeWelcomeComponent {
  ghostLoaderType = GhostLoaderType.PartnerLogo;
  ghosts = 8;
  navyFederalLogo =
    this.configService.config.logosS3BucketUrl + '/B1875DD0-840C2966.png';
  partners!: PartnerData[];
  randomPartners$ = this.partnerService.getRandomPartners().pipe(
    map((result) => {
      this.partners = result;
      return result;
    })
  );
  secoBaseUrl = this.configService.config.secoBaseUrl;
  // trigger tab group active status on component init
  selectedPartnerIndex = 0;
  constructor(
    private configService: ConfigService,
    private dialogService: DialogService,
    private partnerService: PartnersService
  ) {}

  onClickSeco(): void {
    const dialogRef = this.dialogService.governmentExitModal(this.secoBaseUrl);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(this.secoBaseUrl, '_blank');
      }
    });
  }
}
