import { Component } from '@angular/core';
import { MediaLinksService } from 'core/services/media-links.service';

@Component({
  selector: 'msep-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent {
  constructor(private mediaLinksService: MediaLinksService) {}
  mediaLinks = this.mediaLinksService.getMediaLinks();
}
