import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartnershipTypeId } from 'core/enums';
import { roundDown } from 'core/utilities/mat-utils';
import { UtilsService } from 'core/utilities/utils.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'shared/models/api-response';
import { ConfigService } from './infrastructure/config.service';
import { Resource } from './resources.service';
import { SearchFilter } from 'shared/models/search-filter';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Injectable({ providedIn: 'root' })
export class PartnersService {
  private msepPartnerApiUrl = this.configService.config.msepPartnerApiBaseUrl;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private utilService: UtilsService
  ) {}

  canViewOurPartner(id: number): Observable<boolean> {
    const url = `${this.msepPartnerApiUrl}/public/partner/can-view-partner/${id}`;
    return this.http.get<boolean>(url).pipe(catchError(this.handleError));
  }

  downloadPartnerApplication(id: number): Observable<ServerBlobResult> {
    const url = `${this.msepPartnerApiUrl}/partner/${id}/download-application`;
    return this.http
      .get<ServerBlobResult>(url)
      .pipe(catchError((error) => this.handleError(error)));
  }

  deletePartner(id: number): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/${id}/delete`;
    return this.http
      .post<void>(url, {})
      .pipe(catchError((error) => this.handleError(error)));
  }

  getMsep360NewsLetters(
    filters?: Msep360NewsLetterFilter
  ): Observable<ApiResponse<Msep360NewsLetters[]>> {
    const url = `${this.msepPartnerApiUrl}/partner/msep-360-newsletters`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<Msep360NewsLetters[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  getPartnerProfile(id: number): Observable<PartnerProfile> {
    const url = `${this.msepPartnerApiUrl}/public/partner/${id}`;

    return this.http.get<ApiResponse<PartnerProfile>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  getPartnersAutoComplete(searchTerm: string): Observable<Resource[]> {
    const url = `${this.msepPartnerApiUrl}/public/partner/autocomplete`;

    return this.http
      .get<ApiResponse<Resource[]>>(url, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((result: ApiResponse<Resource[]>) => result.data),
        catchError((error) => this.handleError(error))
      );
  }

  getPartnerResourcesAutoComplete(searchTerm: string): Observable<Resource[]> {
    const url = `${this.msepPartnerApiUrl}/public/partner/resources-autocomplete`;

    return this.http
      .get<ApiResponse<Resource[]>>(url, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((result: ApiResponse<Resource[]>) => result.data),
        catchError((error) => this.handleError(error))
      );
  }

  getPartnerResources(
    filters?: PartnerResourceFilter
  ): Observable<ApiResponse<PartnerResource[]>> {
    const url = `${this.msepPartnerApiUrl}/public/partner/partner-resources`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http.get<ApiResponse<PartnerData[]>>(url, { params }).pipe(
      map((result) => result),
      catchError((error) => this.handleError(error))
    );
  }

  getPartnerTrainingVideos(
    filters?: PartnerTrainingVideoFilter
  ): Observable<ApiResponse<PartnerTrainingVideo[]>> {
    const url = `${this.msepPartnerApiUrl}/partner/partner-training-videos`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http
      .get<ApiResponse<PartnerTrainingVideo[]>>(url, { params })
      .pipe(catchError((error) => this.handleError(error)));
  }

  getPrimaryPointOfContact(id: number): Observable<PointOfContact> {
    const url = `${this.msepPartnerApiUrl}/partner/${id}/primary-point-of-contact`;

    return this.http.get<ApiResponse<PointOfContact>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  getRandomPartners(take?: number): Observable<PartnerData[]> {
    const params = take ? { take } : undefined;
    return this.http
      .get<ApiResponse<PartnerData[]>>(
        `${this.msepPartnerApiUrl}/public/partner/random`,
        {
          params: params,
        }
      )
      .pipe(
        map((response) => response.data),
        catchError((error) => this.handleError(error))
      );
  }

  getRoundedPartnerCount(): Observable<number> {
    return this.search().pipe(map((result) => roundDown(result.total, 10)));
  }

  getSysopPartnerProfile(id: number): Observable<SysopPartnerProfile> {
    const url = `${this.msepPartnerApiUrl}/partner/${id}`;

    return this.http.get<ApiResponse<SysopPartnerProfile>>(url).pipe(
      map((result) => result.data),
      catchError(this.handleError)
    );
  }

  resendApplication(id: number): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/partner/resend-application`;
    return this.http
      .post<void>(url, { partnerId: id })
      .pipe(catchError((error) => this.handleError(error)));
  }

  search(filters?: PartnerSearch): Observable<ApiResponse<PartnerData[]>> {
    const url = `${this.msepPartnerApiUrl}/public/partner/search`;
    const params = filters
      ? new HttpParams({
          fromObject: this.utilService.removeFalseyProperties(filters),
        })
      : undefined;
    return this.http.get<ApiResponse<PartnerData[]>>(url, { params }).pipe(
      map((result) => result),
      catchError((error) => this.handleError(error))
    );
  }

  updateLogoUrl(fileName: string, organizationId: number): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.msepPartnerApiUrl}/partner/${organizationId}/update-logo`,
        { fileName: fileName }
      )
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  updateProfile(sapId: number): Observable<void> {
    return this.http.post<void>(
      `${this.msepPartnerApiUrl}/partner/update-profile`,
      { sapId: sapId }
    );
  }

  updateSapId(sapId: number, organizationId: number): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.msepPartnerApiUrl}/partner/${organizationId}/update-sap-id`,
        { sapId: sapId }
      )
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    return throwError(() => err || 'Server error');
  }
}

export interface PartnerData {
  approvedDate: Date;
  approveDate: Date;
  countriesWithFacilities: number[];
  facebookUrl: string;
  geographicReaches: Resource[];
  headquartersAddress1: string;
  headquartersAddress2: string;
  headquartersCity: string;
  headquartersCountryId: number;
  headquartersStateId: string;
  headquartersZip: string;
  id: number;
  instagramUrl: string;
  isInternational: boolean;
  isTeleworkAvailable: boolean;
  offersPermanentRemotePositions: boolean;
  offersChildcare: boolean;
  lastUpdatedDate: Date;
  linkedinUrl: string;
  logoUrl: string;
  numberOfActiveHotJobs: number;
  numberOfActiveJobs: number;
  numberOfEmployees: number;
  numberOfFacilities: number;
  numberOfTeleworkJobs: number;
  organizationDescription: string;
  organizationName: string;
  primaryIndustrySector: string;
  primaryIndustrySectorId: number;
  secondaryIndustrySectors: Resource[];
  twitterUrl: string;
  website: string;
}

export interface PartnerLogo {
  id?: number;
  logoUrl?: string;
  organizationName?: string;
}

export interface PartnerProfile {
  id: number;
  isFourPlusOnePartner: boolean;
  isTeleworkAvailable: boolean;
  offersPermanentRemotePositions: boolean;
  headquartersAddress1: string;
  headquartersAddress2: string;
  headquartersCity: string;
  headquartersPhone: string;
  headquartersState: string;
  headquartersZip: string;
  website: string;
  partnerVideoUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  linkedInUrl: string;
  logoUrl: string;
  name: string;
  description: string;
  numberOfEmployees: number;
  countriesWithFacilities: Resource[];
  primaryIndustry: string;
  sapStakeholderId: number;
  secondaryIndustries: Resource[];
  geographicReach: Resource[];
  partnershipTypes: PartnershipType[];
  approvedDate: Date;
  numberOfActiveJobs: number;
  benefits: Resource[];
}

export interface SysopPartnerProfile extends PartnerProfile {
  lastUpdatedDate: Date;
  lastUpdatedName: string;
}

export interface Msep360NewsLetters {
  id?: number;
  title?: string;
  url?: string;
}

export interface Msep360NewsLetterFilter extends SearchFilter {
  date?: string;
}

export interface PartnershipType extends Resource {
  id: PartnershipTypeId;
}

export interface PartnerResourceFilter extends SearchFilter {
  id?: number;
  resourceCategoryId?: number;
}

export interface PartnerSearch {
  name?: string;
  industrySectorId?: number;
  hasTelework?: boolean;
  stateId?: string;
  internationalFacilities: boolean;
  countryId?: number;
  skip?: number;
  take?: number;
}

export interface PartnerResource {
  id?: number;
  title?: string;
  url?: string;
  icon?: string;
  authenticated?: boolean;
}

export interface PartnerTrainingVideo {
  id: number;
  title: string;
  url: string;
  date: string;
}

export interface PartnerTrainingVideoFilter extends SearchFilter {
  title?: string;
}

export interface PointOfContact {
  name: string;
  email: string;
  phone: string;
}
