<mat-sidenav-container>
  <mat-sidenav #sidenav position="end">
    <div class="sidenav-custom">
      <msep-side-menu (menuNavigated)="sidenav.toggle()"> </msep-side-menu>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <msep-top-header (menuToggled)="sidenav.toggle()"> </msep-top-header>
    <div class="full-height">
      <router-outlet></router-outlet>
    </div>
    <msep-footer></msep-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
