import { NgModule } from '@angular/core';

export const throwIfAlreadyLoaded = (
  parentModule: NgModule,
  moduleName: string
): void => {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import ${moduleName} modules in the AppModule only.`
    );
  }
};
