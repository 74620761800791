import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'core/enums';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class SpecialistGuard {
  constructor(
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      return this.handleInvalidAccess();
    }

    if (
      !this.jwtService.userHasRole(Role.Specialist) &&
      !this.jwtService.userHasRole(Role.SysOp)
    ) {
      return this.handleInvalidAccess();
    }

    return true;
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/not-found']);
    return false;
  }
}
