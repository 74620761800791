import { Component, Input, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'core/services/authentication.service';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'msep-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnDestroy {
  @Input() forceBackgroundImageUse = false;
  @Input() fxLayoutInput = 'flex-col';
  @Input() fxLayoutAlignInput = 'justify-center align-center';
  isAuthenticated = false;
  private subscription = new Subscription();

  constructor(private authenticationService: AuthenticationService) {
    const authenticationSubscription =
      this.authenticationService.isAuthenticatedSubject
        .pipe(map((result) => (this.isAuthenticated = result)))
        .subscribe();

    this.subscription = authenticationSubscription;
  }

  getCssClass(): string {
    // gives us the ability to use the background image on authenticated views (basically the partner pages)
    if (this.forceBackgroundImageUse) {
      return 'page-title';
    }

    return this.isAuthenticated ? 'simple-page-title' : 'page-title';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
