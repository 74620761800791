import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks } from 'core/enums';
import { DashboardService } from 'core/services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'msep-user-metrics-dock',
  templateUrl: './user-metrics-dock.component.html',
})
export class UserMetricsDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  activeUsers?: number;
  isLoadingUserMetrics = false;
  lockedUsers?: number;
  suspendedUsers?: number;
  totalUsers?: number;
  userMetrics = Docks.UserMetrics;

  private subscriptions: Subscription[] = [];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.loadUserMetrics();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }

  private loadUserMetrics(): void {
    this.isLoadingUserMetrics = true;

    this.subscriptions.push(
      this.dashboardService.getUserMetrics().subscribe({
        next: result => {
          this.activeUsers = result.activeUsers;
          this.lockedUsers = result.lockedUsers;
          this.suspendedUsers = result.suspendedUsers;
          this.totalUsers = result.totalUsers;
        },
        error: () => {
          this.isLoadingUserMetrics = false;
        },
        complete: () => {
          this.isLoadingUserMetrics = false;
        },
      })
    );
  }
}
