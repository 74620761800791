import { Injectable } from '@angular/core';
import { UtilsService } from 'core/utilities/utils.service';

/* eslint-disable sonarjs/no-duplicate-string */
@Injectable({ providedIn: 'root' })
export class JobFeedGuidanceService {
  constructor(private utilsService: UtilsService) {}

  getExampleCsv(): ExampleCsv[] {
    return [
      {
        title: 'Web Developer',
        description: 'Suspendisse mollis scelerisque aliquam',
        url: 'https://www.example.com/jobs/web-developer-wd400',
        jobIdentifier: 'WD400',
        industry: 'Computer and Mathematical',
        jobType: 'Full time',
        compensationType: 'salary',
        hourlyRate: null,
        minSalary: '$80,000',
        maxSalary: '$100,000',
        education: 'Bachelors Degree',
        minQualifications: 'html /plain text',
        prefQualifications: 'html /plain text',
        yearsOfExperience: '1-3',
        career: 'Entry Level',
        address: '6404 Ivy Lane',
        address2: 'Suite 600',
        city: 'Greenbelt',
        state: 'MD',
        postal: '20770',
        country: 'USA',
        isTelework: true,
        isRemoteJob: false,
        isHotJob: false,
        jobOpenings: '1',
        tags: 'web, developer, example',
      },
      {
        title: 'Web Developer',
        description: 'Suspendisse mollis scelerisque aliquam',
        url: 'https://www.example.com/jobs/web-designer-wd401	',
        jobIdentifier: 'WD401',
        industry: 'Computer and Mathematical',
        jobType: 'Full time',
        compensationType: 'hourly',
        hourlyRate: '15.00',
        minSalary: null,
        maxSalary: null,
        education: 'Bachelors Degree',
        minQualifications: 'html /plain text',
        prefQualifications: 'html /plain text',
        yearsOfExperience: '4-7',
        career: 'Experienced (Non-manager)',
        address: '6404 Ivy Lane',
        address2: 'Suite 600',
        city: 'Greenbelt',
        state: 'MD',
        postal: '20770',
        country: 'USA',
        isTelework: true,
        isRemoteJob: true,
        isHotJob: true,
        jobOpenings: '1',
        tags: 'web, designer, example',
      },
    ];
  }

  getExampleJson(): string {
    const example = {
      jobs: [
        {
          title: 'Web Developer',
          jobIdentifier: 'WD400',
          url: 'https://www.example.com/jobs/web-developer-wd400',
          industry: 'Computer and Mathematical',
          jobType: 'Full time',
          description: 'Suspendisse mollis scelerisque aliquam',
          education: 'Bachelors Degree',
          minQualifications: 'html /plain text',
          prefQualifications: 'html /plain text',
          yearsOfExperience: '10+',
          career: 'Senior Executive (President, CFO, etc.)',
          compensationType: 'salary',
          hourlyRate: '',
          minSalary: '$80,000',
          maxSalary: '$100,000',
          address: '6404 Ivy Lane',
          address2: 'Suite 600',
          city: 'Greenbelt',
          state: 'MD',
          postal: '20770',
          country: 'USA',
          isTelework: true,
          isRemoteJob: false,
          isHotJob: true,
          jobOpenings: '1',
          tags: 'Test, One, Two',
        },
        {
          title: 'Web Designer',
          jobIdentifier: 'WD401',
          url: 'https://www.example.com/jobs/web-designer-wd401',
          industry: 'Computer and Mathematical',
          jobType: 'Full time',
          description: 'Suspendisse mollis scelerisque aliquam',
          education: 'Bachelors Degree',
          minQualifications: 'html /plain text',
          prefQualifications: 'html /plain text',
          yearsOfExperience: '1-3',
          career: 'Manager (Manager/Supervisor of Staff)',
          compensationType: 'hourly',
          hourlyRate: '15.00',
          minSalary: '',
          maxSalary: '',
          address: '6404 Ivy Lane',
          address2: 'Suite 600',
          city: 'Greenbelt',
          state: 'MD',
          postal: '20770',
          country: 'USA',
          isTelework: false,
          isRemoteJob: false,
          isHotJob: true,
          jobOpenings: '1',
          tags: 'Test1, Test2, Test3',
        },
      ],
    };

    return JSON.stringify(example, null, 2);
  }

  // formatted a little funny to make our lives easier
  getExampleXml(): string {
    return `<jobs>
  <job>
    <title>Web Developer</title>
    <jid>WD400</jid>
    <url>https://www.example.com/jobs/web-developer-wd400</url>
    <industry>Computer and Mathematical</industry>
    <jobType>Full time</jobType>
    <description>Suspendisse mollis scelerisque aliquam</description>
    <education>Bachelors Degree</education>
    <minQualifications>html /plain text</minQualifications>
    <prefQualifications>html /plain text</prefQualifications>
    <yearsOfExperience>8-10</yearsOfExperience>
    <career>Executive (SVP, VP, Department Head, etc.)</career>
    <compensationType>salary</compensationType>
    <hourlyRate></hourlyRate>
    <minSalary>$60,0000</minSalary>
    <maxSalary>$70,0000</maxSalary>
    <address>6404 Ivy Lane</address>
    <address2>Suite 600</address2>
    <city>Greenbelt</city>
    <state>MD</state>
    <postal>20770</postal>
    <country>USA</country>
    <isTelework>true</isTelework>
    <isRemoteJob>false</isRemoteJob>
    <isHotJob>false</isHotJob>
    <jobOpenings>5</jobOpenings>
    <tags>Test, One, Two</tags>
  </job>
  <job>
    <title>Web Developer</title>
    <jid>WD401</jid>
    <url>https://www.example.com/jobs/web-developer-wd401</url>
    <industry>Computer and Mathematical</industry>
    <jobType>Full time</jobType>
    <description>Suspendisse mollis scelerisque aliquam</description>
    <education>Bachelors Degree</education>
    <minQualifications>html /plain text</minQualifications>
    <prefQualifications>html /plain text</prefQualifications>
    <yearsOfExperience>4-7</yearsOfExperience>
    <career>Experienced (Non-manager)</career>
    <compensationType>hourly</compensationType>
    <hourlyRate>15.00</hourlyRate>
    <minSalary></minSalary>
    <maxSalary></maxSalary>
    <address>6404 Ivy Lane</address>
    <address2>Suite 600</address2>
    <city>Greenbelt</city>
    <state>MD</state>
    <postal>20770</postal>
    <country>USA</country>
    <isTelework>false</isTelework>
    <isRemoteJob>true</isRemoteJob>
    <isHotJob>true</isHotJob>
    <jobOpenings>3</jobOpenings>
    <tags>Test, One, Two</tags>
  </job>
</jobs>`;
  }

  getNavLinks(): NavLink[] {
    const navLinkTitles = [
      'General Job Feed Guidance',
      'Required for Job Postings',
      'Highly Recommended for Job Postings',
      'Example XML Feed',
      'Example JSON Feed',
      'Example CSV Feed',
      'Job Feeds with HTML Content',
    ];

    return navLinkTitles.map((title) => ({
      title,
      fragment: this.utilsService.convertToKebabCase(title),
    }));
  }

  getRequiredSpecs(): JobFeedSpec[] {
    return [
      {
        field: 'title',
        type: 'String',
        description: 'The title of job being posted.',
      },
      {
        field: 'description',
        type: 'String',
        description: 'A description of the job overview being posted.',
      },
      {
        field: 'url',
        type: 'String',
        description:
          "The URL of the job posting on your company's site. <strong>This URL must be unique.</strong>",
      },
    ];
  }
}

export interface ExampleCsv {
  title: string;
  description: string;
  url: string;
  jobIdentifier: string;
  industry: string;
  jobType: string;
  compensationType: string;
  hourlyRate: string | null;
  minSalary: string | null;
  maxSalary: string | null;
  education: string;
  minQualifications: string;
  prefQualifications: string;
  yearsOfExperience: string;
  career: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  isTelework: boolean;
  isRemoteJob: boolean;
  isHotJob: boolean;
  jobOpenings: string;
  tags: string;
}

export interface JobFeedSpec {
  field: string;
  type: string;
  description: string;
}

export interface NavLink {
  title: string;
  fragment: string;
}
