import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { User } from './admin/user-management.service';
import { ConfigService } from './infrastructure/config.service';
import { Resource } from './resources.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(private configService: ConfigService, private http: HttpClient) {}

  getUserChallengeQuestions(token: string): Observable<Resource[]> {
    const url = `${this.msepPartnerApiUrl}/user/challenge-questions/${token}`;
    return this.http.get<ApiResponse<Resource[]>>(url).pipe(
      map((result: ApiResponse<Resource[]>) => result.data),
      catchError((error) => this.handleError(error))
    );
  }

  resetAccount(data: ResetPassword): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/user/reset-account`;
    return this.http
      .post<void>(url, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  resetPassword(data: ResetPassword): Observable<void> {
    const url = `${this.msepPartnerApiUrl}/user/reset-password`;
    return this.http
      .post<void>(url, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  sendFullResetToken(user: User): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.msepPartnerApiUrl}/admin/users/send-full-reset-email`,
        user.id
      )
      .pipe((response) => {
        return response;
      }, catchError(this.handleError));
  }

  sendPasswordResetToken(form: ForgotPasswordForm): Observable<void> {
    return this.http
      .post<void>(
        `${this.msepPartnerApiUrl}/account/send-reset-password-token`,
        form
      )
      .pipe(catchError(this.handleError));
  }

  sendPasswordResetTokenWithRecaptcha(
    form: ForgotPasswordForm,
    reCaptchaResponse: string | undefined
  ): Observable<void> {
    const headers: Record<string, string> = {};

    if (reCaptchaResponse) {
      headers['g-recaptcha-response'] = reCaptchaResponse;
    }
    return this.http
      .post<void>(
        `${this.msepPartnerApiUrl}/account/send-reset-password-token-recaptcha`,
        form,
        {
          headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface ChallengeQuestionAnswer {
  challengeQuestionId: number;
  answer: string;
}

export interface ForgotPasswordForm {
  email: string;
  confirmEmail: string;
}

export interface ResetPassword {
  token: string;
  newPassword: string;
  challengeQuestionAnswers: ChallengeQuestionAnswer[];
}
