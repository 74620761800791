import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountStatus, Docks, Permission, Role } from 'core/enums';
import {
  AdminDashboardService,
  HelpDeskCounts,
} from 'core/services/admin/admin-dashboard.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-help-desk-dock',
  templateUrl: './help-desk-dock.component.html',
})
export class HelpDeskDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  helpDesk = Docks.Helpdesk;
  helpDeskCounts$!: Observable<HelpDeskCounts>;
  userAccountPendingStatusId = AccountStatus.Pending;
  userIsSysOpWithPermission = false;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private jwtService: JwtService
  ) {}

  ngOnInit(): void {
    this.helpDeskCounts$ = this.adminDashboardService.getHelpDeskCounts();
    this.userIsSysOpWithPermission =
      this.jwtService.userHasRole(Role.SysOp) &&
      this.jwtService.userHasPermission(Permission.Helpdesk);
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }
}
