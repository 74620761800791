import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'msep-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() buttonLink?: string;
  @Input() buttonText?: string;
  @Input() icon?: string;
  @Input() showCloseAlert = false;
  /* @Input() title sets the HTML title attribute, which
  the browser turns into a tooltip. We don't want that functionality
  for this component, so we define the getter/setter explicitly
  and clear out the native element's title attribute.
   */
  private titleValue!: string;
  @Input() get title(): string {
    return this.titleValue;
  }
  set title(value: string) {
    this.titleValue = value;
    this.elementRef.nativeElement.title = '';
  }
  @Input() type!: AlertType;

  isHidden = false;
  alertClass = 'alert alert-alert';

  constructor(private elementRef: ElementRef, private router: Router) {}

  ngOnInit(): void {
    this.setButtonLinkText();
    this.setClass();
    this.setIcon();
  }

  takeMeThere(): void {
    this.router.navigate([this.buttonLink]);
  }

  hideAlert(): void {
    this.isHidden = true;
  }

  private setButtonLinkText(): void {
    if (this.buttonLink && !this.buttonText) {
      this.buttonText = 'Take me there';
    }
  }

  private setClass(): void {
    if (!this.type) return;

    switch (this.type) {
      case AlertType.Info:
        this.alertClass = 'alert alert-info';
        break;
      case AlertType.Success:
        this.alertClass = 'alert alert-success';
        break;
      case AlertType.Warn:
        this.alertClass = 'alert alert-warn';
        break;

      default:
        this.alertClass = 'alert alert-alert';
        break;
    }

    if (this.isHidden) {
      this.alertClass += ' hidden';
    }
  }

  private setIcon(): void {
    // don't override the icon if we've provided it to the component
    if (this.icon) return;

    switch (this.type) {
      case AlertType.Info:
        this.icon = 'announcement';
        break;
      case AlertType.Success:
        this.icon = 'check_circle';
        break;
      case AlertType.Warn:
        this.icon = 'report';
        break;

      default:
        this.icon = 'warning';
        break;
    }
  }
}

export enum AlertType {
  Alert = 1,
  Info,
  Success,
  Warn,
}
