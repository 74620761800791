<msep-page-title>
  <h1>Media</h1>
  <h2>
    This page contains uploaded videos and linked media encompassing all things
    MSEP related. Please reference these files to get more insight to the
    conduct and scope of the MSEP program.
  </h2>
</msep-page-title>

<div class="margin-top-20">
  <div
    class="flex flex-col flex-wrap flex-gt-sm-row justify-center grid-gap-16"
  >
    <div
      class="flex flex-100 flex-gt-sm-32 flex-gt-lg-19"
      *ngFor="let media of mediaLinks"
    >
      <mat-card matRipple>
        <a
          [href]="media.url"
          [ariaLabel]="media.title + ' - Link opens in new tab'"
        >
          <img
            mat-card-image
            [src]="media.thumbnail"
            [alt]="media.altText"
            [title]="media.title"
          />
          <mat-card-title>{{ media.title }}</mat-card-title>
        </a>
      </mat-card>
    </div>
  </div>
</div>
