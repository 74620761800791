import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'core/enums';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  throwError,
  timer,
} from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { ApiResponse } from 'shared/models/api-response';
import { ConfigService } from './infrastructure/config.service';
import { JwtService } from './infrastructure/jwt.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // Refresh JWT 60 seconds before token expires.
  delayOffset = 60000;
  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  refreshSubscription: Subscription | undefined;

  private baseUrl: string;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {
    this.isAuthenticatedSubject.next(!this.jwtService.isTokenExpired());
    this.baseUrl = this.configService.config.msepPartnerApiBaseUrl;
  }

  clearSession(): void {
    this.isAuthenticatedSubject.next(false);
    this.jwtService.deleteAllTokens();
    this.refreshSubscription?.unsubscribe();
  }

  getDisplayName(): string {
    const decodedToken = this.jwtService.decodeToken();
    return decodedToken?.unique_name ?? '';
  }

  getNewJwtFromRefreshTokenObservable(): Observable<
    | AuthenticateResponse
    | {
        jwt: string;
        refreshToken: string;
      }
  > {
    const requestBody: RefreshTokenFormBody = {
      refreshToken: this.jwtService.getRefreshToken(),
    };
    const url = `${this.baseUrl}/authentication/refresh`;

    return this.http
      .post<ApiResponse<AuthenticateResponse>>(url, requestBody)
      .pipe(
        map((response) => {
          const { data: authenticateResponse } = response;
          if (!authenticateResponse) return { jwt: '', refreshToken: '' };
          this.setTokenData(authenticateResponse);

          return authenticateResponse;
        }),
        catchError(this.handleError)
      );
  }

  getTimeoutTimeFromJwt(): number {
    return this.jwtService.userHasRole(Role.SysOp) ? 10 : 15;
  }

  isAuthenticated(): boolean {
    return !this.jwtService.isTokenExpired();
  }

  logIn(requestBody: LoginFormBody): Observable<boolean> {
    const url = `${this.baseUrl}/authentication/login`;

    return this.http
      .post<ApiResponse<AuthenticateResponse>>(url, requestBody)
      .pipe(
        map((response) => {
          const { data: authenticateResponse } = response;
          if (!authenticateResponse) return false;

          this.setTokenData(authenticateResponse);

          return true;
        }),
        catchError(this.handleError)
      );
  }

  logOut(): Observable<boolean> {
    const url = `${this.baseUrl}/authentication/logout`;

    return this.http.post<boolean>(url, null).pipe(
      map((response) => {
        this.clearSession();
        return response;
      }),
      catchError(this.handleError)
    );
  }

  private getNewJwtFromRefreshToken(): void {
    this.getNewJwtFromRefreshTokenObservable().subscribe((x) => x);
  }

  handleManualTokenDeletion(): void {
    window.addEventListener(
      'storage',
      (event) => {
        if (
          event.key === this.configService.config.tokenStorageKey &&
          event.newValue === null
        ) {
          this.isAuthenticatedSubject.next(false);
          this.router.navigate(['/session-expired']);
        }
      },
      false
    );
  }

  scheduleRefresh(): void {
    this.jwtService.tokenStream.subscribe(() => {
      const delay = this.calculateDelay();

      //When timer eq 0, get new JWT using refresh token
      this.refreshSubscription = timer(delay - this.delayOffset)
        .pipe(takeWhile(() => !!this.jwtService.getRefreshToken()))
        .subscribe(() => {
          this.getNewJwtFromRefreshToken();
        });
    });
  }

  private calculateDelay(): number {
    const now: number = new Date().valueOf();
    const decodedJwt = this.jwtService.decodeToken();
    if (decodedJwt) {
      const jwtExp: number = decodedJwt.exp;
      const exp: Date = new Date(0);
      exp.setUTCSeconds(jwtExp);
      const delay: number = exp.valueOf() - now;

      return delay;
    }
    return 0;
  }

  private setTokenData(authenticateResponse: AuthenticateResponse): void {
    this.jwtService.setToken(authenticateResponse.jwtToken);
    this.jwtService.setRefreshToken(authenticateResponse.refreshToken);
    // wait to notify subscribers until after tokens are set
    this.isAuthenticatedSubject.next(true);
    this.scheduleRefresh();
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.error(err.error);
    const errorMessage = 'Whoops, something went wrong!';
    return throwError(() => errorMessage);
  }
}

interface AuthenticateResponse {
  jwtToken: string;
  refreshToken: string;
}

interface LoginFormBody {
  email: string;
  password: string;
}

interface RefreshTokenFormBody {
  refreshToken: string;
}
