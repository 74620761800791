import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AccountService,
  ForgotPasswordForm,
} from 'core/services/account.service';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { concatMap, Subscription, tap } from 'rxjs';
import { AlertType } from '../alert/alert.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'msep-expiring-password-notification',
  templateUrl: './expiring-password-notification.component.html',
  styleUrls: ['./expiring-password-notification.component.scss'],
})
export class ExpiringPasswordNotificationComponent
  implements OnInit, OnDestroy
{
  daysUntilPasswordExpires?: number;
  email!: string;
  expiredPasswordAlertType = AlertType.Alert;
  isLoading = false;
  isPasswordExpiring = false;

  private resetPasswordErrorMessage = 'Error sending password reset email.';
  private resetPasswordSuccessMessage =
    'Password reset email successfully sent.';
  private subscriptions: Subscription[] = [];

  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private jwtService: JwtService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.email = this.jwtService.getEmail();
    this.isPasswordExpiring = this.jwtService.isPasswordExpiring();
    this.daysUntilPasswordExpires =
      this.jwtService.getDaysUntilPasswordExpires();
  }

  onResetPasswordClick(): void {
    const resetPassword = {
      email: this.email,
      confirmEmail: this.email,
    } as ForgotPasswordForm;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Reset Password?',
        message:
          'By resetting your password, you will be logged out of your MSEP account. For best results, complete the reset password process before logging in again.',
        buttonYes: 'Ok',
        buttonNo: 'Cancel',
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.isLoading = true;

        this.subscriptions.push(
          this.accountService
            .sendPasswordResetToken(resetPassword)
            .pipe(
              concatMap(() => this.authenticationService.logOut()),
              tap(() => this.router.navigate(['/logout']))
            )
            .subscribe({
              next: () => {
                this.notificationService.emitSuccess(
                  this.resetPasswordSuccessMessage
                );
                this.isLoading = false;
              },
              error: () => {
                this.notificationService.emitFailure(
                  this.resetPasswordErrorMessage
                );
                this.isLoading = false;
              },
              complete: () => {
                this.isLoading = false;
              },
            })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
