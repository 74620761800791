import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventsLocalDateTime',
})
export class EventsLocalDateTimePipe extends DatePipe implements PipeTransform {
  // eslint-disable-next-line
  transform(value: any, oldTimezone: string): any {
    const msepFormat = 'EEEE, MMMM d, y h:mm a';
    const dateWithTimezone = new Date(value);
    const eventOffset = this.getTimezoneOffset(
      dateWithTimezone,
      oldTimezone,
      msepFormat
    );

    dateWithTimezone.setHours(dateWithTimezone.getHours() + eventOffset);
    return super.transform(dateWithTimezone, msepFormat);
  }

  private getTimezoneOffset(
    date: Date,
    timezone: string,
    format: string
  ): number {
    const tzDate = new Date(super.transform(date, format, timezone) ?? date);
    return (date.getTime() - tzDate.getTime()) / 360e4;
  }
}
