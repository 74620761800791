import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  googleAnalyticsKey = this.configService.config.googleAnalyticsKey;
  constructor(private configService: ConfigService) {}

  generateScript(): void {
    const gTagLoaderId = 'gTag';
    const tagManagerLoaderId = 'tagManager';
    const areScriptsLoaded =
      document.getElementById(gTagLoaderId) &&
      document.getElementById(tagManagerLoaderId);

    if (areScriptsLoaded) {
      return;
    }

    const tagManagerScript = this.buildTagManagerScript(tagManagerLoaderId);
    const googleAnalyticsScript = this.buildGTagScript(gTagLoaderId);

    // tagmanager documentation asks for the script to be loaded as close as possible
    // to opening head element. https://developers.google.com/tag-manager/quickstart
    document.head.appendChild(tagManagerScript);
    document.head.appendChild(googleAnalyticsScript);
  }

  trackExternalLinkClick(linkHref: string): void {
    // eslint-disable-next-line
    (<any>window).ga('send', 'event', {
      eventCategory: 'External Link Click',
      eventAction: 'click',
      eventLabel: linkHref,
    });
  }

  private buildGTagScript(scriptId: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = scriptId;
    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
    dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', '${this.googleAnalyticsKey}');`;

    return script;
  }

  private buildTagManagerScript(scriptId: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = scriptId;

    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsKey}`;

    return script;
  }
}
