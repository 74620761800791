<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="inputControl"
    placeholder="Type to search resources"
    [matAutocomplete]="auto"
  />
  <mat-hint *ngIf="showHint"><ng-content></ng-content></mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="autocompleteDisplay"
    (optionSelected)="onAutocompleteSelect($event)"
  >
    <mat-option
      *ngFor="let partnerResource of partnerResourcesAutocomplete$ | async"
      [value]="partnerResource"
    >
      {{ partnerResource.description }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
