import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks, Permission, Role } from 'core/enums';
import {
  AdminDashboardService,
  JobFeedInfo,
} from 'core/services/admin/admin-dashboard.service';
import { PartnerMetrics } from 'core/services/dashboard.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import { Job, JobSearchFilter, JobService } from 'core/services/jobs.service';
import { Observable } from 'rxjs';
import { ApiResponse, HeartbeatResponse } from 'shared/models/api-response';

@Component({
  selector: 'msep-jobs-dock',
  templateUrl: './jobs-dock.component.html',
})
export class JobsDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;
  @Input() isAdminView = false;

  @Output() toggleCollapseDock = new EventEmitter();

  isJobsIndexAvailable$!: Observable<HeartbeatResponse>;
  jobs = Docks.Jobs;
  jobs$!: Observable<PartnerMetrics>;
  jobFeedInfo$!: Observable<JobFeedInfo>;
  publishedJobCount$!: Observable<ApiResponse<Job[]>>;
  userIsSysOpWithPermission = false;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private jobService: JobService,
    private jwtService: JwtService
  ) {}

  ngOnInit(): void {
    this.userIsSysOpWithPermission =
      this.jwtService.userHasRole(Role.SysOp) &&
      this.jwtService.userHasPermission(Permission.ManageJobs);
    this.jobFeedInfo$ = this.adminDashboardService.getJobFeedInfo();
    this.jobCount();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }

  private jobCount(): void {
    const jobFilters: JobSearchFilter = {
      take: 0,
      skip: 0,
      location: null,
      coordinates: null,
      organizationId: null,
      organizationName: null,
      title: null,
      jid: null,
      jobFeedId: null,
      isHotJob: null,
      isPublished: true,
      createdDate: null,
      publishEndDate: null,
      jobHash: null,
      createdEndDate: null,
      createdStartDate: null,
      keyword: null,
      sortDirection: 'asc',
      sortByProperty: 'title.keyword',
      state: null,
    };
    this.publishedJobCount$ = this.jobService.searchJobs(jobFilters);
  }
}
