<msep-page-title>
  <h1>Log Out</h1>
</msep-page-title>

<div class="flex flex-col justify-center align-center">
  <p>You have logged out of MSEP.</p>
  <a role="link" [routerLink]="['/notice-and-consent', 'login']"
    >Click here to log back in.</a
  >
</div>
