import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      return this.handleInvalidAccess();
    }

    return true;
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/not-found']);
    return false;
  }
}
