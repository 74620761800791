<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="inputControl"
    placeholder="Type to search our partners"
    [matAutocomplete]="auto"
  />
  <mat-hint *ngIf="showHint"><ng-content></ng-content></mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="autocompleteDisplay"
    (optionSelected)="onAutocompleteSelect($event)"
  >
    <mat-option
      *ngFor="let partner of partnersAutocomplete$ | async"
      [value]="partner"
    >
      {{ partner.description }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
