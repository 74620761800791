import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'core/enums';
import { AuthenticationService } from 'core/services/authentication.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class VhfTempGuard {
  constructor(
    private authService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (
      this.authService.isAuthenticated() &&
      (this.jwtService.userHasRole(Role.SysOp) ||
        this.jwtService.userHasRole(Role.Govt) ||
        this.jwtService.userHasRole(Role.Specialist) ||
        this.jwtService.showVhfToAllUsers() ||
        (this.jwtService.showVhfToBetaUsers() && this.jwtService.isBetaUser()))
    ) {
      return true;
    }

    return this.handleInvalidAccess();
  }

  private handleInvalidAccess(): boolean {
    this.router.navigate(['/not-found']);
    return false;
  }
}
