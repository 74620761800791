<ng-container *ngIf="partnerProfile as profile">
  <div class="header">
    <mat-card class="summary-card">
      <mat-card-content>
        <div class="flex flex-wrap flex-row justify-start align-center">
          <div
            class="summary-card-content flex flex-100 flex-gt-xs-250px justify-center"
          >
            <msep-partner-logo
              [logoUrl]="profile.logoUrl"
              [organizationName]="profile.name"
            ></msep-partner-logo>
          </div>

          <div class="summary-card-content flex-50 flex-gt-xs-auto">
            <h1 id="partner-name">{{ profile.name }}</h1>
            <mat-card-subtitle>
              <div *ngIf="profile.approvedDate" class="induction-details">
                Partner since {{ profile.approvedDate | date : 'yyyy' }}
              </div>
              <div>
                {{ profile | formatAddress }}
              </div>
              <div *ngIf="isAuthenticated()">
                {{ profile.headquartersPhone }}
              </div>
            </mat-card-subtitle>

            <div id="links" *ngIf="hasLink()">
              <a
                role="link"
                id="website"
                class="button"
                mat-raised-button
                color="primary"
                [href]="profile.website | prefixHttps"
              >
                Website
                <mat-icon>open_in_new</mat-icon>
              </a>
              <msep-four-plus-one-badge
                *ngIf="profile.isFourPlusOnePartner"
              ></msep-four-plus-one-badge>
              <div
                id="social-media-links"
                *ngIf="shouldShowSocialMediaLinks()"
                class="flex flex-row gap-1rem"
              >
                <a
                  role="link"
                  [href]="profile.facebookUrl | prefixHttps"
                  *ngIf="hasValue(profile.facebookUrl)"
                >
                  <mat-icon svgIcon="facebook"></mat-icon>
                  <span class="cdk-visually-hidden">Facebook</span>
                </a>

                <a
                  role="link"
                  [href]="profile.linkedInUrl | prefixHttps"
                  *ngIf="hasValue(profile.linkedInUrl)"
                >
                  <mat-icon svgIcon="linkedin"></mat-icon>
                  <span class="cdk-visually-hidden">LinkedIn</span>
                </a>

                <a
                  role="link"
                  [href]="profile.twitterUrl | prefixHttps"
                  *ngIf="hasValue(profile.twitterUrl)"
                >
                  <mat-icon svgIcon="twitter" id="mosTwitter"></mat-icon>
                  <span class="cdk-visually-hidden">Twitter</span>
                </a>

                <a
                  role="link"
                  [href]="profile.instagramUrl | prefixHttps"
                  *ngIf="hasValue(profile.instagramUrl)"
                >
                  <mat-icon svgIcon="instagram"></mat-icon>
                  <span class="cdk-visually-hidden">Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="flex flex-col flex-gt-md-row gap-1rem">
    <div
      class="flex flex-100"
      [class]="
        (isAuthenticated() && (pointOfContact$ | async)) ||
        hasValue(profile.benefits)
          ? 'flex-gt-md-50'
          : ''
      "
    >
      <mat-card class="flex-100" *ngIf="hasValue(profile.description)">
        <section id="about">
          <mat-card-title>
            <h2>About</h2>
          </mat-card-title>
          <mat-card-content>
            <p [innerHTML]="profile.description" exitModal></p>
          </mat-card-content>
        </section>
      </mat-card>
    </div>

    <div
      class="flex flex-100 flex-wrap justify-between"
      [class]="hasValue(profile.description) ? 'flex-gt-md-50' : ''"
      *ngIf="(pointOfContact$ | async) || hasValue(profile.benefits)"
    >
      <div
        *ngIf="hasValue(profile.benefits)"
        class="flex-100"
        [class]="
          isAuthenticated() && (pointOfContact$ | async) ? 'flex-gt-sm-49' : ''
        "
      >
        <mat-card class="flex-100">
          <section id="benefits">
            <mat-card-title>
              <h2>Benefits</h2>
            </mat-card-title>
            <mat-card-content>
              <div *ngFor="let benefit of profile.benefits">
                {{ benefit.description }}
              </div>
            </mat-card-content>
          </section>
        </mat-card>
      </div>
      <div
        [class]="
          hasValue(profile.benefits)
            ? 'flex flex-100 flex-gt-sm-49'
            : 'flex flex-100'
        "
        *ngIf="isAuthenticated() && (pointOfContact$ | async) as pointOfContact"
      >
        <mat-card class="flex-100">
          <section id="pointOfContact">
            <mat-card-title>
              <h2>Point of Contact</h2>
            </mat-card-title>
            <mat-card-content>
              <div *ngIf="hasValue(pointOfContact.name)">
                <mat-icon>person</mat-icon> {{ pointOfContact.name }}
              </div>
              <div *ngIf="hasValue(pointOfContact.email)">
                <mat-icon>alternate_email</mat-icon> {{ pointOfContact.email }}
              </div>
              <div *ngIf="hasValue(pointOfContact.phone)">
                <mat-icon>smartphone</mat-icon> {{ pointOfContact.phone }}
              </div>
            </mat-card-content>
          </section>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="flex flex-col flex-gt-md-row justify-evenly gap-1rem">
    <mat-card
      id="statistics"
      class="flex-100"
      [class]="calculateWidth()"
      *ngIf="shouldShowStatistics()"
    >
      <section id="statistics">
        <mat-card-title>
          <h2>Statistics</h2>
        </mat-card-title>

        <mat-card-content>
          <div
            id="number-of-employees"
            *ngIf="hasValue(profile.numberOfEmployees)"
          >
            <h3>Number of Employees</h3>
            <p>{{ profile.numberOfEmployees | number }}</p>
          </div>

          <div id="number-of-jobs" *ngIf="hasValue(profile.numberOfActiveJobs)">
            <h3>Number of Published Jobs</h3>
            <p>{{ profile.numberOfActiveJobs }}</p>
          </div>

          <div
            id="countries-with-facilities"
            *ngIf="hasValue(profile.countriesWithFacilities)"
          >
            <h3>Countries with Facilities</h3>
            <p>{{ getResourceString(profile.countriesWithFacilities) }}</p>
          </div>
        </mat-card-content>
      </section>
    </mat-card>

    <mat-card
      id="industry-information"
      class="flex-100"
      [class]="calculateWidth()"
      *ngIf="shouldShowIndustry()"
    >
      <section id="industry-information">
        <mat-card-title>
          <h2>Industry Information</h2>
        </mat-card-title>

        <mat-card-content>
          <div id="primary-industry" *ngIf="hasValue(profile.primaryIndustry)">
            <h3>Primary Industry</h3>
            <p>{{ profile.primaryIndustry }}</p>
          </div>

          <div
            id="secondary-industry"
            *ngIf="hasValue(profile.secondaryIndustries)"
          >
            <h3>{{ getSecondaryIndustryHeading() }}</h3>
            <p>{{ getResourceString(profile.secondaryIndustries) }}</p>
          </div>

          <div id="geographic-reach" *ngIf="hasValue(profile.geographicReach)">
            <h3>Geographic Reach</h3>
            <p>{{ getResourceString(profile.geographicReach) }}</p>
          </div>
        </mat-card-content>
      </section>
    </mat-card>

    <mat-card
      class="flex-100"
      [class]="calculateWidth()"
      *ngIf="shouldShowPartnerVideo()"
    >
      <section>
        <mat-card-title>
          <h2>Partner Video</h2>
        </mat-card-title>
        <mat-card-content>
          <msep-video-player
            [videoPath]="profile.partnerVideoUrl"
          ></msep-video-player>
        </mat-card-content>
      </section>
    </mat-card>
  </div>
  <div class="flex justify-center">
    <msep-back-button></msep-back-button>
  </div>
</ng-container>
