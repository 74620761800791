import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';
import { GoogleAnalyticsService } from 'core/services/infrastructure/google-analytics.service';
import { SeoService } from 'core/services/seo.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomIconService } from './core/services/infrastructure/custom-icon.service';
import { NotificationService } from './core/services/infrastructure/notification.service';

@Component({
  selector: 'msep-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private customIconService: CustomIconService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationService: NotificationService,
    private router: Router,
    private seoService: SeoService
  ) {
    this.customIconService.registerCustomIcons();
  }

  ngOnInit(): void {
    // Handles scheduling refresh token if application is refreshed in the browser.
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.scheduleRefresh();
    }
    this.authenticationService.handleManualTokenDeletion();

    if (!isDevMode()) {
      this.googleAnalyticsService.generateScript();
    }

    this.addTabListener();
    this.handleFailureNotifications();
    this.handleFailureFullDurationNotifications();
    this.handleSuccessNotifications();
    this.handleSuccessFullDurationNotifications();
    this.trackNavigationEnd();
  }

  // adds a specified class to indicate tab usage
  // this allows our focus styles to scope only on keyboard usage
  // rather than triggering on mouse or touch usage
  private addTabListener(): void {
    const tabClass = 'using-tab';
    document.body.addEventListener('mousedown', () =>
      document.body.classList.remove(tabClass)
    );

    document.body.addEventListener('keydown', (e: KeyboardEvent) => {
      const tabKey = 'Tab';
      if (e.key === tabKey) {
        document.body.classList.add(tabClass);
      }
    });
  }

  private resetFocus(): void {
    const focusedElement = document.activeElement as HTMLElement;
    // remove focus
    focusedElement.blur();

    // reset focus to body, so that skip link will be first item shown on tab
    // note that tabindex is required for this process to work
    const body = document.querySelector('body') as HTMLElement;
    if (!focusedElement.classList.contains('stop-tab-index-reset') && body) {
      body.tabIndex = 0;
      body.focus();
    }
  }

  private handleFailureNotifications(): void {
    const failureSubscription =
      this.notificationService.failureNotification$.subscribe(
        (message: string) => this.notificationService.showFailure(message)
      );

    this.subscriptions.push(failureSubscription);
  }

  private handleFailureFullDurationNotifications(): void {
    const successSubscription =
      this.notificationService.failureFullDurationNotification$.subscribe(
        (message: string) =>
          this.notificationService.showFailureFullDuration(message)
      );

    this.subscriptions.push(successSubscription);
  }

  private handleSuccessNotifications(): void {
    const successSubscription =
      this.notificationService.successNotification$.subscribe(
        (message: string) => this.notificationService.showSuccess(message)
      );

    this.subscriptions.push(successSubscription);
  }

  private handleSuccessFullDurationNotifications(): void {
    const successSubscription =
      this.notificationService.successFullDurationNotification$.subscribe(
        (message: string) =>
          this.notificationService.showSuccessFullDuration(message)
      );

    this.subscriptions.push(successSubscription);
  }

  private trackNavigationEnd(): void {
    const navEndSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.seoService.getMetaDetailsFromRoute())
      )
      .subscribe((metaDetails) => {
        this.seoService.setTitle(metaDetails.title);
        this.seoService.setMetaTags(metaDetails);
        this.resetFocus();
        window.scrollTo(0, 0); // scroll to top on any navigation
      });

    this.subscriptions.push(navEndSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
