<msep-page-title>
  <h1>Frequently Asked Questions (FAQs)</h1>
  <p>
    Listed are the most frequently asked questions regarding the MSEP portal and
    program. Please select from our list of categories to find the question that
    will most accurately resolve your inquiry.
  </p>
</msep-page-title>

<mat-drawer-container>
  <mat-drawer mode="side" opened class="hide show-gt-sm flex flex-gt-sm-19">
    <mat-nav-list>
      <ng-container *ngFor="let faq of faqs">
        <a
          role="link"
          class="nav-item stop-tab-index-reset"
          [routerLink]="[urlPath]"
          [fragment]="convertToKebabCase(faq.question)"
        >
          {{ faq.question }}
        </a>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-nav-list>

    <p><strong>Did not find your question?</strong></p>

    <a
      role="link"
      id="contact-us-button"
      mat-raised-button
      color="primary"
      [routerLink]="['/notice-and-consent', 'contact-us']"
      >Contact Us</a
    >
  </mat-drawer>

  <mat-drawer-content>
    <section *ngFor="let faq of faqs; let i = index">
      <mat-divider class="section-divider" *ngIf="i !== 0"></mat-divider>
      <h2 class="faq-question" [id]="convertToKebabCase(faq.question)">
        {{ faq.question }}
      </h2>
      <div class="faq-answer" [innerHtml]="faq.answer" exitModal></div>
    </section>
  </mat-drawer-content>
</mat-drawer-container>
