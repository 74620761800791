import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { UtilsService } from 'core/utilities/utils.service';

@Component({
  selector: 'msep-partner-logo',
  templateUrl: './partner-logo.component.html',
  styleUrls: ['./partner-logo.component.scss'],
})
export class PartnerLogoComponent implements OnInit {
  @Input() logoUrl!: string;
  @Input() organizationName!: string;

  defaultLogoFileName = 'default-logo.png';
  defaultLogoUrl?: string;

  constructor(
    private readonly configService: ConfigService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    const s3BucketUrl = this.configService.config.resourcesS3BucketUrl;
    this.defaultLogoUrl = this.utilsService.buildEncodedS3Url(
      s3BucketUrl,
      this.defaultLogoFileName
    );
  }

  onLogoError(event: Event): void {
    const image = event.target as HTMLImageElement;
    image.src = <string>this.defaultLogoUrl;
    image.onerror = null;
  }
}
