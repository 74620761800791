<div class="flex flex-100 flex-wrap flex-row justify-center">
  <div
    [formGroup]="form"
    class="flex flex-100 flex-col margin-top-20 margin-20"
  >
    <div
      *ngIf="partnerId === undefined && !shouldHideFilters"
      class="flex flex-100 justify-gt-sm-end pie-chart-partner-autocomplete"
    >
      <msep-partner-autocomplete
        label="Organization"
        formControlName="organizationName"
        (partnerSelected)="onOrganizationSelected($event)"
        class="flex-100 flex-gt-sm-47"
      ></msep-partner-autocomplete>
    </div>
    <div class="flex-100 justify-start margin-bottom-10">
      <button
        *ngIf="partnerId === undefined && !shouldHideFilters"
        mat-raised-button
        type="reset"
        (click)="onReset()"
      >
        Reset
      </button>
      <msep-export-pie-chart-button
        [chartId]="pieChartId"
        [organizationId]="partnerId"
        [filters]="currentFilter"
      ></msep-export-pie-chart-button>
    </div>
  </div>
  <div class="flex-100 flex-gt-sm-70 chart-wrap">
    <canvas [id]="chartName + (this.partnerId ? this.partnerId : '')">
      <ng-container *ngIf="pieChartMetrics$ | async">
        {{ chart }}
      </ng-container>
    </canvas>
  </div>
</div>
