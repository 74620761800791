import { Pipe, PipeTransform } from '@angular/core';
import { Education } from 'core/services/partner/seco.service';

@Pipe({
  name: 'candidateDegreeInfo',
})
export class CandidateDegreeInfoPipe implements PipeTransform {
  transform(education: Education): string {
    const hasDegreeLevel = (education.degreeLevel?.length ?? 0) > 0;
    const hasFieldOfStudy = (education.fieldOfStudy?.length ?? 0) > 0;

    if (hasDegreeLevel && hasFieldOfStudy) {
      return education.degreeLevel + ' in ' + education.fieldOfStudy;
    } else if (hasDegreeLevel) {
      return education.degreeLevel ?? '';
    } else if (hasFieldOfStudy) {
      return education.fieldOfStudy ?? '';
    } else {
      return '';
    }
  }
}
