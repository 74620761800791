import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'core/services/infrastructure/dialog.service';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'msep-mos-universal-nav',
  templateUrl: './mos-universal-nav.component.html',
  styleUrls: ['./mos-universal-nav.component.scss'],
})
export class MosUniversalNavComponent implements AfterViewInit {
  removeEventListener!: () => void;
  subscriptions: Subscription[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    this.generateScript();

    this.removeEventListener = this.renderer.listen(
      this.elementRef.nativeElement,
      'click',
      (event) => {
        if (event.target.closest('a') instanceof HTMLAnchorElement) {
          this.handleAnchorClick(event);
        }
      }
    );
  }

  generateScript(): void {
    const scriptLoaderId = 'navLoader';
    // only load script if it doesn't exist
    const script = document.getElementById(scriptLoaderId);
    if (script) {
      return;
    }
    const navScript = document.createElement('script');
    navScript.type = 'text/javascript';
    navScript.async = true;
    navScript.defer = true;
    navScript.id = scriptLoaderId;
    /*
      Since our CSP does not allow inline scripts, we need to generate this
      code as an external file. The scripts section of angular.json references the
      input file under assets/scripts and outputs it as universalnav.js. We also set
      inject = false so that the CLI does not automatically include this script in the
      HTML for us.
    */
    navScript.src = 'universalnav.js';

    document.body.appendChild(navScript);
  }

  // eslint-disable-next-line
  public handleAnchorClick(event: any): void {
    event.preventDefault();

    const anchor = event.target.closest('a') as HTMLAnchorElement;

    if (!anchor.href || anchor.href.includes('universalnavCollapse')) {
      return;
    }

    if (
      anchor.href.includes('mailto:') ||
      anchor.href.includes('tel:') ||
      anchor.href.includes('https://msep.militaryonesource.mil/msep')
    ) {
      window.location.href = anchor.href;
      return;
    }

    // checks if link is external
    if (!anchor.href.includes(location.hostname)) {
      this.confirmDialogRef = this.dialogService.governmentExitModal(
        anchor.href
      );
      const dialogSubscription = this.confirmDialogRef
        .afterClosed()
        .subscribe();

      this.subscriptions.push(dialogSubscription);
    } else {
      this.router.navigate([anchor.attributes.getNamedItem('href')?.value]);
    }
  }
}
