<mat-card [class]="alertClass">
  <div class="flex flex-row">
    <div class="align-center">
      <mat-icon
        class="material-icons"
        aria-hidden="true"
        [fontIcon]="icon ?? ''"
      ></mat-icon>
    </div>

    <mat-card-content>
      <mat-card-title>{{ title }} </mat-card-title>
      <mat-card-subtitle><ng-content></ng-content></mat-card-subtitle>
      <button *ngIf="buttonLink" mat-raised-button (click)="takeMeThere()">
        {{ buttonText }}
      </button>
    </mat-card-content>

    <div class="flex justify-end" *ngIf="showCloseAlert">
      <button
        mat-icon-button
        color="primary"
        aria-label="hide information alert"
        (click)="hideAlert()"
      >
        <em class="material-icons close">close</em>
      </button>
    </div>
  </div>
</mat-card>
