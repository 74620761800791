import { Component, Input } from '@angular/core';

@Component({
  selector: 'msep-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent {
  @Input() message = 'No Results';
}
