import { Directive, HostListener, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'core/services/infrastructure/dialog.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Directive({
  // eslint-disable-next-line
  selector: 'a[href], [exitModal]',
})
export class ExitModalDirective implements OnDestroy {
  subscriptions: Subscription[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(private dialogService: DialogService, private router: Router) {}

  @HostListener('click', ['$event'])
  public handleClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    const anchor = this.getAnchorElement(event.target);

    if (!anchor) {
      return;
    }

    // allow default behavior for email and phone
    if (anchor.href.includes('mailto:') || anchor.href.includes('tel:')) {
      window.location.href = anchor.href;
      return;
    }

    const isExternalLink = !anchor.href.includes(location.hostname);
    if (isExternalLink) {
      this.confirmDialogRef = this.dialogService.governmentExitModal(
        anchor.href
      );
      const dialogSubscription = this.confirmDialogRef
        .afterClosed()
        .subscribe();

      this.subscriptions.push(dialogSubscription);
    } else {
      this.router.navigate([anchor.attributes.getNamedItem('href')?.value]);
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((x) => x.unsubscribe());
    }
  }

  // eslint-disable-next-line
  private getAnchorElement(target: any): HTMLAnchorElement {
    let result = target;
    if (target.tagName !== 'A') {
      // element is wrapped with an anchor tag. get the nearest anchor.
      result = target.closest('a');
    }

    return result as HTMLAnchorElement;
  }
}
