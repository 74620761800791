<msep-page-title>
  <h1>Legal &amp; Security</h1>
</msep-page-title>

<mat-list class="link-list">
  <mat-list-item>
    <mat-icon matListItemIcon>arrow_forward</mat-icon>
    <a role="link" [routerLink]="['/user-agreement']">User Agreement</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon class="external-link-icon"
      >arrow_forward</mat-icon
    >
    <a
      href="https://www.militaryonesource.mil/legal-security/"
      aria-label="Accessibility, Military One Source Legal and Administrative - link opens in a new window"
    >
      Accessibility
    </a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon class="external-link-icon"
      >arrow_forward</mat-icon
    >
    <a
      href="https://prhome.defense.gov/nofear/"
      aria-label="DoD No Fear Act - link opens in a new window"
      >DoD No Fear Act</a
    >
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon class="external-link-icon"
      >arrow_forward</mat-icon
    >
    <a
      href="https://www.esd.whs.mil/FOID/foi/dfoipo/"
      aria-label="Office of the Secretary of Defense and Joint
      Staff Freedom of Information Act (FOIA) - link opens in a new window"
      >FOIA</a
    >
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon>arrow_forward</mat-icon>
    <a role="link" [routerLink]="['/privacy-policy']">
      Privacy Act Statement and Security
    </a>
  </mat-list-item>
</mat-list>
