import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';
import {
  filter,
  fromEvent,
  interval,
  map,
  merge,
  Observable,
  Subscription,
  switchMap,
} from 'rxjs';
import { ConfirmationDialogComponent } from 'shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'msep-idle-timeout-warning',
  templateUrl: './idle-timeout-warning.component.html',
})
export class IdleTimeoutWarningComponent implements OnInit, OnDestroy {
  private timeoutInMinutes!: number;
  private idleTimeout!: number;
  private isModalOpen = false;
  private subscriptions: Subscription = new Subscription();
  private warningDialogRef!: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUsersTimeout();
    this.createTimerEvents();
  }

  logoutByTimeout(): void {
    this.subscriptions.add(
      this.authenticationService.logOut().subscribe(() => {
        this.router.navigate(['/session-expired']);
      })
    );
  }

  logoutByManualClick(): void {
    this.subscriptions.add(
      this.authenticationService.logOut().subscribe(() => {
        this.router.navigate(['/logout']);
      })
    );
  }

  openDialog(): void {
    this.isModalOpen = true;
    this.warningDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Session Time-Out Warning',
        message: this.buildMessage(),
        buttonYes: 'Log Out',
        buttonNo: 'Continue',
      },
    });

    this.warningDialogRef.afterClosed().subscribe((confirm) => {
      this.isModalOpen = false;
      if (confirm) {
        this.logoutByManualClick();
      }
    });
  }

  private buildMessage(): string {
    return `You have been inactive for over ${
      this.timeoutInMinutes - 1
    } minutes. Please click "Continue" or "Log Out". You will automatically be logged out after ${
      this.timeoutInMinutes
    } minutes of inactivity.`;
  }

  private createTimerEvents(): void {
    const eventNames = ['click', 'mousemove'];

    const eventStreams = eventNames.map((eventName) => {
      return fromEvent(document, eventName);
    });

    const source = merge(...eventStreams);

    // interval timer
    this.subscriptions.add(this.showModalIfOneMinuteLeft(source));
    // log-out timer
    this.subscriptions.add(this.handleAutomaticTimeout(source));
  }

  private getUsersTimeout(): void {
    this.authenticationService.isAuthenticatedSubject
      .pipe(
        filter((x) => x === true),
        map(() => {
          // this is done due to admins having different timeout than other users.
          this.timeoutInMinutes =
            this.authenticationService.getTimeoutTimeFromJwt();
          this.idleTimeout = this.timeoutInMinutes * 60000; // convert to ms
        })
      )
      .subscribe();
  }

  private handleAutomaticTimeout(source: Observable<Event>): Subscription {
    return source
      .pipe(
        filter(() => this.idleTimeout !== undefined),
        switchMap(() => interval(this.idleTimeout)),
        map(() => this.authenticationService.isAuthenticated())
      )
      .subscribe((authenticated) => {
        if (authenticated) {
          this.isModalOpen = false;
          this.warningDialogRef.close();
          this.logoutByTimeout();
        }
      });
  }

  private showModalIfOneMinuteLeft(source: Observable<Event>): Subscription {
    return source
      .pipe(
        filter(() => this.idleTimeout !== undefined),
        switchMap(() => interval(this.idleTimeout - 60000)),
        map(() => this.authenticationService.isAuthenticated())
      )
      .subscribe((authenticated) => {
        if (authenticated && !this.isModalOpen) {
          this.openDialog();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
