import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { AlertType } from '../alert/alert.component';

@Component({
  selector: 'msep-server-validation-message',
  templateUrl: './server-validation-message.component.html',
  styleUrls: ['./server-validation-message.component.scss'],
})
export class ServerValidationMessageComponent {
  /**
   * Default behavior will scroll to the top of the window on an error.
   * We allow overrides for situations where we do not want to scroll on error,
   * like when this component shows up in a child component that needs to have focus
   * within the current viewport
   */
  @Input() scrollToTop = true;
  alertType = AlertType.Warn;
  errors$ = this.notificationService.serverValidationNotification$
    .asObservable()
    .pipe(
      map((response) => {
        if (this.scrollToTop) {
          window.scrollTo(0, 0);
        }
        return response;
      })
    );
  constructor(private notificationService: NotificationService) {}
}
