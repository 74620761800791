import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Docks, Permission } from 'core/enums';
import { DashboardService } from 'core/services/dashboard.service';
import { JwtService } from 'core/services/infrastructure/jwt.service';
import {
  ClickApplyResponse,
  SecoService,
} from 'core/services/partner/seco.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'msep-spouse-activity-dock',
  templateUrl: './spouse-activity-dock.component.html',
})
export class SpouseActivityDockComponent implements OnInit {
  @Input() isCollapsed!: boolean | undefined;

  @Output() toggleCollapseDock = new EventEmitter();

  clickApplyCount$!: Observable<ClickApplyResponse>;
  spouseActivity = Docks.SpouseActivity;
  spousesHired$!: Observable<number>;
  userHasPermission = false;

  constructor(
    private dashboardService: DashboardService,
    private jwtService: JwtService,
    private secoService: SecoService
  ) {}

  ngOnInit(): void {
    this.userHasPermission = this.jwtService.userHasPermission(
      Permission.ManageReports
    );
    this.clickApplyCount$ = this.secoService.getClickedApplyCount({
      partnerId: 0,
      skip: 0,
      take: 0,
    });
    this.spousesHired$ = this.dashboardService.getExactTotalSpousesHired();
  }

  toggleCollapse(): void {
    this.toggleCollapseDock.emit();
    this.isCollapsed = !this.isCollapsed;
  }
}
