<msep-page-title [forceBackgroundImageUse]="true">
  <h1>Alerts & Information</h1>
  <h2>
    Stay informed with the latest updates from the SECO program and the MSEP
    website.
  </h2>
</msep-page-title>

<div *ngIf="systemAlert$ | async as publishedAlert" class="margin-top-20">
  <ng-container *ngIf="publishedAlert.total > 0; else noResults">
    <table
      mat-table
      [dataSource]="systemAlerts"
      class="mat-elevation-z2"
      aria-label="This table contains all published system alerts."
    >
      <ng-container matColumnDef="title">
        <th mat-header-cell remove-role *matHeaderCellDef mat-header>Title</th>
        <td
          mat-cell
          remove-role
          *matCellDef="let systemAlert"
          data-label="Title"
        >
          {{ systemAlert.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="body">
        <th mat-header-cell remove-role *matHeaderCellDef mat-header>Body</th>
        <td
          mat-cell
          remove-role
          *matCellDef="let systemAlert"
          data-label="Body"
        >
          {{ systemAlert.body }}
        </td>
      </ng-container>
      <tr mat-header-row remove-role *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        remove-role
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <msep-pagination
      #paginator
      [pageSize]="pageSize"
      [length]="alertCount"
      (pagingChange)="onPagingChange($event)"
    ></msep-pagination>
  </ng-container>
  <msep-back-button></msep-back-button>
  <ng-template #noResults>
    <msep-no-results></msep-no-results>
  </ng-template>
</div>

<msep-ghost-loader-list
  *ngIf="!systemAlerts"
  [type]="ghostLoaderType"
  [tableRowLength]="pageSize"
  [tableColumnLength]="displayedColumns.length"
></msep-ghost-loader-list>
