import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'msep-static-display',
  templateUrl: './static-display.component.html',
  styleUrls: ['./static-display.component.scss'],
})
export class StaticDisplayComponent {
  @Input() ignoreTitleCase? = false;

  /* @Input() title sets the HTML title attribute, which
  the browser turns into a tooltip. We don't want that functionality
  for this component, so we define the getter/setter explicitly
  and clear out the native element's title attribute.
   */
  private titleValue!: string;
  @Input() get title(): string {
    return this.titleValue;
  }
  set title(value: string) {
    this.titleValue = value;
    this.elementRef.nativeElement.title = '';
  }

  @Input() value?: unknown;

  constructor(private elementRef: ElementRef) {}
}
